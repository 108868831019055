import React, {useEffect, useState} from 'react';
import {getAllReviews} from "../../components/axios/ReviewRequests";
import AxiosError from "../../components/axios/AxiosError";
import ReviewItem from "./ReviewItem";

const Reviews = ({showMessage}) => {

    const [reviews, setReviews] = useState([]);

    useEffect(() => {
        getAllReviews().then((resp) => setReviews(resp.data)).catch((err) => AxiosError(err, showMessage))
    }, [])


    return (
        <div className={"main-block"} style={{gap: "20px"}}>
            {reviews.map((rev, index) => {
                return <ReviewItem review={rev} key={index} isEdit={false}/>
            })}
        </div>
    );
};

export default Reviews;