import axios from "axios";
import apiData from "../../helpers/ApiData";

export default async function SetNewPassword(password, token) {

    return axios({
        method: "POST",
        headers: {"Content-Type": "application/json"},
        url: apiData.setNewPassword(token),
        data: password
    });
}

