import React, {useEffect, useState} from 'react';
import "./style.css"
import {ms} from "date-fns/locale";


const Presentation = () => {

    return (
        <div className="presentation">
            <img src={"./img/banners/main-banner.gif"} alt={"banner"}/>
            <img src={"./img/banners/additional-banner.png"} alt={"additional-banner"}/>
        </div>
    );
};

export default Presentation;