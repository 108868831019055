import React from 'react';
import {NavLink, useNavigate} from "react-router-dom";
import "./style.css";
import {GiFlowerPot, GiHerbsBundle, GiOlive, GiSpotedFlower, GiTreeBranch} from "react-icons/gi";


const NavbarItem = ({type, title, filters, setFilter, setOpen, link}) => {

    const navigate = useNavigate();



    function getImage(title) {
        switch (title) {
            case "ЦВЕТЫ И ПОДАРКИ":
                return <img src={"../../img/icons/flowers.png"} alt={"img"}/>
            case "ГОРШЕЧНЫЕ РАСТЕНИЯ":
                return <img src={"../../img/icons/plants.png"} alt={"img"}/>
            case "ДЕКОР":
                return <img src={"../../img/icons/decor.png"} alt={"img"}/>
            case "ЦВЕТОЧНАЯ ПОДПИСКА":
                return <img src={"../../img/icons/flower-pass.png"} alt={"img"}/>
            case "НАША ЦВЕТОЧНАЯ МАСТЕРСКАЯ":
                return <img src={"../../img/icons/workshop.png"} alt={"img"}/>
            default: return <></>
        }
    }

    function getIcon(title) {
        switch (title) {
            case "ЦВЕТЫ И ПОДАРКИ":
                return <GiSpotedFlower size={30}/>
            case "ГОРШЕЧНЫЕ РАСТЕНИЯ":
                return <GiFlowerPot size={30}/>
            case "ДЕКОР":
                return <GiHerbsBundle size={30}/>
            case "ЦВЕТОЧНАЯ ПОДПИСКА":
                return <GiOlive size={30}/>
            case "НАША ЦВЕТОЧНАЯ МАСТЕРСКАЯ":
                return <GiTreeBranch size={30}/>
            default: return <></>
        }
    }


    return (
        <>
            <div className={"nav-item"}>
                {getImage(title)}
                <div className={"nav-item-menu"}>
                    <NavLink onClick={() => setOpen(false)} to={link} className={"title"}>{getIcon(title)}<div>{title}</div></NavLink>
                    <div className={"links"}>
                        <ul>
                            {(filters && filters.length > 1) && filters.map((item, index) => {
                                if (type === 'links') {
                                    return <li key={index} style={{display: "flex", flexWrap: "nowrap"}}>
                                        <p className={"link"} onClick={(event) => {
                                            event.stopPropagation(); navigate(item.link);
                                        }}>{item.value}</p>
                                    </li>
                                }
                                else {
                                    return <li key={index} onClick={() => {setFilter(item); setOpen(false); navigate(link);}}>{item.title}</li>;
                                }
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </>
        // eslint-disable-next-line no-useless-concat

    );
};

export default NavbarItem;