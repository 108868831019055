import React from 'react';
import {NavLink} from "react-router-dom";
import {Field, Form, Formik} from "formik";

import {useResize} from "../../helpers/windowSizes/use-resize";

const Login = ({setActive, login}) => {

    const windowSize = useResize();

    return (
        <div className={"modal__content"} style={{
            display: "flex",
            padding: "0",
            overflow: "hidden",
            border: "2px solid white"
        }} onClick={e => e.stopPropagation()}>
            {windowSize.isScreenM && <img src={"./img/banners/login-img.png"} style={{position: "relative", width: "350px", objectFit: "cover"}} alt={"login-banner"}/>}
            <Formik enableReinitialize initialValues={{email: "", password: ""}} onSubmit={values  => {
                login(values.email, values.password);
            }}>
                {() => (
                    <Form className={"login-form"}>
                        <div className={"login-header"}>
                            Войти на сайт
                        </div>
                        <div className="form-group">
                            <Field required
                                   placeholder={"* Email"}
                                   className="form-control"
                                   name="email"
                                   type="email"/>
                        </div>
                        <div className="form-group">
                            <Field required
                                   name="password"
                                   placeholder={"* Пароль"}
                                   type="password"
                                   className="form-control"/>
                        </div>
                        <button type="submit" className="btn">Вход</button>
                        <div className="ref">
                            <NavLink style={{fontSize: "12px"}} to={"/reset-password"} onClick={() => setActive(false)}>Забыли
                                пароль?</NavLink>
                        </div>
                        <div className={"register-ref"}>
                            <div style={{color: "grey"}}>Нет аккаунта?</div>
                            <NavLink to={"/register"} style={{fontSize: "12px"}}
                                     onClick={() => setActive(false)}>Зарегистрироваться</NavLink>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default Login;