import React, {useEffect, useState} from 'react';
import "./style.css";
import {aboutUsItems, catalogItems} from "../../helpers/NavItemList";
import {GiSpotedFlower} from "react-icons/gi";
import NavbarItem from "../navbar/NavbarItem";
import {CgMenuGridR} from "react-icons/cg";
import {NavLink, useLocation} from "react-router-dom";
import {PiFlowerBold} from "react-icons/pi";

const CatalogMenu = ({setFilter, position, filters, topTab}) => {

    const [open, setOpen] = useState(false);
    const [page, setPage] = useState(1);
    const location = useLocation();

    useEffect(() => {
        setOpen(false);
    }, [location])



    function handlerAction(newPage) {
        if (newPage === page) {
            setOpen(!open);
        }
        else {
            setPage(newPage);
            setOpen(true);
        }
    }

    function getFilterList(title) {
        return filters.filter((el) => title.endsWith(el.type)).sort((a, b) => {
            return a.index - b.index;
        });
    }

    return (
        <div className={"catalog-menu"}>
            <div className={"btn-block"}>
                <div className={"btn"} onClick={() => handlerAction(1)}>
                    <div style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        columnGap: "5px",
                        alignContent: "center",
                        alignItems: "center"
                    }}>
                        <PiFlowerBold size={18}/>
                        <div>Цветы</div>
                    </div>
                </div>
                {topTab &&
                    <NavLink className={"btn main"}
                             to={"/" + topTab.type} onClick={() => setFilter(topTab)}>
                        <div style={{
                            fontSize: "18px",
                            fontWeight: "400",
                            display: "flex",
                            columnGap: "5px",
                            flexWrap: "nowrap",
                            alignItems: "center"
                        }}><GiSpotedFlower size={22}/>
                            <div>{topTab.title}</div>
                        </div>
                    </NavLink>}
                <div className={"btn"} onClick={() => handlerAction(2)}>
                    <div style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        columnGap: "5px",
                        alignContent: "center",
                        alignItems: "center"
                    }}><CgMenuGridR size={18}/>
                        <div>О нас</div>
                    </div>
                </div>
            </div>





                <div className={"catalog-container"} style={{ maxHeight: open ? "90vh" : "0", boxShadow: open ? "0 5px 5px rgba(220, 220, 220, 0.36)" : "none"}}>
                    <div className={"menu-container"}>
                        {(page === 1) && catalogItems.map((filter, index) => {
                            return (<NavbarItem type={"catalog"} title={filter.title} link={filter.link} filters={getFilterList(filter.link)} key={index} setFilter={setFilter} setOpen={setOpen}/>)
                        })}

                        {(page === 2) &&
                            <NavbarItem type={"links"} title={aboutUsItems.title} link={aboutUsItems.link} filters={aboutUsItems.filters} setFilter={setFilter} setOpen={setOpen}/>
                        }
                    </div>
                </div>

        </div>
    );
};

export default CatalogMenu;