import React from 'react';

const Price = ({price, setPrice, currentPrice}) => {


    return (
        <div className={currentPrice.size === price.size ? "product-size active" : "product-size"} onClick={() => setPrice(price)}>
            {price.size}
        </div>
    );
};

export default Price;