import React from 'react';
import apiData from "../../helpers/ApiData";
import {convertToMoneyFormat} from "../../helpers/Utils";
import {NavLink} from "react-router-dom";
import {FaTrashAlt} from "react-icons/fa";

const ModalCartItem = ({item, deleteItem, type}) => {


    return (
        <>
            {item && <div className={"modal__cart_item"}>
                <img src={apiData.getAttachment(item.item.attachments[0].downloadLink)} alt={""}/>
                <div className={"modal__cart_item_description"}>
                    <NavLink to={"/product/" + item.item.id}
                             style={{fontSize: "15px", width: "100%", textAlign: "start"}}>
                        {item.item.name}
                    </NavLink>
                    <div style={{marginLeft: "5px", marginTop: "10px"}}>
                        {convertToMoneyFormat(item.price.currentPrice)} Р {type === 'cart' && "x " + item.quantity + " шт."}
                    </div>
                    <div><FaTrashAlt className={"delete-btn"} onClick={() => deleteItem(item, type)}/></div>

                </div>
            </div>}
        </>

    );
};

export default ModalCartItem;