import React, {useEffect} from 'react';
import {getAdminUsers} from "../../../components/axios/UserRequests";
import AxiosError from "../../../components/axios/AxiosError";
import AdminUser from "./AdminUser";

const AdminUsersPage = ({user, showMessage, openModal, closeModal}) => {

    const [users, setUsers] = React.useState([]);

    useEffect(() => {
        getAdminUsers(user.credentials).then((resp) => setUsers(resp.data)).catch((err) => AxiosError(err, showMessage));
    }, []);




    return (
        <div className={"common-page"} style={{borderRadius: "5px", gap: "5px"}}>
            <div className={"users-title"}>
                <div>Имя</div>
                <div>Email</div>
                <div>Телефон</div>
                <div>Роль</div>
            </div>
            {users.map((user, index) => {
                return <AdminUser user={user} key={index} index={index} showMessage={showMessage} openModal={openModal} closeModal={closeModal}/>
            })}
        </div>
    );
};

export default AdminUsersPage;