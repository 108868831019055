import React, {useEffect, useState} from 'react';
import {BiLoaderCircle} from "react-icons/bi";
import {FaCheck} from "react-icons/fa6";
import {TiWarning} from "react-icons/ti";
import PreviewImages from "./PreviewImages";
import {Field, Form, Formik} from "formik";
import PricesContainer from "./PricesContainer";
import {useParams} from "react-router-dom";
import {
    allCategories,
    categoriesList,
    decorCategories,
    flowerPassCategories,
    flowersAndGiftsCategories,
    mainCategories,
    pottedPlantsCategories
} from "../../helpers/categoriesList";
import {priceList} from "../../helpers/PriceHelper";
import GetProductById from "../../components/axios/GetProductById";
import AxiosError from "../../components/axios/AxiosError";
import {updateProduct} from "../../components/axios/ProductRequests";
import Select from "react-select";

const EditProductPage = ({user, showMessage, reloadProducts}) => {

    const {id} = useParams();

    const [options, setOptions] = useState(allCategories);

    const mainOptions = mainCategories;

    const [mainCategory, setMainCategory] = useState();

    const [categories, setCategories] = useState([]);

    const [images, setImages] = useState([]);
    const [prices, setPrices] = useState(priceList);

    const [showWaitIcon, setShowWaitIcon] = useState(false);
    const [iconState, setIconState] = useState(1);

    const [product, setProduct] = useState();

    useEffect(() => {
        fetchAllData().catch((err) => AxiosError(err, showMessage))
    }, [id])


    useEffect(() => {
        switch (mainCategory) {
            case "FLOWER_AND_GIFTS":
                setOptions(flowersAndGiftsCategories);
                break;
            case "POTTED_PLANTS":
                setOptions(pottedPlantsCategories);
                break;
            case "DECOR":
                setOptions(decorCategories);
                break;
            case "FLOWER_PASS":
                setOptions(flowerPassCategories);
                break;
            default:
                setOptions(categoriesList);
                break;
        }

    }, [mainCategory])


    useEffect(() => {
        const attachments = [undefined, undefined, undefined, undefined];
        let pricesList = Object.assign([], priceList);
        if (product) {
            if (product.attachments) {
                for (let i = 0; i < product.attachments.length; i++) {
                    attachments[i] = product.attachments[i];
                }
            }

            pricesList = priceList.map((item) => {
                let price = product.prices.filter((price) => item.size === price.size);

                if (price.length > 0) {
                    price[0].index = item.index;
                    price[0].id = undefined;
                    return price[0];
                } else {
                    return item;
                }
            })
            setCategories(resolveCategories());


        }
        setPrices(pricesList);
        setImages(attachments);

    }, [product]);


    async function update(values) {
        setShowWaitIcon(true);
        let formData = new FormData();
        let attachments = [];

        for(let file of images) {
            if (file) {
                if (file.id) {
                    attachments.push(file);
                }
                else {
                    formData.append('images', file);
                }
            }
        }

        product.name = values.name;
        product.description = values.description;
        product.readyTime = values.readyTime;
        product.attachments = attachments;
        product.isAvailable = values.isAvailable;
        product.isHidden = values.isHidden;
        product.categories = categories.map((el) => el.value);

        product.categories.push(mainCategory.value);

        product.prices = prices.filter((item) => item.currentPrice !== 0);


        formData.append('product', new Blob([JSON.stringify(product)], {type: "application/json"}))


        
        await updateProduct(product.id, formData, user.credentials).then((response) => {
            setProduct(response.data);
            setIconState(2);
        }).catch((err) => AxiosError(err, showMessage));

    }

    const fetchAllData = async () => {
        await GetProductById(id).then((response) =>
            setProduct(response.data));
    }

    function closeModalWindow() {
        setIconState(1);
        setShowWaitIcon(false);
    }

    function resolveCategories() {
        let newCategories = [];


        product.categories.forEach((cat) => {
            switch (cat) {
                case "FLOWER_AND_GIFTS":
                    setMainCategory(mainCategories[0]);
                    break;
                case "POTTED_PLANTS":
                    setMainCategory(mainCategories[2]);
                    break;
                case "DECOR":
                    setMainCategory(mainCategories[1]);
                    break;
                case "FLOWER_PASS":
                    setMainCategory(mainCategories[3]);
                    break;
                default: {
                    let foundedCat = options.find((el) => el.value === cat);
                    if (foundedCat) {
                        newCategories.push(foundedCat);
                    }
                }
            }
        });

        return newCategories;
    }



    return (
        <div className={"add-product-page"}>
                {showWaitIcon &&
                    <div className={"modal-wait-content"}>
                        <div className={"modal-wait-window"}>
                            {iconState === 1 && <div className={"info-message"} ><BiLoaderCircle className={"rotate-icon"}/><div style={{textAlign: "start", fontSize: "14px"}}>Ожидайте</div></div>}
                            {iconState === 2 && <div className={"info-message"}><FaCheck className={"icon"}/><div style={{textAlign: "start", fontSize: "14px"}}>Запрос выполнен успешно</div></div>}
                            {iconState === 3 && <div className={"info-message"}><TiWarning className={"icon"} style={{color: "darkred"}}/><div style={{textAlign: "start", fontSize: "14px"}}>Произошла ошибка при выполнении запроса на сервер.</div></div>}
                            <button className={"close-btn"} onClick={() => closeModalWindow()}>Закрыть окно</button>
                        </div>
                    </div>}
                <div className={"title"}>Редактирование карточки товара</div>
                <PreviewImages images={images} state={iconState}/>
            {(product && mainCategory) && <Formik
                    enableReinitialize="true"
                    initialValues={{name: product.name,
                        description: product.description,
                        isAvailable: product.isAvailable,
                        isHidden: product.isHidden,
                        readyTime: product.readyTime,
                    }}
                    onSubmit={(values, {resetForm}) => {
                        update(values);
                        resetForm();}}>
                    {() => (
                        <Form className={"edit-form"}>
                            <div className="form-group">
                                <label className="form-label" htmlFor="name">Название товара (макс. 64 символ)</label>
                                <Field className="form-control" maxLength={64} required
                                       type="text"
                                       name="name"
                                />
                            </div>

                            <PricesContainer prices={prices} setPrices={setPrices} type={"edit"}/>

                            <div className="form-group">
                                <label className="form-label" htmlFor="description">Описание товара (макс. 1024 символа)</label>
                                <Field className="textarea" maxLength={1024} as="textarea" required
                                       type="text"
                                       name="description"/>
                            </div>

                            <div className="form-group">
                                <label className="form-label" htmlFor="isAvailable">Статус товара</label>
                                <Field className="form-control" style={{backgroundColor: "white"}}
                                       name="isAvailable" as="select" required>
                                    <option value="true">Есть в наличии</option>
                                    <option value="false">Доступен к заказу</option>
                                </Field>
                            </div>

                            <div className="form-group">
                                <label className="form-label" htmlFor="isHidden">Видимость товара (клиентам)</label>
                                <Field className="form-control" style={{backgroundColor: "white"}}
                                       name="isHidden" as="select" required>
                                    <option value="true">Скрыт</option>
                                    <option value="false">Виден</option>
                                </Field>
                            </div>

                            <div className="form-group">
                                <label className="form-label" htmlFor="readyTime">Время изготовления (в часах)</label>
                                <Field className="form-control"
                                       type="number"
                                       min={"0"}
                                       name="readyTime"/>
                            </div>

                            <div className="form-group">
                                <label className="form-label">Главная категория (обязательно)</label>
                                <Select required
                                        isSearchable={false}
                                        value={mainCategory}
                                        options={mainOptions}
                                        isDisabled={categories && categories.length > 0}
                                        className={"select-field"}
                                        onChange={(element) =>
                                            setMainCategory(element)
                                        }
                                />
                            </div>

                            <div className="form-group">
                                <label className="form-label" htmlFor="categories">Подкатегории</label>
                                <Select
                                    defaultValue={categories}
                                    value={categories}
                                    className={"select-field"}
                                    isMulti={true}
                                    onChange={(e) => setCategories(e)}
                                    options={options}
                                    isSearchable={false}
                                />
                            </div>
                            <div style={{
                                width: "auto",
                                display: "flex",
                                flexWrap: "nowrap",
                                gap: "15px",
                                justifyContent: "space-around",
                                marginTop: "40px"
                            }}>
                                <button type="submit" className="btn" style={{width: "auto"}}>Save product</button>
                            </div>

                        </Form>
                    )}
            </Formik>}
        </div>
    );
};

export default EditProductPage;