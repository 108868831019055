import React from 'react';
import "./style.css";
import Items from "../components/item/Items";
import Presentation from "../components/header/Presentation";
import CreateBouquet from "../components/banners/CreateBouquet";
import Filter from "../components/filter/Filter";
import Sort from "../components/sort/Sort";



const Home = (props) => {

    return (
            <div className={"main-block"}>
                <Presentation/>
                <div className={"home-filter-sort-container"}>
                    <Filter dropdawn={true} filterList={props.filterItems} filter={props.filter} setActiveFilter={props.setFilter} />
                    <Sort setSort={props.setSort} />
                </div>
                <Items isItemAddedToCart={props.isItemAddedToCart} addItemToCart={props.addItemToCart} items={props.products}/>
                <CreateBouquet openModal={props.openModal}/>
            </div>

    );
};

export default Home;