import React, {useState} from 'react';
import {Field, Form, Formik} from "formik";
import InputMask from "react-input-mask";
import axios from "axios";
import apiData from "../../helpers/ApiData";
import * as yup from 'yup';
import AxiosError from "../../components/axios/AxiosError";
import {NavLink} from "react-router-dom";
import { Buffer } from 'buffer';

const Register = ({setUser, showMessage}) => {

    const [phone, setPhone] = useState("");

    const validationsSchema = yup.object().shape({
        name: yup.string().typeError('Должно быть строкой').required('обязательно'),
        password: yup.string().typeError('Должно быть строкой').required('обязательно'),
        confirmPassword: yup.string().oneOf([yup.ref('password')], 'Пароли не совпадают').required('обязательно'),
        email: yup.string().matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, "Не соответствует заданному формату").email('Введите верный email').required('обязательно')
    })

    async function register(user) {
        user["phone"] = phone;
        const credentials = "Basic ".concat(Buffer.from(user.email.concat(":").concat(user.password)).toString('base64'));

            await axios({
                method: "POST",
                url: apiData.createUser,
                headers: {"Content-Type": "application/json"},
                // в data передаем объект с данными формы
                data: user,
            })
                .then((response) => {setUser(
                    {
                        credentials: credentials,
                        user: response.data
                    })
                    showMessage("Вы успешно зарегестрированы!");
                    window.location.assign('/');
                })
                .catch((error) => AxiosError(error, showMessage));
    }



    return (
        <div className={"register-page"}>
            <div className={"register-form"}>
                <Formik initialValues={{
                    name: "",
                    password: "",
                    confirmPassword: "",
                    email: ""
                }}
                        validateOnBlur
                        onSubmit={values => register(values)}
                        validationSchema={validationsSchema}>
                    {({values, errors, touched, handleChange, handleBlur, isValid, handleSubmit, dirty}) => (
                        <Form style={{display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
                            <div className={"login-header"}>Регистрация аккаунта</div>
                            <div className="form-group">
                                <Field name="name" required
                                       placeholder="* Имя"
                                       value={values.name}
                                       className="form-control"
                                       type={"text"}/>
                                {touched.name && errors.name && <div className={'error'}>{errors.name}</div>}
                            </div>
                            <div className="form-group">
                                <Field name="email" required
                                       placeholder="* Email/Логин"
                                       value={values.email}
                                       className="form-control"
                                       type={"email"}/>
                                {touched.email && errors.email && <div className={'error'}>{errors.email}</div>}

                            </div>
                            <div className="form-group">
                                <InputMask mask="+7 (999)-999-99-99" value={phone}
                                           onChange={(event) => setPhone(event.currentTarget.value)}>
                                    {() => <input required
                                                  placeholder={"* Телефон"}
                                                  type="tel"
                                                  className="form-control"
                                                  ref={phone}
                                    />}
                                </InputMask>
                            </div>
                            <div className="form-group">
                                <Field name="password" required
                                       placeholder={"* Пароль"}
                                       value={values.password}
                                       className="form-control"
                                       type="password"/>
                                {touched.password && errors.password &&
                                    <div className={'error'}>{errors.password}</div>}
                            </div>

                            <div className="form-group">
                                <Field name="confirmPassword" required
                                       placeholder={"* Подтвердите пароль"}
                                       value={values.confirmPassword}
                                       className="form-control"
                                       type="password"/>
                                {touched.confirmPassword && errors.confirmPassword &&
                                    <div className={'error'}>{errors.confirmPassword}</div>}
                            </div>
                            <button type="submit" disabled={!isValid || !dirty} className="btn">Отправить</button>
                        </Form>
                    )}
                </Formik>
                <div className={"private-policy-check"}>Нажимая кнопку "Отправить", Вы соглашаетесь с условиями <NavLink
                    to={"/privacy-policy"} style={{color: "#0b9b9f"}} className={"private-policy-check"}>политики
                    конфиденциальности</NavLink></div>
                <div className={"registration-info"}>Уважаемый клиент, если Вы уже совершали покупки в нашем магазине,
                    убедительно просим Вас указать тот же номер телефона, чтобы мы могли синхронизировать Ваш бонусный
                    счет.
                </div>

            </div>
        </div>
    );
};

export default Register;