import axios from "axios";
import apiData from "../../helpers/ApiData";

export async function updateProduct(id, product, credentials) {

    return axios({
        method: "PUT",
        url: apiData.updateProduct(id),
        headers: {"Content-Type": "multipart/form-data", "Authorization": credentials},
        // в data передаем объект с данными формы
        data: product
    });
}

export async function deleteProduct(id, credentials) {

    return axios({
        method: "DELETE",
        url: apiData.deleteProduct(id),
        headers: {"Authorization": credentials},
        // в data передаем объект с данными формы
    });
}

export async function getProducts(activeFilter, activeSort) {
    return axios({
        method: "GET",
        url: apiData.productsPage(activeFilter, activeSort ? activeSort : "id::desc"),
        // в data передаем объект с данными формы
    });
}

export async function getAdminAllProducts(credentials, isHidden, isAvailable, categories, sortBy) {
    return axios({
        method: "GET",
        url: apiData.getAdminAllProducts(isHidden, isAvailable, categories, sortBy),
        headers: {"Authorization": credentials},
    });
}

export async function searchProducts(searchName, activeSort) {
    return axios({
        method: "GET",
        url: apiData.searchProducts(searchName, activeSort ? activeSort : ""),
        // в data передаем объект с данными формы
    });
}

export async function getProductById(id) {
    return axios.get(apiData.getProduct(id));
}

export async function createProduct(product, credentials) {

    return axios({
        method: "POST",
        url: apiData.addProduct,
        headers: {"Content-Type": "multipart/form-data", "Authorization": credentials},
        // в data передаем объект с данными формы
        data: product,
    });
}
