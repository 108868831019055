import React, {useEffect, useState} from 'react';
import apiData from "../../../helpers/ApiData";
import "./admin-pages.css"
import {getDiscount, getVendorCode} from "../../../helpers/Utils";
import {allCategories} from "../../../helpers/categoriesList";
import {NavLink} from "react-router-dom";
import {FiEdit} from "react-icons/fi";

const AdminProduct = ({product, showMessage}) => {

    const [curPrice, setPrice] = useState({});

    useEffect(() => {
        setPrice(product.prices[0]);
    }, [product]);


    function changePrice(index) {
        setPrice(product.prices[index]);
    }

    function getCategories() {
        let categories = allCategories.filter((cat) => product.categories.includes(cat.value));

        let string = "";
        for (let c in categories) {
            string = string.concat(categories[c].label).concat(", ");
        }

        return string.slice(0, -2);
    }

    function getEmptyReview(index) {
        return <img key={index} src={"http://placehold.it/1000x1000"} alt={"product-img"}/>
    }

    function getImagePreviews() {
        return <div
            style={{width: "100%", display: "flex", flexWrap: "wrap", justifyContent: "space-between", rowGap: "10px"}}>
            {product.attachments.map((at, index) => {
                return <img key={index} src={apiData.getAttachment(at.downloadLink)} alt={"product-img"}/>
            })}
            {Array(4 - product.attachments.length).fill().map((it, index) => {
                return getEmptyReview(index);
            })}
        </div>
    }


    return (
        <div className={"admin-product"}>
            <div style={{
                width: "100%",
                height: "auto",
                display: "flex",
                flexWrap: "wrap",
                gap: "5px",
                justifyContent: "space-between",
                alignItems: "center"
            }}>
                <NavLink to={"/product/" + product.id} className={"title"}>{product.name}</NavLink>
                <div style={{
                    width: "auto",
                    backgroundColor: product.isHidden ? "#810f0f" : "#79ab8c",
                    color: "white",
                    height: "min-content",
                    padding: "3px 6px",
                    borderRadius: "2px",
                    fontSize: "12px",
                    fontWeight: "500"
                }}>{product.isHidden ? "скрыто" : "активно"}</div>
                <NavLink to={"/edit-product/" + product.id}><FiEdit
                    size={20}/></NavLink>
            </div>
            {getImagePreviews()}
            <div style={{width: "100%"}}>
                <div className={"info"}><span>Артикул </span>
                    <div>{getVendorCode(product.id)}</div>
                </div>
                <div className={"info"}><span>Размеры </span>
                    <div style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        gap: "10px"
                    }}>{product.prices.map((price, index) => {
                        return <div key={index} style={{
                            cursor: "pointer",
                            fontWeight: "600",
                            color: price === curPrice ? "#810f0f" : "black"
                        }} onClick={() => changePrice(index)}>{price.size}</div>
                    })}</div>
                </div>
                <div className={"info"}><span>Категории </span>
                    <div style={{width: "auto", textAlign: "end"}}>{getCategories()}</div>
                </div>
                <div className={"info"}><span>Статус </span>
                    <div>{product.isAvailable ? "В наличии" : "Доступен к заказу"}</div>
                </div>
                <div className={"info"}><span>Цена old/cur/disc </span>
                    <div>{curPrice.oldPrice} Р / {curPrice.currentPrice} Р
                        / {getDiscount(curPrice.oldPrice, curPrice.currentPrice)} %
                    </div>
                </div>

            </div>

        </div>
    );
};

export default AdminProduct;