import React, {useEffect, useState} from 'react';
import '../account-style.css';
import {useParams} from "react-router-dom";
import AxiosError from "../../../components/axios/AxiosError";
import {
    changeOrderStatus,
    getAndCheckPaymentOrder,
    getAndCheckPaymentUserOrder
} from "../../../components/axios/OrderRequests";
import {getStatusOrder} from "../../../helpers/Utils";
import {FaCheck, FaXmark} from "react-icons/fa6";
import {FaPlay} from "react-icons/fa";
import {TbTruckDelivery} from "react-icons/tb";
import {BsBagCheckFill} from "react-icons/bs";
import {getProductById} from "../../../components/axios/ProductRequests";
import OrderPageProductImage from "./OrderPageProductImage";
import "./order-style.css"

const OrderPage = ({user, showMessage}) => {
    const {id} = useParams();
    const [order, setOrder] = useState();

    useEffect(() => {
        if (user) {
            loadOrder();
        }
    }, [id, user]);

    async function loadOrder() {
        if (user.user.role === 'ADMIN') {
            getAndCheckPaymentOrder(id, user.credentials).then((response) => {
                setOrder(response.data);
            }).catch((err) => AxiosError(err, showMessage));
        }
        else {
            getAndCheckPaymentUserOrder(user.user.id, id, user.credentials).then((response) => {
                setOrder(response.data);
            }).catch((err) => AxiosError(err, showMessage));
        }
    }

    function getProductImage(id) {
        let product = getProductById(id).then((resp) => {
            return resp
        }).catch((err) => AxiosError(err, showMessage));

        if (product) {
            return product.then((resp) => resp.data.attachments[0].downloadLink);
        } else {
            return "https://placehold.it/350x50";
        }

        return "https://placehold.it/100x150";
    }

    return (
        <>
            {order && <div className={"order-container"}>


                <div className={"order-products-container"}>
                    <div className={"order-title"}>
                        <div>Заказ {order.number} от {order.created}</div>
                        <div>{getStatusOrder(order.status)}</div>
                    </div>
                    <div className={"order-products-container-2"}>
                        <div style={{fontSize: "16px", marginBottom: "10px"}}>Товары в заказе</div>
                        {order.productList.map((p, index) => {
                            return <OrderPageProductImage orderProduct={p} showMessage={showMessage}/>
                        })}
                        {order.delivery === "DELIVERY" &&
                            <div className={"order-products-item"}><span>Доставка:</span> {order.deliveryCost} ₽</div>}
                        {order.addPostcard === true &&
                            <div className={"order-products-item"}><span>Открытка:</span> {order.postcardCost} ₽</div>}
                        <div style={{fontSize: "18px", margin: "30px 0 10px"}}><span>Итого:</span> {order.orderSum} ₽
                        </div>
                        <div className={"order-products-item"}>
                            <span>Тип оплаты:</span> {order.paymentType === "ONLINE" ? "Банковской картой" : "При получении"}
                        </div>
                        <div className={"order-products-item"}>
                            <span>Статус оплаты:</span> {order.paymentStatus === "PAID_FOR" ? "Оплачено" : "Не оплачено"}
                        </div>


                        <div className={"order-products-item"}><span>Бонусная программа</span></div>
                        <div className={"order-products-item"} style={{marginLeft: "40px"}}>
                            <span>Списано бонусов:</span> {order.bonusPoints}</div>
                        <div className={"order-products-item"} style={{marginLeft: "40px"}}>
                            <span>Будет начислено:</span> {order.awardPoints}</div>
                    </div>


                </div>
                <div className={"order-products-container"}>
                    <div className={"order-products-container-2"}>
                        <div style={{fontSize: "16px", marginBottom: "10px"}}>Дополнительная информация о заказе</div>
                        <div className={"order-products-item"}>
                            <span>Дата {order.delivery === "DELIVERY" ? "доставки" : "самовывоза"}:</span>
                            <div className={"value"}>{order.orderTime}</div>
                        </div>
                        <div className={"order-products-item"}><span>Информация к заказу: </span>
                            <div className={"value"}>{order.deliveryInfo}</div>
                        </div>
                        <div className={"order-products-item"}><span>Тип доставки:</span>
                            <div
                                className={"value"}>{order.delivery === "DELIVERY" ? " до адреса" : " самовывоз"} {order.deliveryContact !== undefined && "по номеру телефона"}</div>
                        </div>
                        {order.delivery === "SELF" &&
                            <div className={"order-products-item"} style={{marginLeft: "40px"}}>
                                <span>Адрес для самовывоза:</span>
                                <div className={"value"}>Самара, ул.Демократическая, 16к1, цветочная мастерская
                                    "Цветоводофф"
                                </div>
                            </div>}
                        {(order.delivery === "DELIVERY" && order.deliveryContact === undefined) &&
                            <div style={{marginLeft: "20px"}}>
                                <div className={"order-products-item"}><span>Город:</span> {order.deliveryCity}</div>
                                <div className={"order-products-item"}><span>Улица:</span> {order.deliveryStreet}</div>
                                <div className={"order-products-item"}><span>Дом:</span> {order.deliveryBuilding}</div>
                                <div className={"order-products-item"}><span>Квартира:</span> {order.deliveryApartment}
                                </div>
                            </div>}
                        {order.deliveryContact !== undefined && <>
                            <div className={"order-products-item"} style={{marginLeft: "40px"}}>
                                <span>Имя получателя:</span>
                                <div className={"value"}>{order.deliveryContact}</div>
                            </div>
                            <div className={"order-products-item"} style={{marginLeft: "40px"}}><span>Телефон получателя:</span>
                                <div className={"value"}>{order.deliveryPhoneNumber}</div>
                            </div>
                        </>}
                        {order.addPostcard === true && <>
                            <div className={"order-products-item"}><span>Добавить открытку:</span>
                                <div className={"value"}>да</div>
                            </div>
                            <div className={"order-products-item"} style={{marginLeft: "40px"}}>
                                <span>Текст открытки:</span>
                                <div className={"value"}>{order.postcardText}</div>
                            </div>
                        </>
                        }
                    </div>
                </div>

                {user.user.role === 'ADMIN' && <div className={"order-products-container"}>
                    <div className={"order-products-container-2"}>
                        <div style={{fontSize: "16px", marginBottom: "10px"}}>Информация о заказчике</div>
                        <div className={"order-products-item"}><span>Имя:</span>
                            <div className={"value"}>{order.user.name}</div>
                        </div>
                        <div className={"order-products-item"}><span>Телефон:</span>
                            <div className={"value"}>{order.user.phone}</div>
                        </div>
                        <div className={"order-products-item"}><span>Email:</span>
                            <div className={"value"}>{order.user.email}</div>
                        </div>
                    </div>
                </div>}

                <div className={"order-products-container"}>
                    <div className={"order-products-container-2"}>
                        <div style={{fontSize: "16px", marginBottom: "10px", width: "100%"}}>Управление заказом</div>
                        {user.user.role === "ADMIN" &&
                            <div className={"btn"}
                                 onClick={() => changeOrderStatus(order.id, "ACCEPTED", user.credentials)}
                                 style={{
                                     pointerEvents: order.status === "CREATED" ? "all" : "none",
                                     opacity: order.status === "CREATED" ? "0.7" : "0.3"
                                 }}>
                                <FaPlay style={{fontSize: "14px", color: "green"}}/>
                                <div>Подтвердить</div>
                            </div>
                        }
                        {user.user.role === "ADMIN" &&
                            <div className={"btn"}
                                 onClick={() => changeOrderStatus(order.id, "READY", user.credentials)}
                                 style={{
                                     pointerEvents: order.status === "ACCEPTED" ? "all" : "none",
                                     opacity: order.status === "ACCEPTED" ? "0.7" : "0.3"
                                 }}>
                                <BsBagCheckFill size={22} style={{color: "darkcyan"}}/>
                                <div>Собран</div>
                            </div>
                        }
                        {user.user.role === "ADMIN" &&
                            <div className={"btn"}
                                 onClick={() => changeOrderStatus(order.id, "DELIVERED", user.credentials)}
                                 style={{
                                     pointerEvents: order.status === "READY" ? "all" : "none",
                                     opacity: order.status === "READY" ? "0.7" : "0.3"
                                 }}>
                                <TbTruckDelivery size={22} style={{color: "#21770b"}}/>
                                <div>У курьера</div>
                            </div>
                        }
                        <div className={"btn"} onClick={() => changeOrderStatus(order.id, "CANCELED", user.credentials)}
                             style={{
                                 pointerEvents: (order.status !== "FINISHED" && order.status !== "DELIVERED") ? "all" : "none",
                                 opacity: (order.status !== "FINISHED" && order.status !== "DELIVERED") ? "0.7" : "0.3"
                             }}><FaXmark size={22} style={{color: "darkred"}}/>
                            <div>Отменить</div>
                        </div>
                        {user.user.role === "ADMIN" && <>
                            <div className={"btn"}
                                 onClick={() => changeOrderStatus(order.id, "FINISHED", user.credentials)} style={{
                                pointerEvents: order.status !== "FINISHED" && order.status !== "CANCELLED" ? "all" : "none",
                                opacity: order.status !== "FINISHED" && order.status !== "CANCELLED" ? "0.7" : "0.3"
                            }}><FaCheck size={22} style={{color: "darkgreen"}}/>
                                <div>Завершить</div>
                            </div>
                        </>}
                    </div>
                </div>
            </div>}
        </>
    );
};

export default OrderPage;