import React from 'react';
import {Field, Form, Formik} from "formik";
import "./editTabPage.css";
import SelectField from "../../components/CustomSelect";
import {categoriesList, mainCategories, pageList} from "../../helpers/categoriesList";
import {saveTab} from "../../components/axios/TabRequests";
import AxiosError from "../../components/axios/AxiosError";

const AddTab = ({toJson, user, showMessage}) => {

    async function createTab(values) {

        let mainCat = mainCategories.filter((c) => c.label === values.type.label)[0];

        values.categories.push(mainCat);

        console.log(values)

        await saveTab(toJson(values), user.credentials).then((response) => {
            showMessage("Новая вкладка успешно создана")
        }).catch((err) => AxiosError(err, showMessage));
    }

    return (
        <div className={"tab"}>
            <Formik initialValues={{
                type: "",
                title: "",
                img: "",
                index: "",
                btn: "false",
                categories: []}}
                    onSubmit={(values, {resetForm})  => {
                        createTab(values);
                        resetForm();
                    }}>
                { ({values, errors, handleChange}) => (
                    <Form style={{width: "100%", minWidth:"350px", margin: "auto", display: "flex", flexWrap: "wrap", gap: "10px", justifyContent: "space-around"}}>
                        <div className={"login-header"} style={{marginTop: "10px", marginBottom: "0", fontSize: "18px", color: "#494949"}}>Создать новую вкладку</div>
                        <div className="form-group">
                            <label className="form-label" htmlFor="name">Страница *</label>
                            <Field
                                required
                                component={SelectField}
                                isMulti={false}
                                options={pageList}
                                name="type"/>
                        </div>
                        <div className="form-group">
                            <label className="form-label">Заголовок *</label>
                            <Field name="title" required
                                   className="form-control"
                                   type={"text"}/>
                        </div>
                        <div className="form-group">
                            <label className="form-label">Название баннера c расширением *</label>
                            <Field name="img"  required
                                   className="form-control"
                                   type={"text"}/>
                        </div>
                        <div className="form-group">
                            <label className="form-label" >Индекс вкладки (порядковый номер) *</label>
                            <Field name="index"  required
                                   className="form-control"
                                   type={"number"}/>
                        </div>
                        <div className="form-group">
                            <label className="form-label" htmlFor="password">Выделенная вкладка *</label>
                            <Field
                                className="form-control" style={{height: "32px", fontSize: "14px"}}
                                name="btn" as="select" required>
                                <option value="true">Да</option>
                                <option value="false">Нет</option>
                            </Field>
                        </div>
                        <div className="form-group">
                            <label className="form-label" htmlFor="categories">Категории (обязательно выбрать мин. 1 кат.)</label>
                            <Field
                                required
                                component={SelectField}
                                isMulti={true}
                                options={categoriesList}
                                name="categories"/>
                        </div>
                        <div style={{width: "100%", justifyContent: "center", display: "flex"}}>
                            <button type="submit" className="btn">Сохранить</button>
                        </div>
                    </Form>
                )}
            </Formik>

        </div>
    );
};

export default AddTab;