import React, {useEffect} from 'react';
import {Field, Form, Formik} from "formik";
import SelectField from "../../components/CustomSelect";
import {allCategories, categoriesList, pageList} from "../../helpers/categoriesList";
import "./editTabPage.css";
import {deleteTab, updateTab} from "../../components/axios/TabRequests";
import AxiosError from "../../components/axios/AxiosError";
import {FaTrashAlt} from "react-icons/fa";


const EditTab = ({tab, user, toJson, showMessage}) => {


    async function update(values) {
        const updated = toJson(values);
        updated.id = tab.id;
        await updateTab(updated, tab.id, user.credentials)
            .then((response) => {
                showMessage("Вкладка успешно обновлена");
                tab = response.data;
            })
            .catch((err) => AxiosError(err, showMessage));
    }


    useEffect(() => {
        console.log("tab updated")
    }, [tab]);

    function getType() {
        return pageList.filter((el) => el.value === tab.type)[0];
    }

    async function remove() {
        await deleteTab(tab.id, user.credentials)
            .then((response) => {
                window.location.reload();
            })
            .catch((err) => AxiosError(err, showMessage))
    }

    function getCategories() {
        return tab.categories.map((cat) => {
            return {value: cat, label: allCategories.filter((c) => c.value === cat)[0].label};
        })
    }

    return (
        <div className={"tab"}>
            <Formik initialValues={{
                type: getType(),
                title: tab.title,
                img: tab.img,
                index: tab.index,
                btn: tab.btn,
                categories: getCategories()}}
                    onSubmit={(values)  => update(values)}>
                { ({values, errors, handleChange}) => (
                    <Form style={{width: "100%", minWidth:"350px", margin: "auto", display: "flex", flexWrap: "wrap", gap: "10px", justifyContent: "space-around"}}>
                        <div className="form-group">
                            <label className="form-label" htmlFor="name">Страница *</label>
                            <Field
                                required
                                component={SelectField}
                                isMulti={false}
                                value={values.type}
                                options={pageList}
                                name="type"/>
                        </div>
                        <div className="form-group">
                            <label className="form-label" htmlFor="email">Заголовок *</label>
                            <Field name="title" required
                                   value={values.title}
                                   className="form-control"
                                   type={"text"}/>
                        </div>
                        <div className="form-group">
                            <label className="form-label" htmlFor="phone">Название баннера c расширением *</label>
                            <Field name="img"  required
                                   value={values.img}
                                   className="form-control"
                                   type={"text"}/>
                        </div>
                        <div className="form-group">
                            <label className="form-label" >Индекс вкладки (порядковый номер) *</label>
                            <Field name="index"  required
                                   className="form-control"
                                   type={"number"}/>
                        </div>
                        <div className="form-group">
                            <label className="form-label" htmlFor="password">Выделенная вкладка *</label>
                                <Field
                                    className="form-control" style={{height: "32px"}}
                                    name="btn" as="select" required>
                                    <option value="true">Да</option>
                                    <option value="false">Нет</option>
                                </Field>
                        </div>
                        <div className="form-group">
                            <label className="form-label" htmlFor="categories">Категории (обязательно выбрать мин. 1 кат.)</label>
                            <Field
                                required
                                value={values.categories}
                                component={SelectField}
                                isMulti={true}
                                options={categoriesList}
                                name="categories"/>
                        </div>

                        <div style={{width: "100%", justifyContent: "center", display: "flex", gap: "10px", flexWrap: "wrap"}}>
                            <button type="submit" className="btn">Обновить</button>
                        </div>
                    </Form>
                )}
            </Formik>
            <button type={"button"} style={{position: "absolute", bottom: "10px", right: "10px", backgroundColor: "white", border: "none", cursor: "pointer"}} onClick={() => remove()}>< FaTrashAlt size={25}/></button>
        </div>
    );
};

export default EditTab;