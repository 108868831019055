import React from 'react';
import "./style.css";
import {Dropdown} from 'react-dropdown-now';
import {BsSortAlphaDown, BsSortAlphaDownAlt, BsSortAlphaUp} from "react-icons/bs";
import {useResize} from "../../helpers/windowSizes/use-resize";

const Sort = ({setSort}) => {

    const windowSize = useResize();


    const options = [
        {value: "available::asc",
            label: <div style={{display: "flex", alignContent: "center", gap: "5px"}}><BsSortAlphaDown size={14}/>по
                наличию</div>
        },
        {value: "price::asc",
            label: <div style={{display: "flex", alignContent: "center", gap: "5px"}}><BsSortAlphaDown size={14}/>по
                увелечению цены</div>
        },
        {value: "price::desc",
            label: <div style={{display: "flex", alignContent: "center", gap: "5px"}}><BsSortAlphaUp size={14}/>по
                уменьшению цены</div>
        },
        {value: "name::asc",
            label: <div style={{display: "flex", alignContent: "center", gap: "5px"}}><BsSortAlphaDown size={14}/>по
                алфавиту А-Я</div>
        },
        {value: "name::desc",
            label: <div style={{display: "flex", alignContent: "center", gap: "5px"}}><BsSortAlphaDownAlt size={14}/>по
                алфавиту Я-А</div>
        },
    ];


    return (

        <div className={"sort-block"}>
            {windowSize.isScreenL ? <div className={"button-sort"}>
                <Dropdown
                    baseClassName={"rdn"}
                    className={"dropdown"}
                    placeholder={<div style={{display: "flex", gap: "5px", alignContent: "center"}}><BsSortAlphaDown
                        size={14}/>сортировка...</div>}
                    options={options}
                    onChange={(value) => setSort(value.value)}
                    />
            </div> : <div className={"mobile-button-sort"}>
                <Dropdown
                    baseClassName={"rdn"}
                    className={"dropdown"}
                    placeholder={<BsSortAlphaDown size={12}/>}
                    options={options}
                    onChange={(value) => setSort(value.value)}
                />
            </div>}
        </div>
    );
};

export default Sort;