import React from 'react';
import "./make-bouquet.css";
import { BsTelegram, BsWhatsapp, BsInstagram } from 'react-icons/bs';
import { SlSocialVkontakte } from 'react-icons/sl';
import {useResize} from "../../helpers/windowSizes/use-resize";

const LinksToSocialNetworks = () => {

    const windowSize = useResize();

    return (
        <div className={"modal__content"} style={{padding: "0", overflow: "hidden", display: "flex", borderRadius: "5px", maxHeight: "500px"}} onClick={e => e.stopPropagation()}>
            {windowSize.isScreenM && <>
                <img className={"make-img"} src={"./img/banners/make-bouquet-img.jpg"} alt={"img"}/>
                <div className={"text-img"}>COБИРЕМ<p>УНИКАЛЬНЫЙ БУКЕТ</p><p>ДЛЯ ВАС</p> <p>И ВАШИХ БЛИЗКИХ</p></div>
            </>}

            <div className={"text-block"}>
                <div className={"title"}>Для заказа букета, напишите нам по любой ссылке ниже:</div>
                <div className={"links"}>
                    <div style={{ marginBottom: "20px", height: "auto", position: "relative"}}>
                        <BsTelegram className={"icon"}/>
                        <a href={"https://t.me/cvetovodoff_zakazy"} target="_blank" rel={"noreferrer"}>Telegram</a>
                    </div>

                    <div style={{ marginBottom: "20px", height: "auto", position: "relative"}}>
                        <BsWhatsapp className={"icon"} />
                        <a href={"https://wa.me/79179796252"} target="_blank" rel={"noreferrer"}>WhatsApp</a>
                    </div>

                    <div style={{ marginBottom: "20px", height: "auto", position: "relative"}}>
                        <SlSocialVkontakte className={"icon"} />
                        <a href={"https://vk.com/cvetovodoff"} target="_blank" rel={"noreferrer"}>VK</a>
                    </div>

                    <div style={{ marginBottom: "20px", height: "auto", position: "relative"}}>
                        <BsInstagram className={"icon"} />
                        <a href={"https://www.instagram.com/cvetovodoff/"} target="_blank" rel={"noreferrer"}>Instagram</a>
                    </div>
                </div>
                <div className={"title"}>Наши квалифицированные флористы с радостью помогут Вам, в разумные сроки, собрать букет с учетом всех ваших пожеланий</div>
            </div>
        </div>
    );
};

export default LinksToSocialNetworks;