import React from 'react';

import img1 from "../img/kp/1.png"
import img2 from "../img/kp/2.png"
import img3 from "../img/kp/3.png"
import img4 from "../img/kp/4.png"
import img5 from "../img/kp/5.png"
import img6 from "../img/kp/6.png"

const ForCorporate = () => {


    return (

        <div className={"page-block"}>
            <div className={"data-block"}>
                <img src={img1} alt={"1"}/>
                <img src={img2} alt={"2"}/>
                <img src={img3} alt={"3"}/>
                <img src={img4} alt={"4"}/>
                <img src={img5} alt={"5"}/>
                <img src={img6} alt={"6"}/>

            </div>
        </div>

    );
};

export default ForCorporate;