export function getOptionsFromPrices(prices) {
    let size = prices.sort(function (o1, o2) { return o1.currentPrice >= o2.currentPrice });

   return size.map((item) => {
        return {
            value: item.size,
            label: item.size,
        }
    });
}

export function convertToMoneyFormat(price) {
    if (price) {
        return price.toLocaleString('ru-RU');
    }
}

export function getDiscount(oldPrice, currentPrice) {
    let result = Number.parseInt(oldPrice) > Number.parseInt(currentPrice) ? Math.round((oldPrice - currentPrice) / (oldPrice / 100)) : 0;
    return result;
}

export function getDayFromPrepareTime(prepareTime) {
    const lastHour = 22;
    const date = new Date();
    const currentHour = date.getHours();

    if (lastHour - prepareTime > currentHour) {
        return "сегодня";
    }

    else if (prepareTime < 24) {
        return "завтра";
    }
    else if (prepareTime < 48) {
        return "послезавтра";
    }
    else {
        let countDays = prepareTime / 24 + 1;
        return "через " + Math.round(countDays) + " д."
    }
}

export function getPriceBySize(prices, size) {
    let price = prices.filter((el) => el.size === size);

    return price[0];
}


export function getMinPrice(prices) {

    if (prices.length > 1) {
        let pricesNew = prices.sort((x,y) => {
            return x.currentPrice >= y.currentPrice;
        })
        return pricesNew[0];
    }
    return prices[0];
}

export function getVendorCode(id) {
    let zeroLength = 6;
    return (id + '').padStart(zeroLength, '0');
}

export function getStatusOrder(status) {
    import("../index.css")
    switch (status) {
        case 'CREATED':
            return <div className={"order-status-indicator"}
                        style={{backgroundColor: "rgba(97,176,112,0.7)"}}>Новый</div>
        case 'ACCEPTED':
            return <div className={"order-status-indicator"}
                        style={{backgroundColor: "rgba(33,153,168,0.7)"}}>Принят</div>
        case 'CANCELED':
            return <div className={"order-status-indicator"}
                        style={{backgroundColor: "rgba(121,11,11,0.7)"}}>Отменен</div>
        case 'FINISHED':
            return <div className={"order-status-indicator"}>Завершен</div>
        case 'DELIVERED':
            return <div className={"order-status-indicator"} style={{backgroundColor: "rgba(201,141,12,0.7)"}}>У
                курьера</div>
        case 'READY':
            return <div className={"order-status-indicator"}
                        style={{backgroundColor: "rgba(23,201,176,0.7)"}}>Собран</div>
        default:
            return <div className={"order-status-indicator"}>Неизвестен</div>
    }
}