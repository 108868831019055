import React, {useEffect, useState} from 'react';
import {Field, Form, Formik} from "formik";
import "./productPage.css";
import {priceList} from "../../helpers/PriceHelper";
import AxiosError from "../../components/axios/AxiosError";
import {
    allCategories,
    categoriesList,
    decorCategories,
    flowerPassCategories,
    flowersAndGiftsCategories,
    mainCategories,
    pottedPlantsCategories
} from "../../helpers/categoriesList";
import PreviewImages from "./PreviewImages";
import {BiLoaderCircle} from "react-icons/bi";
import {FaCheck} from "react-icons/fa6";
import {TiWarning} from "react-icons/ti";
import PricesContainer from "./PricesContainer";
import {createProduct} from "../../components/axios/ProductRequests";
import Select from "react-select";

const AddProductPage = ({user, showMessage}) => {

    const [options, setOptions] = useState(allCategories);

    const mainOptions = mainCategories;

    const [mainCategory, setMainCategory] = useState();

    const [categories, setCategories] = useState([]);

    const [images, setImages] = useState([undefined, undefined, undefined, undefined]);
    const [prices, setPrices] = useState(Object.assign([], priceList));

    const [showWaitIcon, setShowWaitIcon] = useState(false);
    const [iconState, setIconState] = useState(1);

    useEffect(() => {
        switch (mainCategory) {
            case "FLOWER_AND_GIFTS":
                setOptions(flowersAndGiftsCategories);
                break;
            case "POTTED_PLANTS":
                setOptions(pottedPlantsCategories);
                break;
            case "DECOR":
                setOptions(decorCategories);
                break;
            case "FLOWER_PASS":
                setOptions(flowerPassCategories);
                break;
            default:
                setOptions(categoriesList);
                break;
        }

        setCategories([])
    }, [mainCategory])

    useEffect(() => {
        setPrices(Object.assign([], priceList));
    }, [images])

    function checkPricesAndImages() {
        let readyImages = false;
        let readyPrices = false;
        let readyCategory = mainCategory !== undefined;

        for (const img of images) {
            if (img) {
                readyImages = true;
            }
        }

        for (let price of prices) {
            if (price.currentPrice > 0) {
                readyPrices = true;
            }
        }

        return readyImages && readyPrices && readyCategory;

    }

    async function save(values, resetForm) {
        if (!checkPricesAndImages()) {
            showMessage("Добавьте как минимум один прайс, одно фото и главную категорию.")
        }
        else {
            setShowWaitIcon(true);

            let categoriesArray = categories.map((cat) => {
                return cat.value;
            });

            categoriesArray.push(mainCategory);

            values["categories"] = categoriesArray;

            values["prices"] = prices.filter((price) => price.currentPrice > 0);


            let product = JSON.stringify(values);

            let formData = new FormData();
            formData.append('product', new Blob([product], {type: "application/json"}))
            for(let file of images) {
                if (file) {
                    formData.append('images', file);
                }
            }


            await createProduct(formData, user.credentials).then((response) => {
            })
                .catch((error) => {AxiosError(error, showMessage); setIconState(3)});

            if (iconState === 1) {
                setIconState(2);
                resetForm();
            }

            setImages([undefined, undefined, undefined, undefined]);

        }
    }


    function closeModalWindow() {
        setIconState(1);
        setShowWaitIcon(false);
    }


    return (
        <div className={"add-product-page"}>
            {showWaitIcon &&
                <div className={"modal-wait-content"}>
                    <div className={"modal-wait-window"}>
                        {iconState === 1 && <div className={"info-message"} ><BiLoaderCircle className={"rotate-icon"}/><div style={{textAlign: "start", fontSize: "14px"}}>Ожидайте</div></div>}
                        {iconState === 2 && <div className={"info-message"}><FaCheck className={"icon"}/><div style={{textAlign: "start", fontSize: "14px"}}>Товар успешно загружен в базу</div></div>}
                        {iconState === 3 && <div className={"info-message"}><TiWarning className={"icon"} style={{color: "darkred"}}/><div style={{textAlign: "start", fontSize: "14px"}}>Произошла ошибка при загрузке нового товара в базу.</div></div>}
                        <button className={"close-btn"} onClick={() => closeModalWindow()}>Закрыть окно</button>
                    </div>
                </div>}
            <div className={"title"}>Создание карточки товара</div>
            <PreviewImages images={images} state={iconState}/>
            <Formik
                enableReinitialize="true"
                initialValues={{name: "",
                    oldPrice: 0,
                    currentPrice: 0,
                    description: "",
                    isAvailable: "true",
                    isHidden: "false",
                    readyTime: 0,
                }}
                onSubmit={(values, {resetForm}) => {
                    save(values, resetForm);}}>
                {() => (
                    <Form className={"edit-form"}>
                        <div className="form-group">
                            <label className="form-label" htmlFor="name">Название товара (макс. 64 символ)</label>
                            <Field className="form-control" maxLength={64} required
                                   type="text"
                                   name="name"
                            />
                        </div>

                        <PricesContainer prices={prices} setPrices={setPrices} type={"add"}/>

                        <div className="form-group">
                            <label className="form-label" htmlFor="description">Описание товара (макс. 1024 символа)</label>
                            <Field className="textarea" maxLength={1024} as="textarea" required
                                   type="text"
                                   name="description"/>
                        </div>

                        <div className="form-group">
                            <label className="form-label" htmlFor="isAvailable">Статус товара</label>
                            <Field className="form-control" style={{backgroundColor: "white"}}
                                   name="isAvailable" as="select" required>
                                <option value="true">Есть в наличии</option>
                                <option value="false">Доступен к заказу</option>
                            </Field>
                        </div>

                        <div className="form-group">
                            <label className="form-label" htmlFor="isHidden">Видимость товара (клиентам)</label>
                            <Field className="form-control" style={{backgroundColor: "white"}}
                                   name="isHidden" as="select" required>
                                <option value="true">Скрыт</option>
                                <option value="false">Виден</option>
                            </Field>
                        </div>

                        <div className="form-group">
                            <label className="form-label" htmlFor="readyTime">Время изготовления (в часах)</label>
                            <Field className="form-control"
                                   type="number"
                                   min={"0"}
                                   name="readyTime"/>
                        </div>

                        <div className="form-group">
                            <label className="form-label">Главная категория (обязательно)</label>
                            <Select required
                                    isSearchable={false}
                                    options={mainOptions}
                                    className={"select-field"}
                                    onChange={(e) => setMainCategory(e.value)}
                            />
                        </div>

                        <div className="form-group">
                            <label className="form-label" htmlFor="categories">Подкатегории (можно выбрать
                                несколько)</label>
                            <Select
                                isDisabled={mainCategory === undefined}
                                isMulti={true}
                                className={"select-field"}
                                value={categories}
                                onChange={(e) => setCategories(e)}
                                options={options}
                                isSearchable={false}
                            />
                        </div>
                        <button type="submit" className="btn" style={{margin: "100px auto 20px"}}>Save product</button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default AddProductPage;