import React, {useEffect, useState} from 'react';
import GetAndRefreshUser from "../../components/axios/GetAndRefreshUser";
import {FiEdit} from "react-icons/fi";
import InputMask from "react-input-mask";
import * as yup from "yup";
import AxiosError from "../../components/axios/AxiosError";
import {NavLink} from "react-router-dom";
import UserOrders from "./orders/UserOrders";
import {updateUser} from "../../components/axios/UserRequests";
import {useResize} from "../../helpers/windowSizes/use-resize";
import {getUserOrders} from "../../components/axios/OrderRequests";


const PersonalInfo = ({user, setUser, showMessage, login, logout, openModal}) => {

        const [username, setUsername] = useState("");
        const [email, setEmail] = useState("");
        const [phone, setPhone] = useState("");
        const [password, setPassword] = useState("");
    const windowSize = useResize();

        const [edit, setEdit] = useState(false);

        const validationsSchema = yup.object().shape({
            username: yup.string().typeError('Должно быть строкой').required('обязательно'),
            password: yup.string().typeError('Должно быть строкой'),
            confirmPassword: yup.string().oneOf([yup.ref('password')], 'Пароли не совпадают'),
            email: yup.string().matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, "Не соответствует заданному формату").email('Введите верный email').required('обязательно')
        })

    const [orders, setOrders] = useState([]);


    useEffect(() => {
            if (user) {
                setDefaultFieldValues();

                getUserOrders(user.user.id, user.credentials).then((response) => setOrders(response.data))
                    .catch((error) => AxiosError(error, showMessage));
            }
        }, [user])


        function setDefaultFieldValues() {
            setUsername(user.user.name);
            setPhone(user.user.phone);
            setEmail(user.user.email);
            setPassword("");
            setEdit(false);
        }

        function handleAction() {
            setEdit(!edit);
        }


    async function updateUserData() {
        let updatedUser = {
            id: user.user.id,
            name: username,
            password: password.length > 0 ? password : null,
            phone: phone
        }

        await updateUser(updatedUser, user.credentials).then((resp) => {
            if (password.length > 0) {
                login(email, password);
                setPassword("");
            }
        })
            .catch((err) => AxiosError(err, showMessage));
    }

        return (<div className={"account-page"}>
            {!user && <div className={"title"}>Войдите чтобы увидеть информацию о вашем аккаунте.</div>}
            {user &&
                <div className={"account-info"}>
                    <div className={"account-block"} style={{maxWidth: "390px"}}>
                        <div style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            justifyContent: "space-between",
                            alignItems: "start",
                            height: "auto"
                        }}>
                            <div className={"title"}>Ваш профиль</div>
                            <FiEdit className={"edit-profile-icon"} size={25} style={{color: edit ? "darkred" : "grey"}}
                                    onClick={() => handleAction()}/>
                        </div>

                        <div className={"info-block"}>
                            <div className={"info"}>
                                <div className={"label"}>Имя:</div>
                                <input disabled={!edit} value={username} style={{
                                    backgroundColor: edit ? "white" : "#f1f1f1",
                                    borderColor: edit ? "#eaeaea" : "#f1f1f1"
                                }}
                                       onChange={(event) => setUsername(event.currentTarget.value)}/>
                            </div>
                            <div className={"info"}>
                                <div className={"label"}>Email:</div>
                                <input value={email} readOnly={true}/>
                            </div>
                            <div className={"info"}>
                                <div className={"label"}>Phone:</div>
                                <InputMask mask="+7 (999)-999-99-99" value={phone} disabled={!edit} style={{
                                    pointerEvents: edit ? "all" : "none",
                                    backgroundColor: edit ? "white" : "#f1f1f1",
                                    borderColor: edit ? "#eaeaea" : "#f1f1f1"
                                }}
                                           onChange={(event) => setPhone(event.currentTarget.value)}>
                                </InputMask>

                            </div>
                            <div className={"info"}>
                                <div className={"label"}>Password:</div>
                                <input disabled={!edit} value={edit ? password : "********"} style={{
                                    backgroundColor: edit ? "white" : "#f1f1f1",
                                    borderColor: edit ? "#eaeaea" : "#f1f1f1"
                                }}
                                       onChange={(event) => setPassword(event.currentTarget.value)}/>
                            </div>
                        </div>
                        {edit && <div className={"btn-block"}>
                            <button onClick={() => updateUserData()}>сохранить</button>
                            <button onClick={() => setDefaultFieldValues()}>отмена</button>
                        </div>}
                        {!windowSize.isScreenL && <div className={"btn"} onClick={() => logout()} style={{
                            width: "50%",
                            fontSize: "10px",
                            backgroundColor: "darkred",
                            marginBottom: "15px",
                            boxSizing: "border-box",
                            textAlign: "center"
                        }}>Выйти из аккаунта</div>}
                    </div>
                    <div className={"account-block"} style={{maxWidth: "800px"}}>
                        <div className={"title"}>Бонусная программа</div>
                        <div className={"info"} style={{paddingBottom: "10px"}}>Бонусных баллов
                            - {user.user.bonusPoints}</div>
                        <div className={"info"}>Начисление бонусов - 3% от заказа</div>
                        <div className={"common-btn"} style={{marginLeft: "15px", marginTop: "20px"}}
                             onClick={async () => {
                            let credentials = user.credentials;
                            await GetAndRefreshUser(user.user.id, user.credentials).then((data) => {
                                setUser({
                                    credentials: credentials, user: data.data
                                })
                                showMessage("Данные успешно обновлены");
                            })
                                .catch((err) => AxiosError(err, showMessage));
                        }}>
                            Обновить баланс
                        </div>
                        <div className={"description"}>Как получать бонусные баллы. Бонусные баллы начисляются в
                            указанном
                            процентном соотношении от ваших покупок. Начисление баллов происходит только после
                            завершения
                            заказа (оплаты и получения). При следующих заказах Вы можете оплатить баллами до 20% от
                            стоимости заказа, по курсу 1 бонусный балл = 1 рубль. Подробности на странице <NavLink
                                to={"/loyalty-info"} style={{fontSize: "12px"}}>бонусная программа</NavLink>
                        </div>
                    </div>

                    <div className={"account-block"} style={{
                        maxWidth: "1200px",
                        width: "100%",
                        justifyContent: "center",
                        display: "flex",
                        height: "auto",
                        paddingBottom: "15px",
                        flexWrap: "wrap"
                    }}>
                        <div className={"title"} style={{
                            fontSize: "18px",
                            width: "100%",
                            height: "auto",
                            textAlign: "center",
                            fontWeight: "400"
                        }}>Понравился заказ? Напишите Ваши впечталения и предложения о работе нашего магазина и
                            сотрудников. Ваше мнение очень важно для нас!
                        </div>
                        <div className={"common-btn"} onClick={() => openModal(null, "createReview")}>Написать отзыв
                        </div>
                    </div>

                    <div className={"account-block"} style={{maxWidth: "1200px", width: "100%"}}>
                        <div className={"title"}>Заказы</div>
                        <UserOrders user={user} orders={orders}/>
                    </div>
                </div>
            }
        </div>)
    }
;

export default PersonalInfo;