import React from 'react';
import ModalCartItem from "./ModalCartItem";
import {convertToMoneyFormat} from "../../helpers/Utils";
import {NavLink} from "react-router-dom";

const ModalCart = ({products, type, deleteItem}) => {

    function getTotalSum() {
        let sum = 0;
        products.forEach((it) => {
            sum = sum + it.totalSum;
        })

        return sum;
    }


    return (
        <div className={"modal__cart__window"}>
            <div className={"title"}>
                {type === 'cart' ? "Ваша корзина" : "Ваши избранные товары"}
            </div>
            <div className={"modal__cart_products_container"}>
                {products.map((it, index) => {
                    return <ModalCartItem deleteItem={deleteItem} item={it} key={index} type={type}/>
                })
                }
            </div>

            {type === 'cart' && <div className={"total-sum"}>
                Итого: <div style={{
                fontFamily: "Barlow, sans-serif",
                fontWeight: "500"
            }}>{convertToMoneyFormat(getTotalSum())} Р</div>
            </div>}

            {type === 'cart' && <NavLink to={'/order'} className={"common-btn"}>
                Перейти в корзину
            </NavLink>}

        </div>
    );
};

export default ModalCart;