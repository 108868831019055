import Select from "react-select";
import {useField} from "formik";
import React from "react";
import "./customSelect.css"

export default function SelectField(props) {
    const [field, state, { setValue, setTouched }] = useField(props.field.name);

    // value is an array now
    const onChange = (value) => {
        setValue(value);
    };

    const colourStyles = {
        option: (styles, {data, isDisabled, isFocused, isSelected}) => {
            return {
                ...styles,
                fontSize: "12px"
            };
        },
    };

    // use value to make this a  controlled component
    // now when the form receives a value for 'campfeatures' it will populate as expected
    return (
        <Select
            isSearchable={false}
            {...props}
            value={state?.value}
            onChange={onChange}
            onBlur={setTouched}
            styles={colourStyles}
        />
    );
}
