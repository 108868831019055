import React, {useEffect} from 'react';

const MessageModalWindow = ({active, setActive, setMessage, message}) => {

    function closeModal() {
        setTimeout(() => { setActive(false); }, 3000);
    }


    useEffect(() => {
        if (active) {
            closeModal();
        }
    }, [active])


    return (
        <div className={active ? "modal-message active" : "modal-message"} onClick={() => setActive(false)}>
            <div className={"modal-message-content"} onClick={e => e.stopPropagation()}>
                <div className={"message"}>{message}</div>
            </div>
        </div>
    );
};

export default MessageModalWindow;