import React, {useEffect} from 'react';
import "./style.css";
import Login from "./Login";
import LinksToSocialNetworks from "./LinksToSocialNetworks";
import CreateReview from "./CreateReview";
import UserModal from "./UserModal";


const ModalWindow = ({active, setActive, id, type, user, setUser, showMessage, login, closeModal}) => {

    useEffect(() => {
        let html = document.querySelector('html');

        if (active) {
            html.style.overflowY = 'hidden';
        } else {
            html.style.overflowY = 'scroll';
        }
    }, [active])


    return (
        <div className={active ? "modal active" : "modal"} onClick={() => setActive(false)}>
            {type === "login" && <Login
                user={user}
                login={login}
                setUser={setUser}
                setActive={setActive}
                showMessage={showMessage}

            />}
            {type === "createBouquet" && <LinksToSocialNetworks/>}
            {type === "createReview" &&
                <CreateReview user={user} id={id} showMessage={showMessage} active={active} setActive={setActive}/>}
            {type === "user" && <UserModal id={id} closeModal={closeModal} user={user} showMessage={showMessage} active={active} setActive={setActive}/>}
        </div>
    );
};

export default ModalWindow;