import React, {useEffect, useState} from 'react';
import CartItem from "./CartItem";
import "./style.css"
import {NavLink} from "react-router-dom";
import GetNameOfCount from "../../components/GetNameOfCount";
import {convertToMoneyFormat} from "../../helpers/Utils";

const Cart = ({user, products, setProducts, openModal}) => {

    const [totalSum, setTotalSum] = useState(0);
    const [promocodeInput, setPromocodeInput] = useState(false);

    function calculateTotalSum() {
        let totalSum = 0;

        products.forEach((el) => {
            totalSum += el.totalSum;
        })

        return totalSum;
    }


    useEffect(() => {
        if (products) {
            setTotalSum(calculateTotalSum())
        }
    }, [products])


    return (
        <div className={"cart"}>
            <div className={"title"} style={{marginBottom: "10px"}}>Корзина</div>

            <div className="cart-container">
                {products && (products.map((product, index) => {
                    return <CartItem key={index} product={product} products={products} setProducts={setProducts} totalSum={totalSum} setTotalSum={setTotalSum}/>;
                }))}
                {(products.length < 1) && <h3 style={{fontSize: "16px"}}>В корзине пока нет товаров...</h3>}
            </div>
            <div className="cart-menu">
                <div className="order-info">
                    <div className="promo" style={{marginBottom: "20px"}}>
                        <div className="promo-title" onClick={() => setPromocodeInput(!promocodeInput)}>{promocodeInput ? "Не использовать промокод" : "Использовать промокод"}</div>
                        {promocodeInput && (
                            <input className="input-promo" type={"text"}/>
                        )}
                    </div>
                    <div className={"title"}>В корзине</div>
                    <div className={"countItems"}>{products.length} {GetNameOfCount(products.length)}</div>
                    <div className={"total-sum"}>Итого: {convertToMoneyFormat(totalSum)} ₽</div>
                </div>

                <div className={"order-info"}>
                    <NavLink to={"/order/checkout"} style={(user === undefined || products.length < 1) ? {pointerEvents: "none", opacity: "30%"} : {}}>
                        <div className={"btn-purchase"} >Перейти к оформлению</div>
                    </NavLink>
                    <div className={"reset-cart"} onClick={() => setProducts([])}>Очистить корзину</div>
                    {(user === undefined) && <div className={"register-info"}>Уважаемый клиент, чтобы перейти к оформлению заказа, пожалуйста <div style={{cursor: "pointer", color: "#0bafde", width: "min-content", display: "inline-block"}} onClick={() => openModal(null, "login")}>войдите</div> в свой аккаунт или <NavLink style={{fontSize: "14px", color: "#0bafde"}}  to={"/register"}>зарегистрируйте</NavLink> новый.</div>}
                    <div className={"order-add-info"}><span>
                    Обращаем Ваше внимание, что окончательная стоимость заказа, после применения скидок и бонусных баллов, а также доставки, будет рассчитана на стадии оформления заказа. Также сообщаем Вам, что участвовать в бонусной программе могут только зарегистрированные пользователи.
                </span></div>
                </div>

            </div>
        </div>
    );
};

export default Cart;