import React from 'react';
import "./search.css"
import {MdSearch} from "react-icons/md";
import {useResize} from "../../helpers/windowSizes/use-resize";

const SearchPanel = ({setSearchName, searchName}) => {

    const windowsSize = useResize();

    return (
        <div className={"search-panel"}>
            <MdSearch size={windowsSize.isScreenL ? 26 : 18} style={{color: "#838383"}}/>
            <input type={"text"} placeholder={"Введите название"} value={searchName} className={"search-input"}
                   onChange={(event) => setSearchName(event.currentTarget.value)}/>
        </div>
    );
};

export default SearchPanel;