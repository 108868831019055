import React, {useEffect, useState} from 'react';
import {PiUploadSimpleBold} from "react-icons/pi";
import {FaTrashAlt} from "react-icons/fa";
import apiData from "../../helpers/ApiData";

const PreviewImages = ({images}) => {

    const [prev1, setPrev1] = useState();
    const [prev2, setPrev2] = useState();
    const [prev3, setPrev3] = useState();
    const [prev4, setPrev4] = useState();

    useEffect(() => {
        setPrev1(images[0] && images[0].id ? apiData.getAttachment(images[0].downloadLink) : undefined);
        setPrev2(images[1] && images[1].id ? apiData.getAttachment(images[1].downloadLink) : undefined);
        setPrev3(images[2] && images[2].id ? apiData.getAttachment(images[2].downloadLink) : undefined);
        setPrev4(images[3] && images[3].id ? apiData.getAttachment(images[3].downloadLink) : undefined);
    }, [images]);

    function addImage(index, img) {
        let reader = new FileReader();
        reader.onload = function (e) {
            let originalImg = new Image();
            originalImg.src = e.target.result;
            CompressImg(originalImg.src, index, img.name);
        }
        reader.readAsDataURL(img);
    }

    function CompressImg(base64, index, fileName) {
        const canvas = document.createElement('canvas');
        const img = document.createElement('img');

        img.onload = function () {
            let width = img.width;
            let height = img.height;
            let maxWidth = 900;

            if (width > maxWidth) {
                height = Math.round((height *= maxWidth / width));
                width = maxWidth;
            }


            canvas.width = width;
            canvas.height = height;

            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, width, height);
            const compressedData = canvas.toDataURL('image/jpeg', 1.0);

            canvas.toBlob((blob) => {
                const formData = new FormData();
                formData.append('img', blob, fileName);
                images[index - 1] = formData.values().next().value;
            }, "image/jpeg", 1);

            switch (index) {
                case 1:
                    setPrev1(compressedData);
                    break;
                case 2:
                    setPrev2(compressedData);
                    break;
                case 3:
                    setPrev3(compressedData);
                    break;
                case 4:
                    setPrev4(compressedData);
                    break;
            }


        }

        img.onerror = function (err) {
        }

        img.src = base64;

    }

    function resetImage(index) {
        images[index - 1] = undefined;
        switch (index) {
            case 1:
                setPrev1(undefined);
                break;
            case 2:
                setPrev2(undefined);
                break;
            case 3:
                setPrev3(undefined);
                break;
            case 4:
                setPrev4(undefined);
                break;
        }
    }


    return (
        <div className={"preview-container"}>
            {prev1 ?
                <div className={"preview-img"}>
                    <img src={prev1}/>
                    <div className={"delete-icon"} onClick={() => resetImage(1)}>
                        <FaTrashAlt size={18}/>
                    </div>
                </div>
                :
                <div className="input__wrapper">
                    <input name="file" type="file" id="input__file" className="input input__file"
                           onChange={(event) => addImage(1, event.currentTarget.files[0])}/>
                    <label htmlFor="input__file" className="input__file-button">
                        <span className="input__file-icon-wrapper"><PiUploadSimpleBold size={25}/></span>
                        <span className="input__file-button-text">Добавить фото</span>
                    </label>
                </div>
            }

            {prev2 ?
                <div className={"preview-img"}>
                    <img src={prev2}/>
                    <div className={"delete-icon"} onClick={() => resetImage(2)}>
                        <FaTrashAlt size={18}/>
                    </div>
                </div>
                :
                <div className="input__wrapper">
                    <input name="file" type="file" id="input__file" className="input input__file"
                           onChange={(event) => addImage(2, event.currentTarget.files[0])}/>
                    <label htmlFor="input__file" className="input__file-button">
                        <span className="input__file-icon-wrapper"><PiUploadSimpleBold size={25}/></span>
                        <span className="input__file-button-text">Добавить фото</span>
                    </label>
                </div>
            }

            {prev3 ?
                <div className={"preview-img"}>
                    <img src={prev3}/>
                    <div className={"delete-icon"} onClick={() => resetImage(3)}>
                        <FaTrashAlt size={18}/>
                    </div>
                </div>
                :
                <div className="input__wrapper">
                    <input name="file" type="file" id="input__file" className="input input__file"
                           onChange={(event) => addImage(3, event.currentTarget.files[0])}/>
                    <label htmlFor="input__file" className="input__file-button">
                        <span className="input__file-icon-wrapper"><PiUploadSimpleBold size={25}/></span>
                        <span className="input__file-button-text">Добавить фото</span>
                    </label>
                </div>
            }

            {prev4 ?
                <div className={"preview-img"}>
                    <img src={prev4}/>
                    <div className={"delete-icon"} onClick={() => resetImage(4)}>
                        <FaTrashAlt size={18}/>
                    </div>
                </div>
                :
                <div className="input__wrapper">
                    <input name="file" type="file" id="input__file" className="input input__file"
                           onChange={(event) => addImage(4, event.currentTarget.files[0])}/>
                    <label htmlFor="input__file" className="input__file-button">
                        <span className="input__file-icon-wrapper"><PiUploadSimpleBold size={25}/></span>
                        <span className="input__file-button-text">Добавить фото</span>
                    </label>
                </div>
            }
        </div>
    );
};

export default PreviewImages;