import React, {useEffect, useState} from 'react';
import {getProductById} from "../../../components/axios/ProductRequests";
import AxiosError from "../../../components/axios/AxiosError";
import {NavLink} from "react-router-dom";
import apiData from "../../../helpers/ApiData";

const OrderPageProductImage = ({orderProduct, showMessage}) => {

    const [product, setProduct] = useState();

    useEffect(() => {
        getProductById(orderProduct.productId).then((resp) => setProduct(resp.data)).catch((err) => AxiosError(err, showMessage));
    }, []);

    return (
        <div style={{display: "flex", flexWrap: "nowrap", width: "100%", marginLeft: "20px", marginBottom: "15px"}}>
            {product && <img style={{width: "100px", height: "auto", objectFit: "contain"}}
                             src={apiData.getAttachment(product.attachments[0].downloadLink)} alt={"product img"}/>}
            <NavLink to={`/product/${orderProduct.productId}`} className={"order-products-item"}>
                <div>{orderProduct.productName}</div>
                <div>{orderProduct.quantity} x {orderProduct.price} ₽</div>
            </NavLink>
        </div>
    );
};

export default OrderPageProductImage;