import React, {useEffect, useState} from 'react';
import '../account-style.css';
import OrderItem from "./OrderItem";
import AxiosError from "../../../components/axios/AxiosError";
import {getAdminOrders} from "../../../components/axios/OrderRequests";

const AdminOrders = ({user, showMessage}) => {
    const [orders, setOrders] = useState([]);
    const [activeOrder, setActiveOrder] = useState([]);
    const [filter, setFilter] = useState("ALL");

    useEffect(() => {
        loadOrders(filter);
    }, [filter]);

    async function loadOrders(status) {
        getAdminOrders(status, user.credentials).then((response) => setOrders(response.data))
            .catch((err) => AxiosError(err, showMessage));
    }


    return (

        <div className={"common-page"}>
            <div style={{width: "100%", display: "flex", justifyContent: "center", marginBottom: "20px"}}>
                <div className={"btn-switch"}>
                    <div className={filter === 'ALL' ? "btn-switch-item active" : "btn-switch-item"}
                         onClick={() => setFilter("ALL")}>Все
                    </div>
                    <div className={filter === 'CREATED' ? "btn-switch-item active" : "btn-switch-item"}
                         onClick={() => setFilter("CREATED")}>Новые
                    </div>
                    <div className={filter === 'ACCEPTED' ? "btn-switch-item active" : "btn-switch-item"}
                         onClick={() => setFilter("ACCEPTED")}>Принятые
                    </div>
                    <div className={filter === 'DELIVERED' ? "btn-switch-item active" : "btn-switch-item"}
                         onClick={() => setFilter("DELIVERED")}>У курьера
                    </div>
                    <div className={filter === 'READY' ? "btn-switch-item active" : "btn-switch-item"}
                         onClick={() => setFilter("READY")}>Собраны
                    </div>
                    <div className={filter === 'CANCELED' ? "btn-switch-item active" : "btn-switch-item"}
                         onClick={() => setFilter("CANCELED")}>Отмененные
                    </div>
                    <div className={filter === 'FINISHED' ? "btn-switch-item active" : "btn-switch-item"}
                         onClick={() => setFilter("FINISHED")}>Завершенные
                    </div>
                </div>
            </div>


            {orders.length > 0 ? <div className={"orders"}>
                {orders.map((order, index) => <OrderItem key={index} order={order} user={user} activeOrder={activeOrder}
                                                         setActiveOrder={setActiveOrder}/>)}
            </div> : "Заказы не найдены..."}
        </div>

    )
};

export default AdminOrders;