const priceList = [
    {
        index: 0,
        size: 'XS',
        currentPrice: 0,
        oldPrice: 0
    },
    {
        index: 1,
        size: 'S',
        currentPrice: 0,
        oldPrice: 0
    },
    {
        index: 2,
        size: 'M',
        currentPrice: 0,
        oldPrice: 0
    },
    {
        index: 3,
        size: 'L',
        currentPrice: 0,
        oldPrice: 0
    },
    {
        index: 4,
        size: 'XL',
        currentPrice: 0,
        oldPrice: 0
    },
    {
        index: 5,
        size: 'XXL',
        currentPrice: 0,
        oldPrice: 0
    },
    {
        index: 6,
        size: 'XXXL',
        currentPrice: 0,
        oldPrice: 0
    }
];

export {priceList}