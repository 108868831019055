import axios from "axios";
import apiData from "../../helpers/ApiData";

export async function saveTab(tab, credentials) {
    return axios({
        method: "POST",
        url: apiData.saveTab,
        headers: {"Content-Type": "application/json", "Authorization": credentials},
        // в data передаем объект с данными формы
        data: tab,
    });
}

export async function updateTab(tab, id, credentials) {
    return axios({
        method: "PUT",
        url: apiData.updateTab(id),
        headers: {"Content-Type": "application/json", "Authorization": credentials},
        // в data передаем объект с данными формы
        data: tab,
    });
}

export async function deleteTab(id, credentials) {
    return axios({
        method: "DELETE",
        url: apiData.deleteTab(id),
        headers: {"Authorization": credentials},
    });
}

export async function getAllTabs() {
    return axios({
        method: "GET",
        url: apiData.getTabs
    });
}