import React from 'react';
import "./reviews.css"
import apiData from "../../helpers/ApiData";
import {IoIosStar, IoIosStarOutline} from "react-icons/io";
import {FiEdit} from "react-icons/fi";

const ReviewItem = ({review, isEdit, openModal, closeModal}) => {

    const iconSize = 20;


    return (
        <div className={"review-container"}>
            <div className={"title"}>
                <div>{review.username}</div>
                <div>{review.created}</div>
                {isEdit && <div style={{display: "flex", flexWrap: "nowrap", gap: '10px', alignItems: "center"}}>
                    <div style={{
                        width: "auto",
                        backgroundColor: review.isChecked ? "#79ab8c" : "#810f0f",
                        color: "white",
                        height: "min-content",
                        padding: "2px 8px 3px",
                        borderRadius: "2px",
                        fontSize: "12px",
                        fontWeight: "500"
                    }}>{review.isChecked ? "виден" : "скрыт"}</div>
                    <div style={{cursor: "pointer"}} onClick={() => openModal(review.id, "createReview")}><FiEdit
                        size={20}/></div>
                </div>}
            </div>

            <div className={"rating"}>
                <div className={"rating-field"} style={{cursor: "auto"}}>
                    <div className={"star"}>{review.rating > 0 ?
                        <IoIosStar style={{color: "orange"}} size={iconSize}/> :
                        <IoIosStarOutline style={{color: "lightgray"}} size={iconSize}/>}</div>
                    <div className={"star"}>{review.rating > 1 ?
                        <IoIosStar style={{color: "orange"}} size={iconSize}/> :
                        <IoIosStarOutline style={{color: "lightgray"}} size={iconSize}/>}</div>
                    <div className={"star"}>{review.rating > 2 ?
                        <IoIosStar style={{color: "orange"}} size={iconSize}/> :
                        <IoIosStarOutline style={{color: "lightgray"}} size={iconSize}/>}</div>
                    <div className={"star"}>{review.rating > 3 ?
                        <IoIosStar style={{color: "orange"}} size={iconSize}/> :
                        <IoIosStarOutline style={{color: "lightgray"}} size={iconSize}/>}</div>
                    <div className={"star"}>{review.rating > 4 ?
                        <IoIosStar style={{color: "orange"}} size={iconSize}/> :
                        <IoIosStarOutline style={{color: "lightgray"}} size={iconSize}/>}</div>
                </div>

                <div style={{
                    fontSize: "13px",
                    fontWeight: "600",
                    color: "grey",
                    textAlign: "center",
                    padding: "0 0 2px 0"
                }}>{review.rating} / 5
                </div>
            </div>

            <div className={"text"}>
                {review.text}
            </div>
            <div className={"images"}>
                {review.attachments && review.attachments.map((attach, index) => {
                    return <img key={index} src={apiData.getAttachment(attach.downloadLink)} alt={"review img"}/>
                })}
            </div>
        </div>
    );
};

export default ReviewItem;