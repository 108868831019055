import React from 'react';
import "./style.css";

const FilterItem = ({filter, setFilter, activeFilter}) => {

    const isActive = activeFilter === filter;

    const normalLink = "filter-item";
    const activeLink = "filter-item-active";

    const actualClass = isActive ? activeLink : normalLink;

    return (
        <div className={isActive ? "filter-item active" : "filter-item"} onClick={() => setFilter(filter)}>
            {filter.title}
        </div>
    );
};

export default FilterItem;