import React from 'react';
import PersonalInfo from "./PersonalInfo";
import AdminPanel from "./admin/AdminPanel";

const PersonalAccount = ({user, setUser, showMessage, login, logout, openModal}) => {

    return (
        <div className={"page-block"}>
            {user ? <div className={"data-block"} style={{justifyContent: "space-between"}}>
                {user.user.role === 'ADMIN' && <AdminPanel/>}
                <PersonalInfo user={user} showMessage={showMessage} login={login} setUser={setUser} logout={logout}
                              openModal={openModal}/>
            </div> : <div className={"empty-msg"}>Войдите, чтобы увидеть информацию...</div>}
        </div>

    );
};

export default PersonalAccount;