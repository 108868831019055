import React from 'react';

const EducationInfo = () => {
    return (
        <div className={"education-info"}>
            <div className={"info-msg"}>Данная страница в разработке, информация скоро появится...</div>
        </div>
    );
};

export default EducationInfo;