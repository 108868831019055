import React from 'react';
import OrderItem from "./OrderItem";

const UserOrders = ({user, orders}) => {


    return (
        <div style={{width: "auto", boxSizing: "border-box", padding: "0", position: "relative"}}>
            {(orders.length > 0) && (orders.map((el, index) => {
                return <OrderItem order={el} key={index} user={user}/>
            }))

            }
        </div>
    );
};

export default UserOrders;