import axios from "axios";
import apiEndpoints from "../../helpers/ApiData";

export default async function LoginUser(credentials) {

   return axios({
        method: "GET",
        url: apiEndpoints.login,
        headers: {"Authorization": credentials}
    });
}