import React from 'react';
import NewItem from "./NewItem";


const Items = ({user, items, openModal, addItemToCart, isItemAddedToCart}) => {
    function checkHidden(item) {
        return (user && user.user.role === 'ADMIN') || !item.isHidden;
    }

    return (
        <div className={"catalog-items"}>

            {items.length > 0 && (items.filter((el) => checkHidden(el)).map((el, index) => {
                return <NewItem key={index} addItemToCart={addItemToCart} isItemAddedToCart={isItemAddedToCart}
                                product={el} openModal={openModal} user={user}/>
            }))}
        </div>);
}

export default Items;