import React from 'react';

const MobileHeader = () => {
    return (
        <div className={"mobile-header"}>
            <div className={"mobile-logo"}>
                <img src={"../../img/logo/1.png"}/>
            </div>
        </div>
    );
};

export default MobileHeader;