import React, {useEffect, useState} from 'react';
import {getAdminAllProducts} from "../../../components/axios/ProductRequests";
import AxiosError from "../../../components/axios/AxiosError";
import AdminProduct from "./AdminProduct";

const AdminProductsPage = ({user, showMessage}) => {

    const [products, setProducts] = useState([]);
    const [isHidden, setIsHidden] = useState(false);
    const [isAvailable, setIsAvailable] = useState(true);
    const [category, setCategory] = useState("FLOWER_AND_GIFTS");

    useEffect(() => {
        getProducts().catch((err) => AxiosError(err, showMessage));
    }, [isHidden, isAvailable, category]);


    async function getProducts() {
        await getAdminAllProducts(user.credentials, isHidden, isAvailable, category, "id::desc").then((resp) => setProducts(resp.data));
    }

    return (
        <div className={"common-page"}>
            <div style={{width: "100%", display: "flex", justifyContent: "center", marginBottom: "20px"}}>
                <div className={"btn-switch"}>
                    <div className={isHidden ? "btn-switch-item" : "btn-switch-item active"}
                         onClick={() => setIsHidden(false)}>Активные
                    </div>
                    <div className={isHidden ? "btn-switch-item active" : "btn-switch-item"}
                         onClick={() => setIsHidden(true)}>Скрытые
                    </div>
                </div>

                <div className={"btn-switch"}>
                    <div className={isAvailable ? "btn-switch-item active" : "btn-switch-item"}
                         onClick={() => setIsAvailable(true)}>В наличии
                    </div>
                    <div className={isAvailable ? "btn-switch-item" : "btn-switch-item active"}
                         onClick={() => setIsAvailable(false)}>Под заказ
                    </div>
                </div>

                <div className={"btn-switch"}>
                    <div className={category === 'FLOWER_AND_GIFTS' ? "btn-switch-item active" : "btn-switch-item"}
                         onClick={() => setCategory('FLOWER_AND_GIFTS')}>Цветы и подарки
                    </div>
                    <div className={category === 'POTTED_PLANTS' ? "btn-switch-item active" : "btn-switch-item"}
                         onClick={() => setCategory('POTTED_PLANTS')}>Горшечные растения
                    </div>
                    <div className={category === 'DECOR' ? "btn-switch-item active" : "btn-switch-item"}
                         onClick={() => setCategory('DECOR')}>Декор
                    </div>
                    <div className={category === 'FLOWER_PASS' ? "btn-switch-item active" : "btn-switch-item"}
                         onClick={() => setCategory('FLOWER_PASS')}>Цветочная подписка
                    </div>
                </div>


            </div>

            <div className={"admin-products-container"}>
                {products.map((it, index) => {
                    return <AdminProduct product={it} key={index} showMessage={showMessage}/>
                })}
            </div>

        </div>
    );
};

export default AdminProductsPage;