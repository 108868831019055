import React, {useEffect, useState} from 'react';
import {IoIosStar, IoIosStarOutline} from "react-icons/io";
import {PiUploadSimpleBold} from "react-icons/pi";
import {createReview, getAdminReview, updateReview} from "../../components/axios/ReviewRequests";
import AxiosError from "../../components/axios/AxiosError";
import apiData from "../../helpers/ApiData";

const CreateReview = ({user, showMessage, id, active, setActive}) => {

    const [rating, setRating] = useState(0);

    const [review, setReview] = useState();

    const [images, setImages] = useState([4]);

    const [prev1, setPrev1] = useState();
    const [prev2, setPrev2] = useState();
    const [prev3, setPrev3] = useState();
    const [prev4, setPrev4] = useState();
    const [check, setCheck] = useState(false);


    const [textReview, setTextReview] = useState("");
    const [username, setUsername] = useState();
    const [status, setStatus] = useState();


    const iconSize = 24;

    useEffect(() => {
        setCheck(rating > 0 && textReview.length > 0);
    }, [rating, textReview]);

    useEffect(() => {
        if (id) {
            fetchData().catch((err) => AxiosError(err, showMessage));
        } else {
            setRating(0)
            setTextReview("");
            setReview(undefined)
            resetPreviews();
        }
    }, [active, id]);

    useEffect(() => {
        if (review) {
            setRating(review.rating);
            setTextReview(review.text);
            setStatus(review.isChecked);
            setUsername(review.username);
            if (review.attachments) {
                review.attachments.map((a, index) => {
                    switch (index) {
                        case 0:
                            setPrev1(apiData.getAttachment(a.downloadLink));
                            break;
                        case 1:
                            setPrev2(apiData.getAttachment(a.downloadLink));
                            break;
                        case 2:
                            setPrev3(apiData.getAttachment(a.downloadLink));
                            break;
                        case 3:
                            setPrev4(apiData.getAttachment(a.downloadLink));
                            break;
                    }
                })
            }
        }
    }, [review]);

    const fetchData = async () => {
        await getAdminReview(id, user.credentials).then((resp) => setReview(resp.data));
    }

    function closeWindow() {
        setRating(0)
        setTextReview("");
        resetPreviews();
        setActive(false);
    }

    function uploadReview() {
        let review = {
            userId: user.user.id,
            username: user.user.name,
            isChecked: false,
            rating: rating,
            text: textReview
        };

        review = JSON.stringify(review);

        let formData = new FormData();
        formData.append('review', new Blob([review], {type: "application/json"}))


        for (let i of images) {
            formData.append('images', i);
        }

        uploadReviewToServer(formData).catch((err) => AxiosError(err, showMessage));
    }


    function update() {
        let updatedReview = {
            id: id,
            username: username,
            text: textReview,
            rating: rating,
            isChecked: status
        };

        updatedReview = JSON.stringify(updatedReview);

        updateReviewOnServer(updatedReview).catch((err) => AxiosError(err, showMessage));
    }

    const uploadReviewToServer = async (formData) => {
        await createReview(formData, user).then((resp) => showMessage("Отзыв успешно отправлен"));
        setTimeout(closeWindow, 500);
    }

    const updateReviewOnServer = async (data) => {
        await updateReview(id, data, user).then((resp) => showMessage("Отзыв успешно обновлен"));
        setTimeout(closeWindow, 500);
    }

    function addImage(img, arr) {
        let reader = new FileReader();
        reader.onload = function (e) {
            let originalImg = new Image();
            originalImg.src = e.target.result;
            CompressImg(originalImg.src, img.name, arr);
        }
        reader.readAsDataURL(img);
    }

    function CompressImg(base64, fileName, index) {
        const canvas = document.createElement('canvas');
        const img = document.createElement('img');

        img.onload = function () {
            let width = img.width;
            let height = img.height;
            let maxWidth = 500;

            if (width > maxWidth) {
                height = Math.round((height *= maxWidth / width));
                width = maxWidth;
            }


            canvas.width = width;
            canvas.height = height;

            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, width, height);
            const compressedData = canvas.toDataURL('image/jpeg', 1.0);

            canvas.toBlob((blob) => {
                const formData = new FormData();
                formData.append('img', blob, fileName);
                images[index] = formData.values().next().value;
            }, "image/jpeg", 1);

            setImages(images);

            switch (index) {
                case 0:
                    setPrev1(compressedData);
                    break;
                case 1:
                    setPrev2(compressedData);
                    break;
                case 2:
                    setPrev3(compressedData);
                    break;
                case 3:
                    setPrev4(compressedData);
                    break;
            }
        }

        img.onerror = function (err) {
        }

        img.src = base64;

    }

    function trySubmitFile(e) {
        const files = e.target.files;
        if (files.length > 4) {
            alert('You are only allowed to upload a maximum of 4 files at a time');
            return;
        }
        if (!files.length) return;

        resetPreviews();
        setImages([4])

        for (let i = 0; i < files.length; i++) {
            addImage(files[i], i)
        }
    }

    function resetPreviews() {
        setPrev1(undefined);
        setPrev2(undefined);
        setPrev3(undefined);
        setPrev4(undefined);
    }

    return (
        <div className={"modal__content"} style={{
            padding: "10px",
            minWidth: "300px",
            maxWidth: "512px",
            height: "auto",
            flexWrap: "wrap",
            overflow: "hidden",
            display: "flex",
            borderRadius: "5px",
            maxHeight: "auto",
            overflowY: "scroll"
        }} onClick={e => e.stopPropagation()}>
            <div className={"modal-control"}>
                <div className={"close-btn"} onClick={() => closeWindow()}>x</div>
            </div>
            {review && <>
                <div className={"modal-item"}>
                    <label>Имя отправителя</label>
                    <input className="form-control" style={{backgroundColor: "white"}} type={"text"} value={username}
                           onChange={(event) => setUsername(event.currentTarget.value)}/>
                </div>
                <div className={"modal-item"}>
                    <label>Статус</label>
                    <div className={"btn-switch"} style={{borderRadius: "2px"}}>
                        <div className={status ? "btn-switch-item active" : "btn-switch-item"}
                             onClick={() => setStatus(true)}>Виден
                        </div>
                        <div className={status ? "btn-switch-item" : "btn-switch-item active"}
                             onClick={() => setStatus(false)}>Скрыт
                        </div>
                    </div>
                </div>
            </>}
            <div className={"modal-item"}>
                <label>Общая оценка (от 1 до 5) *</label>
                <div className={"rating-field"}>
                    <div className={"star"} onClick={() => setRating(1)}>{rating > 0 ?
                        <IoIosStar style={{color: "orange"}} size={iconSize}/> :
                        <IoIosStarOutline style={{color: "gray"}} size={iconSize}/>}</div>
                    <div className={"star"} onClick={() => setRating(2)}>{rating > 1 ?
                        <IoIosStar style={{color: "orange"}} size={iconSize}/> :
                        <IoIosStarOutline style={{color: "gray"}} size={iconSize}/>}</div>
                    <div className={"star"} onClick={() => setRating(3)}>{rating > 2 ?
                        <IoIosStar style={{color: "orange"}} size={iconSize}/> :
                        <IoIosStarOutline style={{color: "gray"}} size={iconSize}/>}</div>
                    <div className={"star"} onClick={() => setRating(4)}>{rating > 3 ?
                        <IoIosStar style={{color: "orange"}} size={iconSize}/> :
                        <IoIosStarOutline style={{color: "gray"}} size={iconSize}/>}</div>
                    <div className={"star"} onClick={() => setRating(5)}>{rating > 4 ?
                        <IoIosStar style={{color: "orange"}} size={iconSize}/> :
                        <IoIosStarOutline style={{color: "gray"}} size={iconSize}/>}</div>
                </div>
            </div>

            <div className={"modal-item"}>
                <label>Напишите отзыв (макс 2048 символов) *</label>
                <textarea style={{padding: "5px"}} maxLength={2048} value={textReview}
                          onChange={(e) => setTextReview(e.target.value)}/>
            </div>

            <div className={"modal-item"}>
                <label>Добавьте фотографии (макс 4 шт.)</label>
                {review === undefined && <div className={"input-container"}>
                    <div className="input__wrapper">
                        <input name="file" type="file" id="input__file" className="input input__file" multiple={true}
                               onChange={trySubmitFile}/>
                        <label htmlFor="input__file" className="input__file-button">
                            <span className="input__file-icon-wrapper"><PiUploadSimpleBold size={14}/></span>
                            <span className="input__file-button-text" style={{fontSize: '12px', color: "white"}}>Выбрать фото</span>
                        </label>
                    </div>
                </div>}

                <div className={"preview-container"}>
                    {prev1 && <div className={"preview-img"}>
                        <img src={prev1}/>
                    </div>}
                    {prev2 && <div className={"preview-img"}>
                        <img src={prev2}/>
                    </div>}
                    {prev3 && <div className={"preview-img"}>
                        <img src={prev3}/>
                    </div>}
                    {prev4 && <div className={"preview-img"}>
                        <img src={prev4}/>
                    </div>}
                </div>
            </div>

            {!check && <div className={"modal-item"} style={{backgroundColor: "white", margin: "0"}}>
                <div className={"info-msg"} style={{color: "rgba(255,3,3,0.77)", fontSize: "12px", fontWeight: "500"}}>
                    Не заполнены все обязательные поля (помеченные *)
                </div>
            </div>}

            <div className={"modal-item"} style={{backgroundColor: "white"}}>
                <div className={"btn"} style={{opacity: check ? "1.0" : "0.3", pointerEvents: check ? "all" : "none"}}
                     onClick={() => {
                         if (review) {
                             update();
                         } else {
                             uploadReview();
                         }
                     }}>
                    {review ? "Сохранить изменения" : "Отправить отзыв"}
                </div>
            </div>

            <div className={"modal-item"}>
                <div className={"info-msg"}>
                    Уважаемый пользователь, нажимая кнопку отправить отзыв, Вы соглашаетесь с условиями, что Ваш отзыв,
                    после модерации, может быть опубликован на странице отзывы данного сайта, с указанием Вашего имени.
                </div>
            </div>
        </div>
    );
};

export default CreateReview;