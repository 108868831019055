
const catalogItems = [
    {
        title: 'ЦВЕТЫ И ПОДАРКИ',
        link: '/flowers',
        filters: []
    },
    {
        title: 'ГОРШЕЧНЫЕ РАСТЕНИЯ',
        link: '/plants',
        filters: []
    },
    {
        title: 'ДЕКОР',
        link: '/decor',
        filters: []
    },
    {
        title: 'ЦВЕТОЧНАЯ ПОДПИСКА',
        link: '/flower-pass',
        filters: []
    }
];

const aboutUsItems =
    {
        title: 'НАША ЦВЕТОЧНАЯ МАСТЕРСКАЯ',
        link: '/about-us',
        filters: [
            {
                link: '/about-us',
                value: 'Информация о нас',
            },
            {
                link: '/contacts',
                value: 'Наши контакты',
            },
            {
                link: '/for-corporate-clients',
                value: 'Корпоративным клиентам',
            },
            {
                link: '/warranty-info',
                value: 'Гарантия и возврат',
            },
            {
                link: '/loyalty-info',
                value: 'Бонусная программа',
            },
            {
                link: '/privacy-policy',
                value: 'Политика конфиденциональности',
            },
            {
                link: '/education-info',
                value: 'Мастер классы и обучение',
            },
            {
                link: '/reviews',
                value: 'Отзывы покупателей',
            },
            {
                link: '/payment-delivery',
                value: 'Доставка и оплата',
            }
        ]
    }
;

export {catalogItems, aboutUsItems}