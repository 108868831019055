const mainURI = 'https://api-cvetovodoff.ru';
//const mainURI = 'http://localhost:8555'

const apiEndpoints = {
    apiAddress: mainURI,

    getTabs: mainURI + "/get-tabs",
    saveTab: mainURI + "/admin/tabs",
    updateTab: function (id) {
        return mainURI + `/admin/tabs/${id}`
    },
    deleteTab: function (id) {
        return mainURI + `/admin/tabs/${id}`
    },

    getSiteData: mainURI + "/get-site-data",
    saveSiteData: mainURI + "/admin/site-data",
    updateSiteData: function (id) {
        return mainURI + `/admin/site-data/${id}`
    },
    deleteSiteData: function (id) {
        return mainURI + `/admin/site-data/${id}`
    },

    getAllReviews: mainURI + "/reviews",
    getAdminAllReviews: mainURI + "/admin/reviews",
    getAdminReview: function (reviewId) {
        return mainURI + `/admin/reviews/${reviewId}`
    },
    updateReview: function (reviewId) {
        return mainURI + `/admin/reviews/${reviewId}`
    },
    createReview: function (id) {
        return mainURI + `/users/${id}/review`
    },


    uploadBanner: function () {
        return mainURI + "/admin/banners";
    },


    productsPage: function (categories, sort) {
        return mainURI + `/products?categories=${categories}&sort_by=${sort}&limit=200`;
    },
    searchProducts: function (searchName, sort) {
        return mainURI + `/products/search?name=${searchName}&sort_by=${sort}&limit=200`;
    },
    createUser: mainURI + '/users',
    generateResetToken: function (email) {
       return  mainURI + `/users/reset-password/${email}`;
    },
    setNewPassword: function (token) {
        return mainURI + `/users/reset-password/${token}`;
    },
    addProduct: mainURI + '/admin/product',
    getProduct: function (id) {
        return mainURI + `/products/${id}`
    },
    getAdminAllProducts: function (isHidden, isAvailable, categories, sortBy) {
        return mainURI + `/admin/product?categories=${categories}&sort_by=${sortBy}&limit=200&isHidden=${isHidden}&isAvailable=${isAvailable}`;
    },

    updateProduct: function (id) {
        return mainURI + `/admin/product/${id}`
    },
    deleteProduct: function (id) {
        return mainURI + `/admin/product/${id}`
    },
    getUser: function (id) {
        return mainURI + `/users/${id}`;
    } ,
    getAdminUsers: mainURI + '/admin/users',
    getAdminUser: function (id) {
        return mainURI + `/admin/users/${id}`
    },
    updateAdminUser: function (id) {
        return mainURI + `/admin/users/${id}`
    },
    getAndRefreshUser: function (id) {
        return mainURI + `/users/${id}/refresh`;
    },
    updateUser: function (id) {
        return mainURI + `/users/${id}`;
    },

    editProduct: mainURI + '/admin/product/',

    getAttachment: function (downloadLink) {
      return mainURI + downloadLink;
    },
    login: mainURI + '/login',
    createOrder: function (userId) {
        return mainURI + `/users/${userId}/orders`
    },
    changeOrderStatus: function (orderId) {
        return mainURI + `/admin/orders/${orderId}/status`
    },
    getAllOrders: mainURI + '/admin/orders',
    getUserOrders: function (userId) {
        return mainURI + `/users/${userId}/orders`
    },
    getAdminOrderById: function (orderId) {
        return mainURI + `/admin/orders/${orderId}`
    },
    getUserOrderById: function (userId, orderId) {
        return mainURI + `/users/${userId}/orders/${orderId}`
    },
    getAdminOrders: function (status) {
        return mainURI + `/admin/orders?status=${status}`
    }
}

export default apiEndpoints;