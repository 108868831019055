import React, {useEffect, useState} from 'react';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import 'dayjs/locale/en-gb';

const CustomDateTimePicker = ({readyTime, setOrderTime, manualOrderTime, setManualOrderTime}) => {

    const [date, setDate] = useState(getStartDate());

    const [minDate, setMinDate] = useState(date);



    const [minTime, setMinTIme] = useState(dayjs().set('hour', 8).set('minute', 0));
    const [maxTime, setMaxTIme] = useState(dayjs().set('hour', 21).set('minute', 0));

    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        refreshMinValid();
        updateOrderTime();
    }, [date])


    useEffect(() => {
        setDate(getStartDate());
        setMinDate(getStartDate());
    }, [readyTime]);

    useEffect(() => {
        setOrderTime(manualOrderTime ? "" : date);
    }, [manualOrderTime]);
    function updateOrderTime() {
        const dateTime = date.format("YYYY-MM-DD").concat("T").concat(date.format("HH:mm:ss").concat("+04:00"));
        setOrderTime(dateTime);
    }
    function handleSetDate(date, error) {

        let inputDate = (dayjs) (date);

        if (!error.validationError) {
            setDate(inputDate);
        }
        else {
            setOrderTime(undefined);
        }
    }

    function getStartDate() {
        let date = dayjs().add(readyTime, 'hour');

        if (date.hour() < 8) {
            date = date.set('hour', 8).set('minute', 0);
        }
        else if (date.hour() >= 21) {
            date = date.add(1, 'day').set('hour', 8).set('minute', 0);
        }

        let minutes = date.minute();

        while (minutes % 15 !== 0) {
            minutes++;
        }

        date = date.set('minute', minutes);

        return date;
    }

    function refreshMinValid() {

        if (date.date() === dayjs().date() && dayjs().hour() > 8 && dayjs().hour() < 21) {
            setMinTIme(dayjs().set('hour', minDate.hour()).set('minute', minDate.minute()));
        }
        else {
            setMinTIme(dayjs().set('hour', 8).startOf('hour'));
        }
    }

    return (
        <div style={{
            display: "flex",
            marginTop: "10px",
            flexWrap: "wrap",
            columnGap: "40px",
            alignItems: "center",
            position: "relative"
        }}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
            <div className={"form-label"} style={{
                marginTop: "20px", fontSize: "18px", width: "100%", fontWeight: "400"
            }}>Выберете дату и время самовывоза / доставки *</div>
            <div className={manualOrderTime ? "date-time-container-inactive" : "date-time-container"}>
                <DateTimePicker minutesStep={15} skipDisabled={true} label="Датa и время доставки" className={"date"}
                                minDate={minDate} value={date} maxTime={maxTime} minTime={minTime}
                                onChange={(date, error) => handleSetDate(date, error)}/>
            </div>
        </LocalizationProvider>
            <div style={{alignItems: "center", height: "auto", gap: "10px", display: "flex"}}>
                <input type={"checkbox"} value={manualOrderTime} onChange={(event) => setManualOrderTime(event.currentTarget.checked)}/>
                <label style={{marginTop: "1px", fontWeight: "400", fontSize: "12px"}}>Указать время вручную</label>
            </div>
        </div>
    );
};

export default CustomDateTimePicker;