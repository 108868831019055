import React, {useState} from 'react';
import AddTab from "./AddTab";
import EditTab from "./EditTab";
import {ca} from "date-fns/locale";
import {uploadBanner} from "../../components/axios/SiteDataRequests";
import AxiosError from "../../components/axios/AxiosError";

const EditTabsPage = ({tabs, user, showMessage}) => {

    const [page, setPage] = useState();
    const instructions = "1. Страница - страница сайта для которой создается/редактируется вкладка, обязательно выбрать. \n" +
        "2. Заголовок - название вкладки на странице (например: Все, Букеты, 8 марта и так далее...) обязательно к заполнению. \n" +
        "3. Название баннера с расширением - название файла, который содержит изображение баннера, с указанием расширения (например: flowers.png, bouquets.png и так далеее....), баннеры должны быть расположены на сервере в папке, по пути /var/www/cvetovodoff.ru/img/banners. \n" +
        "4. Индекс вкладки - порядковый номер вкладки на странице, убедитесь, что создаете вкладку с уникальным индексом. 1 индекс находится в начале. \n" +
        "5. Выделенная вкладка - если выбрать вариант да, то данная вкладка появится в хедере, сверху, как отдельная кнопка. (макс 1 вкладка). Если выбираете да, то убедитесь, что в предыдущей вкладке вы сменили этот параметр на нет. \n" +
        "6. Категории - выбор необходимых категорий товаров, которые будут отображаться в данной вкладке. \n"

    const instructionsUploadBanners = "1. Баннеры необходимо загружать с правильным названием и расширением. \n" +
        "2. Все баннеры кроме основного на главной странице должны иметь расширение .png \n" +
        "3. Основной баннер на главной странице имеет расширение .gif \n" +
        "4. Размеры всех баннеров - 1200px ширина, высота основного баннера 300-350px, дополнительных - 200px \n" +
        "5. Стандартные названия баннеров: \n" +
        "----- main-banner.gif - главный баннер. \n" +
        "----- additional-banner.png - дополнительный баннер (под главным). \n" +
        "----- about-page.png - страница о нас. \n" +
        "----- bonus-page.png - страница бонусы. \n" +
        "----- bouquets.png - вкладка букеты. \n" +
        "----- care.png - вкладка средства для ухода. \n" +
        "----- decor.png - вкладка декор. \n" +
        "----- flower-boxes.png - вкладка цветочные боксы. \n" +
        "----- flower-pass.png - вкладка цветочная подписка. \n" +
        "----- flowers.png - вкладка цветы и букеты. \n" +
        "----- holidays.png - вкладка все для праздника. \n" +
        "----- indoor.png - вкладка комнатные растения. \n" +
        "----- newyear.png - вкладка новый год. \n" +
        "----- outdoor.png - вкладка уличные растения. \n" +
        "----- payment-delivery-page.png - страница оплата и доставка. \n" +
        "----- plants.png - вкладка горшечные растения. \n" +
        "----- roses.png - вкладка розомания. \n" +
        "----- spring.png - вкладка весна. \n" +
        "----- toys-balls.png - вкладка все для праздника (игрушки, шары). \n" +
        "----- valentin-day.png - вкладка день валентина. \n" +
        "----- warranty-page.png - страница гарантия и возврат. \n" +
        "----- wedding.png - вкладка все для свадьбы. \n" +
        "----- woman-day.png - вкадка 8 марта. \n" +
        "";

    const [banner, setBanner] = useState();

    async function upload() {
        const formData = new FormData();
        formData.append("file", banner);
        await uploadBanner(formData, user.credentials).then((resp) => {
           showMessage("Баннер успешно загружен. Для того, чтобы увидеть изменения, обновите страницу.")
        }).catch((err) => AxiosError(err, showMessage))
    }

    function valuesToJson(values) {
        return {
            title: values.title,
            img: values.img,
            categories: getCategories(values.categories),
            type: values.type.value,
            index: values.index,
            btn: values.btn
        };
    }

    function getCategories(categories) {
        return categories.map((cat) => {
            return cat.value;
        });
    }

    return (
        <>
        {(user && user.user.role === 'ADMIN' && tabs) &&
            <div className={"edit-tab-page"}>
                <div className={"instructions"}>
                    <h3 style={{marginTop: "20px"}}>Инструкция по добавлению/редактированию вкладок.</h3>
                    {instructions}
                    <h3 style={{marginTop: "20px", width: "100%"}}>Инструкция по загрузке баннеров.</h3>
                    {instructionsUploadBanners}
                </div>
                <button className={"btn"} onClick={() => setPage(1)}>Добавить вкладку</button>
                <button className={"btn"} onClick={() => setPage(2)}>Редактировать вкладки</button>
                <button className={"btn"} onClick={() => setPage(3)}>Загрузить баннер</button>
                {page === 1 && <AddTab toJson={valuesToJson} user={user} showMessage={showMessage}/>}
                {page === 2 && tabs.map((tab, index) => {
                    return <EditTab toJson={valuesToJson} key={index} user={user} tab={tab} showMessage={showMessage}/>
                })}
                {page === 3 && <div style={{display: "flex", flexWrap: "nowrap", gap: "15px", marginTop: "50px"}}>
                    <input className={"input-banner"} name="file" type="file" id="input__file"
                           onChange={(event) => setBanner(event.currentTarget.files[0])}/>
                    <button className={"upload-banner-btn"} onClick={() => upload()}>Загрузить</button>
                </div>}
            </div>}
        </>
    );
};

export default EditTabsPage;