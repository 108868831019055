import React from 'react';
import "../account-style.css"
import {NavLink} from "react-router-dom";
import {IoMdAdd} from "react-icons/io";
import {SlMenu} from "react-icons/sl";
import {TbEdit} from "react-icons/tb";
import {VscAccount} from "react-icons/vsc";

const AdminPanel = () => {
    return (
        <div className={"account-block"} style={{maxWidth: "1200px", width: "100%"}}>
            <div className={"title"}>Admin Panel</div>
            <div style={{display: "flex", flexWrap: "wrap", gap: "15px", justifyContent: "flex-start"}}>
                <NavLink to={"/add-product"} className={"admin-btn"}><IoMdAdd size={16}/> Добавить товар</NavLink>
                <NavLink to={"/orders"} className={"admin-btn"}><SlMenu size={12}/> Заказы</NavLink>
                <NavLink to={"/edit-tabs"} className={"admin-btn"}><TbEdit size={16}/>Вкладки/Баннеры</NavLink>
                <NavLink to={"/admin-users"} className={"admin-btn"}><VscAccount size={16}/>Пользователи</NavLink>
                <NavLink to={"/admin-products"} className={"admin-btn"}><VscAccount size={16}/>Товары</NavLink>
                <NavLink to={"/admin-reviews"} className={"admin-btn"}><VscAccount size={16}/>Отзывы</NavLink>
                <NavLink to={"/admin-settings"} className={"admin-btn"}><VscAccount size={16}/>Настройки</NavLink>
            </div>
        </div>
    );
};

export default AdminPanel;