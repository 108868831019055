import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className={"privacy-policy-page"}>
            <div className={"title"}>Политика обработки персональных данных</div>
            <pre className={"text"}>
                <p>Предоставляя свои персональные данные Пользователь даёт согласие на обработку, хранение и использование
                своих персональных данных на основании ФЗ № 152-ФЗ «О персональных данных» от 27.07.2006 г. в следующих
                    целях:</p>
                {'\n'}
                <p> - осуществление поддержки клиентов;</p>
                <p> - рассылка информации о маркетинговых событиях, рекламных акциях Продавца;</p>
                <p> - проведение аудита и прочих внутренних исследований для повышения качества предоставляемых услуг.</p>
                {'\n'}
                <p>Под персональными данными подразумевается любая информация личного характера, которая позволяет
                    установить личность Пользователя/Покупателя, в частности:</p>
                {'\n'}
                <p>фамилия, имя, отчество;</p>
                <p>дата рождения;</p>
                <p>контактный телефон;</p>
                <p>адрес электронной почты;</p>
                <p>почтовый адрес.</p>
                {'\n'}
                <p>Персональные данные Пользователей хранятся только на электронных носителях и обрабатываются с
                    использованием автоматизированных систем, кроме случаев, когда их ручная обработка необходима, чтобы
                    исполнить требования законодательства.</p>

                {'\n'}
                <p>Компания обязуется не передавать полученные персональные данные третьим лицам, кроме следующих
                    случаев:</p>
                {'\n'}
                <p>- запросы уполномоченных органов государственной власти РФ только по основаниям и в порядке,
                    установленным законодательством РФ;</p>
                <p>- передача данных стратегическим партнерам, которые предоставляют Компании товары и услуги, или тем из
                    них, которые помогают Компании реализовывать Потребителям товары и услуги. Третьим лицам мы
                    предоставляем только минимальный объем персональных данных, который необходим для оказания нужной
                    услуги или проведения транзакции.</p>
                {'\n'}
                <p>Компания оставляет за собой право вносить в настоящие Правила изменения в одностороннем порядке при
                    условии, что эти изменения не противоречат действующему законодательству РФ. Изменения условий
                    настоящих правил вступают в силу после их публикации на Сайте.</p>
                {'\n'}
                <p>Если вы хотите связаться с нами по поводу сбора и использования нами Вашей личной информации,
                    проверить или исправить свою личную информацию или отказаться от любых наших будущих сообщений и
                    рассылок, пожалуйста, напишите нам на e-mail: noreply-cvetovodoff@yandex.ru. В соответствии с
                    законом, при получении такого требования мы удалим Ваши данные в 3-дневный срок.</p>
                {'\n\n'}
                <p>Интернет-магазин «Цветоводофф»</p>
                <p>обновлено 28 января 2024г.</p>
            </pre>

            <div className={"title"}>Юридическая информация</div>
            <pre className={"text"}>
                <p>ИП Филиппов Филипп Владимирович, 443125, Россия, обл Самарская, г.Самара, ул.Губанова, дом 20, кв.77</p>
                <p>ИНН 631937692100, ОГРНИП 320631300023397, р/с 40802810214500034850</p>
                <p>кор/с 30101810745374525104, Банк ООО "Банк Точка", БИК 044525104.</p>
            </pre>
        </div>
    );
};

export default PrivacyPolicy;