import React from 'react';
import "./style.css";
import {useNavigate} from "react-router-dom";

const MobileNavMenuItem = ({tab, title, rotate, setFilter, filters, link, setOpen, handleAction}) => {

    const navigate = useNavigate();

    function getImage(title) {
        switch (title) {
            case "ЦВЕТЫ И ПОДАРКИ":
                return <img src={"../../img/icons/flowers.png"} alt={"img"}/>
            case "ГОРШЕЧНЫЕ РАСТЕНИЯ":
                return <img src={"../../img/icons/plants.png"} alt={"img"}/>
            case "ДЕКОР":
                return <img src={"../../img/icons/decor.png"} alt={"img"}/>
            case "ЦВЕТОЧНАЯ ПОДПИСКА":
                return <img src={"../../img/icons/flower-pass.png"} alt={"img"}/>
            case "НАША ЦВЕТОЧНАЯ МАСТЕРСКАЯ":
                return <img src={"../../img/icons/workshop.png"} alt={"img"}/>
            default:
                return <></>
        }
    }

    return (
        <div className={"hidden-menu-item"}>
            <div className={tab === title && rotate ? "front active" : "front"} onClick={() => handleAction(title)}>
                {getImage(title)}
            </div>
            <div className={tab === title && rotate ? "back active" : "back"}>
                {getImage(title)}
                <div className={"back-nav-items"}>
                    {filters.map((it, index) => {
                        return <div key={index} className={"back-nav-item"} onClick={() => {
                            setFilter(it);
                            setOpen(false);
                            navigate(link);
                        }}>
                            {it.title.toUpperCase()}
                        </div>
                    })}
                </div>
            </div>
        </div>
    );
};

export default MobileNavMenuItem;