import React, {useEffect, useState} from 'react';
import {NavLink} from "react-router-dom";
import GetNameOfCount from "../../components/GetNameOfCount";
import DeliveryContainer from "./DeliveryContainer";
import CustomDateTimePicker from "./CustomDateTimePicker";
import CreateOrder from "../../components/axios/CreateOrder";
import AxiosError from "../../components/axios/AxiosError";
import {BiLoaderCircle, BiWallet} from "react-icons/bi";
import {FaCheck, FaRegCreditCard} from "react-icons/fa6";
import {TiWarning} from "react-icons/ti";
import {TbTruckDelivery} from "react-icons/tb";
import {MdOutlineStoreMallDirectory} from "react-icons/md";
import {BsPostcardHeart} from "react-icons/bs";
import {convertToMoneyFormat} from "../../helpers/Utils";


const Checkout = ({cartProducts, setProducts, user, showMessage, siteData, clearBuffer}) => {

    const [ready, setReady] = useState(true);


    const [totalSum, setTotalSum] = useState(0);

    const [useBonuses, setUseBonuses] = useState(false);

    const userBonusPoints = user !== undefined ? user.user.bonusPoints : 0;

    const [bonusPoints, setBonusPoints] = useState(0);


    const [paymentType, setPaymentType] = useState("CASH");
    const [paymentStatus, setPaymentStatus] = useState("NOT_PAID")
    const [freeDelivery, setFreeDelivery] = useState(false);

    const [deliveryType, setDeliveryType] = useState("SELF");
    const [deliveryCity, setDeliveryCity] = useState("");
    const [deliveryStreet, setDeliveryStreet] = useState("");
    const [deliveryBuilding, setDeliveryBuilding] = useState("");
    const [deliveryApartment, setDeliveryApartment] = useState("");
    const [deliveryContact, setDeliveryContact] = useState("");
    const [deliveryPhoneNumber, setDeliveryPhoneNumber] = useState("");
    const [deliveryDescription, setDeliveryDescription] = useState("");
    const deliveryCost = getSiteData("deliveryCost");


    const [deliveryTo, setDeliveryTo] = useState('ADDRESS');
    const [postcard, setPostcard] = useState(false);
    const [postcardText, setPostcardText] = useState("");
    const postcardCost = getSiteData("postcardCost");


    const [orderTime, setOrderTime] = useState('');
    const [manualOrderTime, setManualOrderTime] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [iconState, setIconState] = useState(0);

    useEffect(() => {
        checkRequiredFields();
    }, [totalSum, deliveryContact, deliveryType, deliveryTo, deliveryDescription, deliveryCity, deliveryStreet, deliveryBuilding, deliveryApartment, deliveryPhoneNumber, postcard, postcardText, orderTime])


    function getSiteData(name) {
        return siteData.filter((s) => s.key === name)[0];
    }

    function getTotalSum() {
        let sum = 0;

        cartProducts.forEach((el) => {
            sum += el.totalSum;
        })

        return sum;
    }


    useEffect(() => {
        if (getTotalSum() - bonusPoints >= 5000) {
            setFreeDelivery(true);
        }

    }, [totalSum])


    useEffect(() => {
        let sum = getTotalSum() - bonusPoints;


        if (deliveryType === "DELIVERY" && !freeDelivery) {
            sum += Number.parseInt(deliveryCost.value);
        } else {
            setReady(true);
        }

        if (postcard) {
            sum += Number.parseInt(postcardCost.value);
        }

        setTotalSum(sum);

        // eslint-disable-next-line
    }, [cartProducts, bonusPoints, deliveryType, postcard, freeDelivery])


    function checkRequiredFields() {
        if (!orderTime && !manualOrderTime) {
            setReady(false);
        } else if (deliveryType === 'DELIVERY' && deliveryTo === 'ADDRESS' && (!deliveryCity || !deliveryBuilding || !deliveryStreet)) {
            setReady(false);
        } else if (deliveryType === 'DELIVERY' && deliveryTo === 'PHONE' && (!deliveryPhoneNumber || !deliveryContact)) {
            setReady(false);
        } else {
            setReady(true);
        }
    }

    function checkPostcardInCart() {
        return cartProducts.find((el) => el.item.categories.includes('POSTCARD')) !== undefined;
    }


    function calculateMaxBonus() {

        let num1 = Math.floor(getTotalSum() * 0.2);
        return num1 < userBonusPoints ? num1 : userBonusPoints;

    }

    function getReadyTime() {
        let time = 0;
        cartProducts.forEach((item) => {
            time = Math.max(time, item.item.readyTime);
        })

        return time;
    }

    function getAmountForFreeDelivery() {
        let amount = totalSum - deliveryCost.value;

        if (amount >= 5000) {
            return 0;
        }

        return 5000 - amount;
    }

    function closeModalWindow(status) {
        if (status === 1) {
            setProducts([]);
        }
        setShowModal(false);
    }
    async function createOrder() {
        setShowModal(true);
        setIconState(1);

        let order = {
            userId: user.user.id,
            productList: cartProducts.map((el) => {
                return {
                    productId: el.item.id,
                    productName: el.item.name,
                    quantity: el.quantity,
                    totalSum: el.totalSum,
                    price: el.price.currentPrice,
                    size: el.price.size
                }
            }),
            orderSum: totalSum,
            bonusPoints: bonusPoints,
            delivery: deliveryType,
            deliveryCity: deliveryCity,
            deliveryStreet: deliveryStreet,
            deliveryBuilding: deliveryBuilding,
            deliveryApartment: deliveryApartment,
            deliveryPhoneNumber: deliveryPhoneNumber,
            deliveryInfo: deliveryDescription,
            deliveryContact: deliveryContact,
            deliveryPriceId: freeDelivery ? undefined : deliveryCost.value,
            addPostcard: postcard,
            postcardText: postcardText,
            postcardPriceId: postcard ? postcardCost.id : undefined,

            orderTime: orderTime,
            paymentType: paymentType,
            paymentStatus: paymentStatus,
        }

        order = JSON.stringify(order);

        await CreateOrder(order, user)
            .then((response) => {
                if (paymentType === "ONLINE") {
                    setShowModal(false);
                    setIconState(0);
                    window.location.href = response.data.paymentLink;
                }
                else {
                    setIconState(2);
                }
                clearBuffer();
            })
            .catch((err) => {
                setIconState(3);
                AxiosError(err, showMessage);
            });
    }


    return (
            <div className={"order-checkout"}>
                <div className="checkout-title">Оформление заказа</div>
                {showModal && <div className={"modal-wait-content"}>
                    <div className={"modal-wait-window"}>
                        {iconState === 1 && <div className={"info-message"}><BiLoaderCircle className={"rotate-icon"}/><div style={{textAlign: "start", fontSize: "14px"}}>Ожидайте</div></div>}
                        {iconState === 2 && <div className={"info-message"}><FaCheck className={"icon"}/><div style={{textAlign: "start", fontSize: "14px"}}>Заказ успешно создан</div></div>}
                        {iconState === 3 && <div className={"info-message"}><TiWarning className={"icon"} style={{color: "darkred"}}/><div style={{textAlign: "start", fontSize: "14px"}}>Произошла ошибка. Заказ не был создан. Попробуйте еще раз.</div></div>}
                        {iconState === 2 ? <NavLink to={"/"} className={"close-btn"} onClick={() => closeModalWindow(1)}>Вернуться на главную</NavLink> : <button className={"close-btn"} onClick={() => closeModalWindow(2)}>Закрыть окно</button>}
                    </div>
                </div>}
                <div className={"checkout-container"}>
                    <div className={"data-block-2"}>
                        <div className={"data-block-title"}>Данные клиента</div>
                        <div className="form-group" style={{width: "100%", maxWidth: "400px"}}>
                            <label className="form-label" htmlFor="name">Имя *</label>
                            <input defaultValue={user !== undefined ? user.user.name : ''}
                                   className={"form-control-readOnly"}
                                   type="text"
                                   readOnly
                            />
                        </div>
                        <div className="form-group" style={{width: "100%", maxWidth: "400px"}}>
                            <label className="form-label" htmlFor="email">Email *</label>
                            <input defaultValue={user !== undefined ? user.user.email : ''}
                                   className={"form-control-readOnly"}
                                   type="text"
                                   readOnly/>
                        </div>
                        <div className="form-group" style={{width: "100%", maxWidth: "400px"}}>
                            <label className="form-label" htmlFor="name">Телефон *</label>
                            <input defaultValue={user !== undefined ? user.user.phone : ''}
                                   className={"form-control-readOnly"}
                                   type="text"
                                   readOnly/>
                        </div>
                    </div>

                    <div className={"data-block-2"}>
                        <div className={"data-block-title"}>Способ получения</div>
                        <div className="choose-type-delivery">
                            <div className={deliveryType === "SELF" ? "type-delivery-active" : "type-delivery"}
                                 onClick={() => {
                                     setDeliveryType("SELF");
                                 }}><MdOutlineStoreMallDirectory size={18} style={{marginRight: "10px"}}/> Самовывоз
                            </div>
                            <div className={deliveryType === "DELIVERY" ? "type-delivery-active" : "type-delivery"}
                                 onClick={() => setDeliveryType("DELIVERY")}><TbTruckDelivery size={18}
                                                                                              style={{marginRight: "10px"}}/>
                                Доставка
                            </div>
                        </div>

                        {(deliveryType === "SELF") &&
                            <div className={"self"}>
                                <h3>Цветочная мастерская "Цветоводофф" в городе Самара.</h3>
                                <h5>Самара, ул Демократическая, д 16 к 1</h5>
                                <h6>Работает</h6>
                                <h5>Ежедневно с 8:00 до 20:00</h5>
                                <h6>Телефон</h6>
                                <h5>+7 (927) 021-00-12</h5>
                                <h6>Дополнительная информация</h6>
                                <div
                                    style={{display: "grid", gridTemplateColumns: "1fr", margin: "5px", rowGap: "5px"}}>
                                    <NavLink className={"link-to-page"} to={"/payment-delivery"}>Доставка и
                                        оплата</NavLink>
                                    <NavLink className={"link-to-page"} to={"/warranty-info"}>Гарантия и
                                        возврат</NavLink>
                                    <NavLink className={"link-to-page"} to={"/loyalty-info"}>Бонусная
                                        программа</NavLink>
                                    <NavLink className={"link-to-page"} to={"/reviews"}>Отзывы</NavLink>
                                </div>

                            </div>
                        }
                        {(deliveryType === "DELIVERY") &&
                            <DeliveryContainer
                                setReady={setReady}
                                city={deliveryCity}
                                setCity={setDeliveryCity}
                                street={deliveryStreet}
                                setStreet={setDeliveryStreet}
                                building={deliveryBuilding}
                                setBuilding={setDeliveryBuilding}
                                apartment={deliveryApartment}
                                setApartment={setDeliveryApartment}
                                deliveryPhone={deliveryPhoneNumber}
                                deliveryCost={deliveryCost}
                                setPhone={setDeliveryPhoneNumber}
                                deliveryTo={deliveryTo}
                                setDeliveryTo={setDeliveryTo}
                                contact={deliveryContact}
                                setContact={setDeliveryContact}
                                getAmountForFreeDelivery={getAmountForFreeDelivery}/>}

                        {!checkPostcardInCart() && <>
                            <div className={postcard ? "postcard-active" : "postcard"}
                                 onClick={() => setPostcard(!postcard)}><BsPostcardHeart size={16}
                                                                                         style={{marginRight: "10px"}}/>Добавить
                                открытку
                            </div>
                            {postcard && <div className="form-group" style={{
                                marginBottom: "40px", marginTop: "0"
                            }}>
                                <div className={"delivery-info"} style={{
                                    marginBottom: "10px"
                                }}>Стоимость открытки - {postcardCost.value} ₽
                                </div>
                                <textarea
                                    placeholder={"Текст открытки"}
                                    className={"form-control"}
                                    value={postcardText}
                                    onChange={(event) => setPostcardText(event.currentTarget.value)}
                                    style={{maxWidth: "1090px", minHeight: "50px"}}
                                />
                            </div>}</>}
                        <div className={"delivery-info"} style={{
                            marginBottom: "10px"
                        }}>Уважаемый клиент, наша мастерская, по индивидуальным заказам, осуществляет доставку в ночное время за дополнительную плату. <p>Если у вас возникла в этом потребность, пожалуйста поставьте галочку <span>"Указать время вручную"</span>, укажите необходимую дату и время доставки в поле <span>"Комментарии к заказу"</span> и наш менеджер свяжется с Вами для уточнения деталей и стоимости.</p>
                            <p>Обратите внимание, что заказ должен быть сделан в рабочее время мастерской и подтвержден менеджером.</p>
                        </div>
                      <CustomDateTimePicker readyTime={getReadyTime()} setOrderTime={setOrderTime} orderTime={orderTime} manualOrderTime={manualOrderTime} setManualOrderTime={setManualOrderTime}/>
                        <div className="form-group" style={{marginTop: "20px"}}>
                            <textarea className="form-control" value={deliveryDescription} style={{height: "auto", minHeight: "100px", maxWidth: "1090px"}}
                                   placeholder={"Комментарии к заказу"}
                                   onChange={(event) => setDeliveryDescription(event.currentTarget.value)}
                                   maxLength={1024}
                            />
                        </div>
                    </div>

                    <div className={"data-block-2"}>
                        <div className={"data-block-title"}>Способ оплаты</div>

                        <div className="choose-type-payment">
                            <div className={paymentType === "CASH" ? "type-payment-active" : "type-payment"}
                                 onClick={() => setPaymentType("CASH")}><BiWallet size={16}
                                                                                  style={{marginRight: "10px"}}/> Наличные
                            </div>
                            <div className={paymentType === "ONLINE" ? "type-payment-active" : "type-payment"}
                                 onClick={() => setPaymentType("ONLINE")}><FaRegCreditCard size={16}
                                                                                           style={{marginRight: "10px"}}/> Банковская
                                карта
                            </div>
                        </div>

                            <div className={"delivery-info"}>{paymentType === 'ONLINE' ? "Вы перейдете на страницу оплаты после нажатия на кнопку оформить." : "Оплата при получении наличными либо банковской картой."}</div>

                    </div>
                </div>


                <div className={"cart-menu"} style={{alignItems: "flex-start"}}>
                    <div className={"order-info"}>
                        <div className="promo">
                            <div className="promo-title" onClick={() => setUseBonuses(!useBonuses)}>Использовать
                                бонусы
                            </div>
                            {useBonuses && <>
                                <div className={"text1"}>Ваш бонусный баланс - <span>{user.user.bonusPoints} </span>
                                </div>
                                <input className="input-promo"
                                       type={"number"}
                                       max={calculateMaxBonus()}
                                       min={0}
                                       placeholder={"Введите количество бонусов"}
                                       value={bonusPoints}
                                       onChange={(event) => {
                                           let max = calculateMaxBonus();
                                           let value = event.currentTarget.value;

                                           if (value > max) {
                                               value = max;
                                           } else if (value < 0) {
                                               value = 0;
                                           }

                                           setBonusPoints(value);
                                       }}/>
                                <div className={"delivery-info"}>
                                    Уважаемый клиент, Вы можете оплатить бонусными баллами не более 20% от стоимости
                                    заказа
                                    без учета доставки.
                                </div>
                            </>}
                        </div>
                        <div className={"order-info"}>
                            <div className={"checkout-countItems"}>В
                                заказе {cartProducts.length} {GetNameOfCount(cartProducts.length)}</div>

                            {(getTotalSum() > 0) && (cartProducts.map((el, index) => {
                                return <div key={index} className={"order-item-info"}>
                                    <h3>{el.item.name} ({el.quantity} шт) - {convertToMoneyFormat(el.totalSum)} ₽</h3>

                                </div>;
                            }))}
                            {(deliveryType === 'DELIVERY') &&
                                <div className={"delivery-cost"}
                                     style={{marginTop: "30px"}}>Доставка: {freeDelivery ? 0 : deliveryCost.value} ₽</div>}
                            {postcard &&
                                <div className={"delivery-cost"}
                                     style={{marginTop: "10px"}}>Открытка: {postcardCost.value} ₽</div>}
                            <div className={"total-sum"}>Итого: {convertToMoneyFormat(totalSum)} ₽</div>
                        </div>
                    </div>
                    <div className={"order-info"}>
                        <div className={ready ? "btn-purchase" : "btn-purchase-unavailable"}
                             onClick={() => createOrder()}>Оформить заказ
                        </div>
                        <NavLink to="/order">
                            <div className="reset-cart">Вернуться в корзину
                            </div>
                        </NavLink>
                        <div className={"private-policy-check"}>Нажимая кнопку "Оформить
                            заказ", Вы соглашаетесь с условиями <NavLink to={"/privacy-policy"}
                                                                         className={"private-policy-check"}
                                                                         style={{color: "#0ea3a9"}}>политики
                                конфиденциальности</NavLink> и даете согласие на обработку Ваших персональных данных
                        </div>
                    </div>

                </div>
            </div>

    );
};

export default Checkout;