import React, {useState} from 'react';
import {useSearchParams} from "react-router-dom";
import "./resetPasswordStyle.css";
import { BiLoaderCircle } from "react-icons/bi";
import { FaCheck } from "react-icons/fa6";
import { TiWarning } from "react-icons/ti";
import GenerateResetToken from "../../components/axios/GenerateResetToken";
import SetNewPassword from "../../components/axios/SetNewPassword";



const ResetPassword = ({showMessage}) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const resetToken = searchParams.get("resetToken");
    const [email, setEmail] = useState("");
    const [iconState, setIconState] = useState(0);
    const [password, setPassword] = useState("")
    const [check, setCheck] = useState(false);


    async function sendRequestGenerateToken() {
        setIconState(1);

        await GenerateResetToken(email).then((response) => {
            setIconState(2);
            setEmail("");
        }).catch(function (error) {
            if (error.response) {
                // Запрос был сделан, и сервер ответил кодом состояния, который
                // выходит за пределы 2xx
                showMessage("Запрос не был выполнен сервер")
            } else if (error.request) {
                // Запрос был сделан, но ответ не получен
                // `error.request`- это экземпляр XMLHttpRequest в браузере и экземпляр
                // http.ClientRequest в node.js
                showMessage("В данный момент сервер не отвечает, попробуйте еще раз чуть позже.")
            } else {
                // Произошло что-то при настройке запроса, вызвавшее ошибку
                console.log('Error', error.message);
            }
        });
    }

    async function sendRequestSetPassword() {
        setIconState(1);

        await SetNewPassword(password, resetToken).then((response) => {
            setIconState(2);
            setPassword("");
        }).catch(function (error) {
            setIconState(3);
            if (error.response) {
                // Запрос был сделан, и сервер ответил кодом состояния, который
                // выходит за пределы 2xx
                showMessage("Запрос не был выполнен сервер")
            } else if (error.request) {
                // Запрос был сделан, но ответ не получен
                // `error.request`- это экземпляр XMLHttpRequest в браузере и экземпляр
                // http.ClientRequest в node.js
                showMessage("В данный момент сервер не отвечает, попробуйте еще раз чуть позже.")
            } else {
                // Произошло что-то при настройке запроса, вызвавшее ошибку
                console.log('Error', error.message);
            }
        });
    }

    function checkPassword(repeatPassword) {
        setCheck(password === repeatPassword);
    }

    return (
        <div className={"resetPassword"}>
            {resetToken === null ?
                <div className={"input-email-form"}>

                    <label className="form-label" htmlFor="name">Восстановление пароля</label>
                    <input id={"email"} type={"email"} defaultValue={email} placeholder={"Введите email"} onChange={(event) => setEmail(event.currentTarget.value)}/>
                    <div className={"btn-block"}>
                        <button disabled={iconState > 0 || email.length < 1} type={"submit"} onClick={() => sendRequestGenerateToken()}>Сбросить пароль</button>
                        {iconState === 1 && <div className={"info-message"} ><BiLoaderCircle className={"rotate-icon"}/><div style={{textAlign: "start", fontSize: "14px"}}>Ожидайте</div></div>}
                        {iconState === 2 && <div className={"info-message"}><FaCheck className={"icon"}/><div style={{textAlign: "start", fontSize: "14px"}}>Письмо с инструкцией успешно отправлено, проверьте ваш почтовый ящик</div></div>}
                        {iconState === 3 && <div className={"info-message"}><TiWarning className={"icon"} style={{color: "darkred"}}/><div style={{textAlign: "start", fontSize: "14px"}}>При выполнения запроса произошла ошибка, попробуйте еще раз.</div></div>}
                    </div>
                </div> :

                <div className={"input-new-password-form"}>
                    <label className="form-label" htmlFor="name">Установка нового пароля</label>
                    <input id={"email"} type={"password"} defaultValue={password} placeholder={"Введите новый пароль"} onChange={(event) => setPassword(event.currentTarget.value)}/>
                    <input id={"email"} type={"password"} placeholder={"Введите пароль еще раз"} onChange={(event) => checkPassword(event.currentTarget.value)}/>
                    {(!check && password.length > 0)  && <label className="error-label" htmlFor="name">Пароли не совпадают</label>}

                    <div className={"btn-block"}>
                        <button disabled={iconState > 0 || !check} type={"submit"} onClick={() => sendRequestSetPassword()}>Установить новый пароль</button>
                        {iconState === 1 && <div className={"info-message"}><BiLoaderCircle className={"rotate-icon"}/><div style={{textAlign: "start", fontSize: "14px"}}>Ожидайте</div></div>}
                        {iconState === 2 && <div className={"info-message"}><FaCheck className={"icon"}/><div style={{textAlign: "start", fontSize: "14px"}}>Пароль успешно изменен.</div></div>}
                        {iconState === 3 && <div className={"info-message"}><TiWarning className={"icon"} style={{color: "darkred"}}/><div style={{textAlign: "start", fontSize: "14px"}}>При выполнения запроса произошла ошибка, попробуйте еще раз.</div></div>}
                    </div>
                </div>}
        </div>
    );
};

export default ResetPassword;