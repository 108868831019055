import React, {useEffect, useState} from 'react';
import {NavLink, useLocation} from "react-router-dom";
import "./style.css";
import MenuItem from "./MenuItem";
import {useResize} from "../../helpers/windowSizes/use-resize";
import CatalogMenu from "./CatalogMenu";
import ModalCart from "./ModalCart";


const Header = ({
                    openModal,
                    user,
                    cartProducts,
                    favoriteProducts,
                    logout,
                    setFilter,
                    filters,
                    deleteItemFromProducts
                }) => {

    const [cartEmpty, setCartEmpty] = useState(true);
    const location = useLocation();

    const [position, setPosition] = useState(25);
    const [showCartModalMenu, setShowCartModalMenu] = useState(false);
    const [showFavoriteModalMenu, setShowFavoriteModalMenu] = useState(false);

    const scrollPosition = () => window.scrollY || document.documentElement.scrollTop;
    const header = document.querySelector('header');


    /*window.addEventListener('scroll', () => {
        if (header) {
            if (scrollPosition() >= 25 && position === 25) {
                header.style.boxShadow = 'var(--main-box-shadow)';
                setPosition(0);
            } else if (scrollPosition() < 25 && position === 0) {
                header.style.boxShadow = 'var(--main-box-shadow)';
                setPosition(25);
            }
        }
    })*/

    useEffect(() => {
        if (cartProducts.length === 0) {
            setCartEmpty(true);
        }
        else {
            setCartEmpty(false)
        }
    }, [cartProducts])

    const handleMouseEnter = (type) => {
        if (type === 'cart') {
            if (location.pathname !== "/order" && cartProducts.length > 0) {
                setShowCartModalMenu(true);
            }
        } else if (type === 'favorite') {
            setShowFavoriteModalMenu(true);
        }
    }

    const handleMouseLeave = (type) => {
        switch (type) {
            case 'favorite':
                setShowFavoriteModalMenu(false);
                break;
            case 'cart':
                setShowCartModalMenu(false);
                break;
        }
    }

    function getTopTab() {
        return filters.filter((el) => el.btn)[0];
    }


    return (
        <>
            <header>
                <div className={"logo-nav-bar"}>
                    <div style={{display: "flex", flexWrap:"nowrap", gap: "5px",backgroundColor: "white"}}>
                        <NavLink to={"/"} className={"nav-list__link"}>
                            <img className={"logo"} src={"../../img/logo/1.png"} alt={"logo"}/>
                        </NavLink>
                        <CatalogMenu topTab={getTopTab()} filters={filters} setFilter={setFilter} position={position}/>
                    </div>

                    {useResize().isScreenM && <ul className={"header-nav"}>
                        <div style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            columnGap: "15px",
                            justifyContent: "flex-end"
                        }}>
                            <div onMouseEnter={() => handleMouseEnter('cart')}
                                 onMouseLeave={() => handleMouseLeave('cart')}>
                                <div onClick={() => setShowCartModalMenu(false)}
                                         style={{position: "relative"}}>
                                    <NavLink to={"/order"} className={"btn-common"}>
                                        <MenuItem name={"Корзина"} cartLength={cartProducts.length}/>
                                    </NavLink>
                                    {showCartModalMenu &&
                                        <ModalCart deleteItem={deleteItemFromProducts} products={cartProducts}
                                                   type={"cart"}/>}
                                </div>
                            </div>
                            <div className={"btn-common"} onMouseEnter={() => handleMouseEnter('favorite')}
                                 onMouseLeave={() => handleMouseLeave('favorite')}>
                                <MenuItem name={"Избранное"} cartLength={favoriteProducts.length}/>
                                {showFavoriteModalMenu &&
                                    <ModalCart deleteItem={deleteItemFromProducts} products={favoriteProducts}
                                               type={"favorite"}/>}
                            </div>

                            {!user && <>
                                <div className={"btn-common"} onClick={() => openModal(null, "login")}><MenuItem name={"Войти"}/></div>
                            </>}
                            {user && <>
                                <NavLink to={"/personal-account"}>
                                    <div className={"btn-common"} ><MenuItem name={"Кабинет"}/></div>
                                </NavLink>
                                <NavLink to={"/"}>
                                    <div className={"btn-common"} onClick={() => logout()}><MenuItem name={"Выйти"}/></div>
                                </NavLink>
                            </>}
                        </div>
                    </ul>
                    }
                </div>
            </header>
        </>

    );
};

export default Header;