import React, {useEffect} from 'react';
import FilterItem from "./FilterItem";
import "./style.css";
import {useResize} from "../../helpers/windowSizes/use-resize";
import {Dropdown} from "react-dropdown-now";
import {FaArrowDown} from "react-icons/fa6";
import {IoFilterSharp} from "react-icons/io5";

const Filter = ({filterList, activeFilter, setActiveFilter, dropdawn}) => {

    const windowSize = useResize();

    const options = fillOptions();

    useEffect(() => {
        if (!windowSize.isScreenL) {
            dropdawn = true;
        }
    }, []);

    useEffect(() => {
        if (filterList) {
            fillOptions();
        }
    }, [filterList])



    function fillOptions() {

       /* for (let i = 0; i < filterList.length; i++) {
             let option = filterList[i];
             options[i] = {
                 value: option,
                 label: option.title
                 //label: <div style={{display: "flex", flexWrap: "nowrap", alignItems: "center", gap: "10px"}}><IoFilterSharp/>{option.title}</div>
             }
         }*/



        return filterList.map((el, index) => {
            return {
                id: index,
                value: el,
                label: <div key={index} style={{display: "flex", flexWrap: "nowrap", alignItems: "center", gap: "10px"}}>
                    <IoFilterSharp/>{el.title}</div>
                }
        });
    }

    function getCurrentFilter() {
        let option = options.filter((it) => it.value === activeFilter)[0];
        return option ? option : options[0];

    }

    return (
        <div className={"filter-container"}>
            {!dropdawn &&
                <div className={"filter-block"}>
                    {filterList && filterList.map((el, index) => {
                        return (<FilterItem filter={el} key={index} activeFilter={activeFilter}
                                            setFilter={setActiveFilter}/>)
                    })}
                </div>}

                {(dropdawn && options) && <div className={"mobile-filter-container"}>
                    <Dropdown
                        baseClassName={"rdn"}
                        className={"dropdown"}
                        placeholder="Выберите фильтр "
                        options={options}
                        onChange={(val) => setActiveFilter(val.value)}
                    />
                    <FaArrowDown/>
                </div>}

        </div>

    );
};

export default Filter;