import React from 'react';
import InputMask from "react-input-mask";

const DeliveryContainer = ({
                               contact,
                               deliveryCost,
                               setContact,
                               deliveryTo,
                               setDeliveryTo,
                               city,
                               street,
                               building,
                               apartment,
                               deliveryPhone,
                               setCity,
                               setStreet,
                               setPhone,
                               setBuilding,
                               setApartment,
                               getAmountForFreeDelivery
                           }) => {



    return (
        <div className={"delivery"}>
            <div className={"delivery-info"} style={{
                marginBottom: "5px"
            }}>Доставка осуществляется курьерской службой только в
                пределах
                г.Самара. <p>Стоимость доставки в пределах города {deliveryCost.value} рублей.</p><p> При заказе от 5000 рублей
                    доставка бесплатная.</p> <span><p>Внимание! Доставка осуществляется в течение часа, от указанного
                                    Вами времени.</p></span>
            </div>
            <div className={"delivery-info"}>Доставку по телефону выбирайте только в том случае, если вы не знаете
                точный адрес. <p>Мы сами позвоним по указанному номеру и узнаем адрес.</p>
            </div>

            <div className={"delivery-info"} style={{marginBottom: "20px"}}>Добавьте товаров еще
                на <span>{getAmountForFreeDelivery()} ₽</span>, чтобы доставка стала бесплатной.
            </div>


            <div className="choose-type-delivery">
                <div className={deliveryTo === "ADDRESS" ? "type-delivery-active" : "type-delivery"}
                     onClick={() => setDeliveryTo("ADDRESS")}>По адресу
                </div>
                <div className={deliveryTo === "PHONE" ? "type-delivery-active" : "type-delivery"}
                     onClick={() => setDeliveryTo("PHONE")}>По телефону
                </div>
            </div>

            {(deliveryTo === 'ADDRESS') && <div className={"data-block"} style={{boxShadow: "none"}}>
                <div className="form-group">
                    <input className={"form-control"} value={city}
                           style={{border: city.length < 1 ? "1px solid red" : ""}}
                           onChange={(event) => setCity(event.currentTarget.value)}
                           type="text"
                           placeholder={"* Город"}
                           maxLength={255}
                           required/>
                </div>
                <div className="form-group">
                    <input className={"form-control"} value={street}
                           style={{border: street.length < 1 ? "1px solid red" : ""}}
                           onChange={(event) => setStreet(event.currentTarget.value)}
                           type="text"
                           placeholder={"* Улица"}
                           maxLength={255}
                           required/>
                </div>
                <div className="form-group">
                    <input value={building}
                           className={"form-control"}
                           style={{border: building.length < 1 ? "1px solid red" : ""}}
                           onChange={(event) => setBuilding(event.currentTarget.value)}
                           type="text"
                           placeholder={"* Дом"}
                           maxLength={255}
                           />
                </div>
                <div className="form-group">
                    <input value={apartment} className="form-control"
                           placeholder={" Квартира"}
                           onChange={(event) => setApartment(event.currentTarget.value)}
                           type="text"
                           maxLength={255}
                    />
                </div>
            </div>}

            {(deliveryTo === 'PHONE') &&
                <div className={"data-block"} style={{boxShadow: "none"}}>
                    <div className={"form-group"}>
                        <input className={"form-control"} value={contact}
                               onChange={(event) => setContact(event.currentTarget.value)}
                               type="text"
                               placeholder={"* Имя получателя"}
                               style={{border: contact.length < 1 ? "1px solid red" : ""}}
                               maxLength={255}
                               required/>
                    </div>
                    <div className="form-group">
                        <InputMask mask="+7 (999)-999-99-99" value={deliveryPhone}
                                   onChange={(event) => setPhone(event.currentTarget.value)}>
                            {() => <input
                                className={"form-control"}
                                type="tel"
                                style={{border: deliveryPhone.length < 1 ? "1px solid red" : ""}}
                                placeholder={"* Телефон получателя"}
                                required/>}
                        </InputMask>
                    </div>
                </div>}
        </div>


    );
};

export default DeliveryContainer;