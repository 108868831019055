import React, {useEffect} from 'react';
import Filter from "../components/filter/Filter";
import Items from "../components/item/Items";
import {AnimatePresence} from "framer-motion";
import "./style.css";
import CreateBouquet from "../components/banners/CreateBouquet";
import Sort from "../components/sort/Sort";


const Catalog = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
            <div className={"main-block"}>
                    {props.filter && <img className={'page-banner'} src={"../../img/banners/".concat(props.filter.img)} alt={"banner"}/>}
                <div style={{display: "flex", width: "100%", flexWrap: "nowrap", gap: "10px", height: "min-content", marginBottom: "20px", alignItems: "start"}}>
                    <Filter filterList={props.filterItems} activeFilter={props.filter} setActiveFilter={props.setFilter} dropdawn={false}/>
                    <Sort setSort={props.setSort}/>
                </div>

                    {props.products.length > 0 ? <AnimatePresence mode={"wait"}>
                        <Items openModal={props.openModal} isItemAddedToCart={props.isItemAddedToCart} addItemToCart={props.addItemToCart} items={props.products} user={props.user} reloadProducts={props.loadProducts}/>
                        </AnimatePresence> :
                        <div style={{minHeight: "1000px", fontSize: "12px", color: "rgba(51,51,51,0.63)"}}>К сожалению,
                            в данный момент, по Вашему запросу товаров не найдено... Попробуйте использовать другие
                            фильтры и категории...</div>}
                    <CreateBouquet openModal={props.openModal}/>
            </div>
    );

};

export default Catalog;