import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import GetProductById from "../components/axios/GetProductById";
import apiData from "../helpers/ApiData";
import {
    convertToMoneyFormat,
    getDayFromPrepareTime,
    getDiscount,
    getMinPrice,
    getPriceBySize,
    getVendorCode
} from "../helpers/Utils";
import Price from "../components/item/Price";
import {TbDiscount2} from "react-icons/tb";
import {useResize} from "../helpers/windowSizes/use-resize";
import {FaCartArrowDown, FaCartPlus} from "react-icons/fa";
import {PiHeartBold} from "react-icons/pi";


const Product = ({user, addItemToCart, isItemAddedToCart, showMessage}) => {

    const {id} = useParams();

    const [product, setProduct] = useState();
    const [mainImg, setImg] = useState({});

    const [offeredItems, setOfferedItems] = useState();

    const [currentSize, setCurrentSize] = useState("");

    const [price, setPrice] = useState();

    const windowSize = useResize();


    useEffect(() => {
        async function fetchData() {
            await GetProductById(id)
                .then((response) => {
                    setProduct(response.data);
                })
                .catch(function (error) {
                    if (error.response) {
                        // Запрос был сделан, и сервер ответил кодом состояния, который
                        // выходит за пределы 2xx
                        showMessage(error.response.data.message)
                    } else if (error.request) {
                        // Запрос был сделан, но ответ не получен
                        // `error.request`- это экземпляр XMLHttpRequest в браузере и экземпляр
                        // http.ClientRequest в node.js
                        showMessage("В данный момент сервер не отвечает, попробуйте еще раз чуть позже.")
                    } else {
                        // Произошло что-то при настройке запроса, вызвавшее ошибку
                        console.log('Error', error.message);
                    }
                });
            /*await GetOfferedItems("")
                .then((data) => setOfferedItems(data.data))
                .catch(function (error) {
                    if (error.response) {
                        // Запрос был сделан, и сервер ответил кодом состояния, который
                        // выходит за пределы 2xx
                        showMessage(error.response.data.message)
                    } else if (error.request) {
                        // Запрос был сделан, но ответ не получен
                        // `error.request`- это экземпляр XMLHttpRequest в браузере и экземпляр
                        // http.ClientRequest в node.js
                        showMessage("В данный момент сервер не отвечает, попробуйте еще раз чуть позже.")
                    } else {
                        // Произошло что-то при настройке запроса, вызвавшее ошибку
                        console.log('Error', error.message);
                    }
                });*/
        }

        fetchData();

    }, [])


    useEffect(() => {
        if (product) {
            setImg(product.attachments ? apiData.getAttachment(product.attachments[0].downloadLink) : "http://placehold.it/1000x1000");
            setPrice(getMinPrice(product.prices));
        }

    }, [product])


    useEffect(() => {
        if (product) {
            let price = getPriceBySize(product.prices, currentSize);
            setPrice(price);
        }
    }, [currentSize]);

    return (
        <div className={"product-page"}>
            {(product && price) && <>


                {windowSize.isScreenL ? <>
                        <div className={"product-page-title"}>
                            {product.name}
                        </div>
                    <div className={"product-container"}>
                        <div className={"img-block"}>
                            <div className={"preview"}>
                                {product.attachments && product.attachments.map((img, index) => {
                                    return (
                                        <img key={index}
                                             style={mainImg === apiData.getAttachment(img.downloadLink) ? {opacity: "100%"} : {opacity: "50%"}}
                                             src={apiData.getAttachment(img.downloadLink)}
                                             onClick={() => setImg(apiData.getAttachment(img.downloadLink))}
                                             alt={"img" + index}/>
                                    )
                                })}
                            </div>
                            <img src={mainImg} alt={"mainImg"}/>
                        </div>


                        <div className={"product-info"}>
                            <div>
                                <div className={"title"}
                                     style={{marginLeft: "1%"}}>Описание
                                    товара
                                </div>
                                <div className={"description"}>{product.description}</div>
                            </div>

                            <div>
                                <div className={"title"} style={{
                                    marginTop: "20px",
                                    marginLeft: "1%",
                                }}>Дополнительная информация
                                </div>
                                <div className={"description"} style={{
                                    backgroundColor: "#f5f5f5",
                                    color: "grey",
                                    padding: "5px 15px",
                                    fontSize: "16px"
                                }}>Артикул {getVendorCode(product.id)}</div>
                                <div className={"description"}
                                     style={{color: "grey", padding: "5px 15px", fontSize: "16px"}}
                                >Товар {product.isAvailable ? "в наличии" : "доступен к заказу"}</div>
                                <div className={"description"} style={{
                                    backgroundColor: "#f5f5f5",
                                    color: "grey",
                                    padding: "5px 15px",
                                    fontSize: "16px"
                                }}> Доступен к
                                    получению {getDayFromPrepareTime(product.readyTime)}</div>

                            </div>


                            <div>
                                <div className={"sizes-block"}>
                                    <div className={"title"} style={{
                                        width: "100%",
                                        marginTop: "0",
                                        marginLeft: "1%",
                                    }}>Размер
                                    </div>
                                    <div className={"sizes"}>
                                        {product.prices.sort(function (a, b) {
                                            return a.currentPrice - b.currentPrice
                                        }).map((item, index) => {
                                            return <Price key={index} price={item} setPrice={setPrice}
                                                          currentPrice={price}/>;
                                        })}
                                    </div>
                                </div>
                                <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                    <div className={"price"}>
                                        {price.oldPrice > 0 && <div style={{
                                            textDecoration: "line-through",
                                            color: "grey",
                                            opacity: "0.7"
                                        }}>{convertToMoneyFormat(price.oldPrice)} ₽</div>}
                                        {convertToMoneyFormat(price.currentPrice)} ₽
                                    </div>
                                    {price.oldPrice > 0 && <div className={"discount"} style={{fontSize: "16px"}}>
                                        <TbDiscount2 size={20}/>
                                        -{getDiscount(price.oldPrice, price.currentPrice)}%
                                    </div>}
                                    <div style={{display: "flex", flexWrap: "nowrap", gap: "10px"}}>
                                        <div className={"favorite-btn"}
                                             onClick={() => addItemToCart(product, price, "favorite")}>
                                            {isItemAddedToCart(product.id, price.size, "favorite") ?
                                                <PiHeartBold size={18} style={{color: "rgba(218,0,0,0.87)"}}/> :
                                                <PiHeartBold size={18}/>}
                                        </div>
                                        {isItemAddedToCart(product.id, price.size, 'cart') ?
                                            <div className={"add-to-cart-btn"}><FaCartArrowDown size={16}/>В корзине
                                            </div> :
                                            <div className={"add-to-cart-btn active"}
                                                 onClick={() => addItemToCart(product, price, 'cart')}><FaCartPlus
                                                size={16}/>Купить</div>}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    </> :
                    <div className={"mobile-product-container"}>
                        <div className={"img-block"}>
                            <img src={mainImg} alt={"mainImg"}/>
                            <div className={"preview"}>
                                {product.attachments && product.attachments.map((img, index) => {
                                    return (
                                        <img key={index}
                                             style={mainImg === apiData.getAttachment(img.downloadLink) ? {opacity: "100%"} : {opacity: "50%"}}
                                             src={apiData.getAttachment(img.downloadLink)}
                                             onClick={() => setImg(apiData.getAttachment(img.downloadLink))}
                                             alt={"img" + index}/>
                                    )
                                })}
                            </div>
                        </div>
                        <div className={"product-page-title"}>
                            {product.name}
                        </div>
                        <div className={"product-info"}>
                            <div>
                                <div className={"title"}>Описание
                                    товара
                                </div>
                                <div className={"description"}>{product.description}</div>
                            </div>

                            <div>
                                <div className={"title"} style={{
                                    marginTop: "20px",
                                }}>Дополнительная информация
                                </div>
                                <div className={"description"} style={{
                                    backgroundColor: "#f5f5f5",
                                    color: "grey",
                                    padding: "5px 15px",
                                    fontSize: "12px"
                                }}>Артикул {getVendorCode(product.id)}</div>
                                <div className={"description"}
                                     style={{color: "grey", padding: "5px 15px", fontSize: "12px"}}
                                >Товар {product.isAvailable ? "в наличии" : "доступен к заказу"}</div>
                                <div className={"description"} style={{
                                    backgroundColor: "#f5f5f5",
                                    color: "grey",
                                    padding: "5px 15px",
                                    fontSize: "12px"
                                }}> Доступен к
                                    получению {getDayFromPrepareTime(product.readyTime)}</div>

                            </div>


                            <div>
                                <div className={"sizes-block"}>
                                    <div className={"title"} style={{
                                        width: "100%",
                                        marginTop: "0",
                                    }}>Размер
                                    </div>
                                    <div className={"sizes"}>
                                        {product.prices.sort(function (a, b) {
                                            return a.currentPrice - b.currentPrice
                                        }).map((item, index) => {
                                            return <Price key={index} price={item} setPrice={setPrice}
                                                          currentPrice={price}/>;
                                        })}
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    alignItems: "center",
                                    justifyContent: "space-between"
                                }}>
                                    <div className={"price"}>
                                        {price.oldPrice > 0 && <div style={{
                                            textDecoration: "line-through",
                                            color: "grey",
                                            opacity: "0.7"
                                        }}>{price.oldPrice} ₽</div>}
                                        {price.currentPrice} ₽
                                    </div>
                                    {price.oldPrice > 0 && <div className={"discount"} style={{fontSize: "16px"}}>
                                        <TbDiscount2 size={20}/>
                                        -{getDiscount(price)}%
                                    </div>}
                                    <div style={{
                                        width: "100%",
                                        marginTop: "20px",
                                        display: "flex",
                                        justifyContent: "flex-start"
                                    }}>
                                        {isItemAddedToCart(product.id, price.size, 'cart') ?
                                            <div className={"add-to-cart-btn"}>В корзине</div> :
                                            <div className={"add-to-cart-btn active"}
                                                 onClick={() => addItemToCart(product, price, 'cart')}>Купить</div>}
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>}

            </>}
        </div>
    );
};

export default Product;