import axios from "axios";
import apiData from "../../helpers/ApiData";

export default async function CreateOrder(order, user) {

    return axios({
        method: "POST",
        url: apiData.createOrder(user.user.id),
        headers: {"Content-Type": "application/json", "Authorization": user.credentials},
        // в data передаем объект с данными формы
        data: order
    });
}