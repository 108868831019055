import React from 'react';
import "../account-style.css"
import {NavLink} from "react-router-dom";
import {getStatusOrder} from "../../../helpers/Utils";


const OrderItem = ({order, user}) => {



    return (
        <NavLink to={"/personal-account/orders/" + order.id} className={"order-item"}>
            <div className={"order-item-container"}>
                <div className={"order-column active"}>
                    <div style={{fontWeight: "600"}}>Заказ {order.number} от {order.created}</div>
                    <div style={{
                        width: "auto",
                        justifyContent: "flex-end",
                        display: "flex",
                        height: "min-content"
                    }}>{getStatusOrder(order.status)}</div>
                </div>


                <div className={"order-column"}>
                    <div className={"order-column-item"}>
                        <span>{order.delivery === "SELF" ? "Самовывоз из магазина " : "Доставка"}</span>
                        <div>{order.orderTime}</div>

                    </div>
                    <div className={"order-column-item"}>
                        <span>Заказчик</span>
                        <div>{order.user.name} {order.user.phone}</div>

                    </div>
                    <div className={"order-column-item"}>
                        <span>Сумма заказа</span>
                        <div>{order.orderSum} ₽</div>
                        </div>
                    <div className={"order-column-item"}>
                        <span>Оплата</span>
                        <div>{order.paymentType === "CASH" ? "Наличными" : "Картой онлайн"}</div>

                        </div>
                    <div className={"order-column-item"}>
                        <span>Статус оплаты</span>
                        <div>{order.paymentStatus === "PAID_FOR" ? "Оплачен" : "Не оплачен"}</div>

                        </div>
                    </div>


            </div>
        </NavLink>
    );
};

export default OrderItem;