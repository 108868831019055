import React, {useEffect} from 'react';
import Select from "react-select";
import {getAdminUser, updateAdminUser} from "../../components/axios/UserRequests";
import {AxiosError} from "axios";

const UserModal = ({id, showMessage, active, setActive, closeModal, user}) => {

    const [currentUser, setCurrentUser] = React.useState();
    const [userRole, setUserRole] = React.useState({});

    const options = [
        {value: "ADMIN", label: "ADMIN"},
        {value: "USER", label: "USER"},
    ]

    useEffect(() => {
        loadUser().then((resp) => setCurrentUser(resp.data));
    }, []);

    useEffect(() => {
        if (currentUser) {
            setUserRole(options.filter((o) => o.value === currentUser.role)[0])
        }
    }, [currentUser])

    function loadUser() {
        return getAdminUser(id, user.credentials).catch((err) => AxiosError(err, showMessage));
    }

    function uploadChanges() {
        if (userRole.value === currentUser.role) {
            closeModal();
        }
        else {
            currentUser.role = userRole.value;
            updateAdminUser(id, currentUser, user.credentials)
                .then((resp) => showMessage("Данные пользователя успешно обновлены"))
                .catch((err) => AxiosError(err, showMessage));
        }
    }


    return (
        <div className={"modal__content"} style={{
            padding: "10px",
            width: "320px",
            height: "auto",
            flexWrap: "wrap",
            overflow: "hidden",
            display: "flex",
            borderRadius: "5px",
            maxHeight: "auto",
            overflowY: "scroll"
        }} onClick={e => e.stopPropagation()}>
            {currentUser && <div className={"modal-control"} style={{alignContent: "flex-start", gap: "15px", justifyContent: "flex-end"}}>
                <div className={"close-btn"} onClick={() => closeModal()}>x</div>
                <div className={"user-modal-row"}>
                    <label>Имя</label>
                    <div>{currentUser.name}</div>
                </div>
                <div className={"user-modal-row"}>
                    <label>Email</label>
                    <div>{currentUser.email}</div>
                </div>
                <div className={"user-modal-row"}>
                    <label>Телефон</label>
                    <div>{currentUser.phone}</div>
                </div>
                {userRole && <div className={"user-modal-row"}>
                    <label>Роль</label>
                    <Select required
                            isSearchable={false}
                            options={options}
                            value={userRole}
                            className={"select-field"}
                            onChange={(e) => setUserRole(e)}
                    />
                </div>}
                <div className={"common-btn"} onClick={uploadChanges} style={{marginTop: "20px", marginLeft: "auto", marginRight: "auto"}}>Сохранить изменения</div>

            </div>}
        </div>
    );
};

export default UserModal;