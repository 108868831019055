import React from 'react';

const MobileFavoritesPage = () => {
    return (
        <div>

        </div>
    );
};

export default MobileFavoritesPage;