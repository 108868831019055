import axios from "axios";
import apiData from "../../helpers/ApiData";

export async function getAdminReview(id, credentials) {
    return axios({
        method: "GET",
        url: apiData.getAdminReview(id),
        headers: {"Authorization": credentials},
    });
}

export async function getAllReviews() {
    return axios({
        method: "GET",
        url: apiData.getAllReviews,
    });
}

export async function getAdminAllReviews(credentials) {
    return axios({
        method: "GET",
        url: apiData.getAdminAllReviews,
        headers: {"Authorization": credentials},
    });
}

export async function createReview(review, user) {
    return axios({
        method: "POST",
        url: apiData.createReview(user.user.id),
        headers: {"Content-Type": "multipart/form-data", "Authorization": user.credentials},
        data: review,
    });
}

export async function updateReview(id, review, user) {
    return axios({
        method: "PATCH",
        url: apiData.updateReview(id),
        headers: {"Content-Type": "application/json", "Authorization": user.credentials},
        data: review,
    });
}