import React, {useEffect, useState} from 'react';
import "./style.css";
import {AiFillHome} from "react-icons/ai";
import {FaCartShopping} from "react-icons/fa6";
import {MdAccountCircle} from "react-icons/md";
import {FaInfoCircle} from "react-icons/fa";
import {PiHeartBold} from "react-icons/pi";
import {TiThMenu} from "react-icons/ti";
import { BiSolidContact } from "react-icons/bi";

import {NavLink} from "react-router-dom";
import {aboutUsItems, catalogItems} from "../../helpers/NavItemList";
import MobileNavMenuItem from "./MobileNavMenuItem";

const MobileNavMenu = ({user, setFilter, filters, openModal, cartProducts}) => {

    const [openMenu, setOpenMenu] = useState(false);
    const [openContacts, setOpenContacts] = useState(false);

    const [tab, setTab] = useState("");

    const [rotate, setRotate] = useState(false);

    function handleAction(tab) {
        setTab(tab);
        setRotate(true);
    }

    function openAction(page) {
        setRotate(false);
        if (page === "menu") {
            if (openContacts) {
                setOpenContacts(false);
            }
            setOpenMenu(!openMenu);
        } else if (page === "contacts") {
            if (openMenu) {
                setOpenMenu(false);
            }
            setOpenContacts(!openContacts);
        }
    }

    function getFilterList(title) {
        return filters.filter((el) => title.endsWith(el.type)).sort((a, b) => {
            return a.index - b.index;
        });
    }

    useEffect(() => {
        let html = document.querySelector('html');

        if (openMenu || openContacts) {
            html.style.overflowY = 'hidden';
        } else {
            html.style.overflowY = 'scroll';
        }

        setTab("");
        setRotate(false);
    }, [openMenu, openContacts]);

    return (
        <div className={"mobile-nav-menu"}>
            <div className={"hidden-menu"} style={{
                maxHeight: openMenu ? "calc(100% - 60px)" : "0px",
                minHeight: openMenu ? "calc(100% - 60px)" : "0"
            }}>
                <div className={"hidden-menu-container"}>
                    {catalogItems.map((filter, index) => {
                        return <MobileNavMenuItem tab={tab} rotate={rotate} handleAction={handleAction}
                                                  title={filter.title} link={filter.link}
                                                  filters={getFilterList(filter.link)} key={index} setFilter={setFilter}
                                                  setOpen={setOpenMenu}/>
                    })}
                </div>
            </div>

            <div className={"hidden-menu"} style={{
                maxHeight: openContacts ? "calc(100% - 60px)" : "0px",
                minHeight: openContacts ? "calc(100% - 60px)" : "0"
            }}>
                <div className={"hidden-menu-container"}>
                    <div className={"hidden-menu-item"}>
                        <div className={"front"}>
                            <img src={"../../img/icons/workshop-mobile.png"} alt={"img"}/>
                        </div>
                        <div className={"contacts-menu-container"}>

                            {aboutUsItems.filters.map((link, index) => {
                                return <NavLink key={index} to={link.link} className={"back-nav-item"}
                                                onClick={() => setOpenContacts(false)}>
                                    {link.value.toUpperCase()}
                                </NavLink>
                            })}


                        </div>
                    </div>
                </div>
            </div>


            <div className={"nav-menu-container"}>
                <div className={"nav-menu-leftPart"}>
                    <div className={"mobile-nav-item"} onClick={() => openAction("menu")}>
                        <TiThMenu  className={"icon"}/>
                    </div>
                    <div className={"mobile-nav-item"} onClick={() => openAction("contacts")}>
                        <FaInfoCircle  className={"icon"}/>
                    </div>
                    <NavLink to={"/contacts"} className={"mobile-nav-item"} onClick={() => {
                        setOpenMenu(false);
                        setOpenContacts(false)
                    }}>
                        <BiSolidContact  className={"icon"}/>
                    </NavLink>
                </div>
                <NavLink to={"/"} className={"nav-menu-centerPart"} onClick={() => {
                    setOpenMenu(false);
                    setOpenContacts(false)
                }}>
                    <AiFillHome size={24}/>
                </NavLink>
                <div className={"nav-menu-rightPart"}>
                    <NavLink to={"/favorites"} className={"mobile-nav-item"}
                             style={{color: cartProducts.length > 0 ? "orange" : "grey"}} onClick={() => {
                        setOpenMenu(false);
                        setOpenContacts(false)
                    }}>
                        <PiHeartBold className={"icon"}/>
                    </NavLink>
                    <NavLink to={"/order"} className={"mobile-nav-item"} onClick={() => {
                        setOpenMenu(false);
                        setOpenContacts(false)
                    }}>
                        <FaCartShopping className={"icon"} style={{color: cartProducts.length > 0 ? "orange" : "grey"}}/>
                    </NavLink>


                    {!user ? <div className={"mobile-nav-item"} onClick={() => {
                        setOpenMenu(false);
                        setOpenContacts(false);
                        openModal(null, "login");
                    }}>
                        <MdAccountCircle  className={"icon"}/>
                    </div> : <NavLink to={"/personal-account"} className={"mobile-nav-item"} onClick={() => {
                        setOpenMenu(false);
                        setOpenContacts(false)
                    }}>
                        <MdAccountCircle  className={"icon"}/>
                    </NavLink>}

                </div>
            </div>
        </div>
    );
};

export default MobileNavMenu;