import React from 'react';
import {AnimatePresence} from "framer-motion";
import Items from "../components/item/Items";

const FlowerPass = (props) => {




    return (
        <div className={"main-block"}>
            {props.filter && <img className={'page-banner'} src={"../../img/banners/".concat(props.filter.img)} alt={"banner"}/>}
            <AnimatePresence mode={"wait"}>
                <Items openModal={props.openModal} isItemAddedToCart={props.isItemAddedToCart} addItemToCart={props.addItemToCart} items={props.products} user={props.user} reloadProducts={props.loadProducts}/>
            </AnimatePresence>
        </div>
    );
};

export default FlowerPass;