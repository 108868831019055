import React from 'react';
import {Map, Placemark} from '@pbe/react-yandex-maps';
import "./style.css";
import {BsInstagram, BsTelegram, BsWhatsapp} from 'react-icons/bs';
import {SlSocialVkontakte} from 'react-icons/sl';
import {useResize} from "../helpers/windowSizes/use-resize";

const Contacts = () => {

    const windowSize = useResize();

    return (
        <div className={"title-2"}>
            <div className={"contacts-info"}>
                <div className={"self"}>
                    <h3>Цветочная мастерская "Цветоводофф" в городе Самара.</h3>
                    <h6>Адрес</h6>
                    <h5>Самара, ул Демократическая, д 16 к 1</h5>
                    <h6>Работает</h6>
                    <h5>Ежедневно с 8:00 до 20:00</h5>
                    <h6>Телефон</h6>
                    <h5>+7 (927) 021-00-12 - телефон управляющего</h5>
                    <h5>+7 (917) 979-62-52 - для приема заказов</h5>
                    <h6>Наши соцсети</h6>
                    <div className={"links"}>
                        <div className={"link-item"}>
                            <BsTelegram className={"icon"} size={18}/>
                            <a href={"https://t.me/cvetovodoff_zakazy"} target="_blank" rel={"noreferrer"}>Telegram</a>
                        </div>

                        <div className={"link-item"}>
                            <BsWhatsapp className={"icon"} size={18}/>
                            <a href={"https://wa.me/79179796252"} target="_blank" rel={"noreferrer"}>WhatsApp</a>
                        </div>

                        <div className={"link-item"}>
                            <SlSocialVkontakte className={"icon"} size={18}/>
                            <a href={"https://vk.com/cvetovodoff"} target="_blank" rel={"noreferrer"}>VK</a>
                        </div>

                        <div className={"link-item"}>
                            <BsInstagram className={"icon"} size={18}/>
                            <a href={"https://www.instagram.com/cvetovodoff/"} target="_blank" rel={"noreferrer"}>Instagram</a>
                        </div>
                    </div>

                </div>
            </div>

            <Map defaultState={{
                center: [53.265014, 50.214516],
                zoom: 17,
                controls: ["zoomControl", "fullscreenControl"],
            }}
                 modules={["control.ZoomControl", "control.FullscreenControl"]}
                 width={windowSize.width < 1200 ? (windowSize.width - 20) : 1200} height={500}>
                <Placemark defaultGeometry={[53.265014, 50.214516]}/>
            </Map>
        </div>
    );
};

export default Contacts;