import React, {useEffect, useState} from 'react';
import {useResize} from "../../helpers/windowSizes/use-resize";
import {convertToMoneyFormat, getDiscount, getMinPrice, getPriceBySize} from "../../helpers/Utils";
import {NavLink} from "react-router-dom";
import apiData from "../../helpers/ApiData";
import "./newItem.css";
import {TbDiscount2, TbDiscountCheck} from "react-icons/tb";
import {FaCartArrowDown, FaCartPlus} from "react-icons/fa";
import {PiHeartBold} from "react-icons/pi";

const NewItem = ({product, addItemToCart, isItemAddedToCart}) => {

    const [price, setPrice] = useState({});

    const [currentSize, setCurrentSize] = useState("");

    const windowSize = useResize();

    const iconSize = windowSize.isScreenL ? 16 : 14;

    const [isLoad, setIsload] = useState(false);

    const [previewImage,setPreviewImage] = useState();

    function putItemToProducts(type) {
        addItemToCart(product, price, type);
    }

    const loadImage = async () => {
        await apiData.getAttachment(product.attachments[0].downloadLink)
    }

    useEffect(() => {
        setPrice(getPriceBySize(product.prices, currentSize));
    }, [currentSize]);

    useEffect(() => {
        if (product) {
            let p = getMinPrice(product.prices);
            setPrice(p);
            setCurrentSize(p.size);
            setPreviewImage(apiData.getAttachment(product.attachments[0].downloadLink));
        }
    }, [product]);


    return (
        <div className={"item"} style={{visibility: isLoad ? "visible" : "hidden"}}>
            <NavLink className={"img-box"} to={`/product/${product.id}`}>
                <img onLoad={() => setIsload(true)}
                 src={previewImage}
                 alt={product.name} onError={(error) => setPreviewImage("http://placehold.it/1000x1000")} loading={"lazy"}/>

                <div className={product.isAvailable ? "in-stock" : "out-stock"}>
                    <TbDiscountCheck size={14}/>
                    <div>{product.isAvailable ? "В наличии" : "Доступен к заказу"}</div>
                </div>
                <div className={"size"}>
                    <div>size: {price.size}</div>
                </div>
            </NavLink>


            <div className={"item-info"}>
                <div className={"price"}>
                    <div style={{display: "flex", flexWrap: "wrap", alignItems: "center", columnGap: "5px"}}>
                        <div
                            style={{color: price.oldPrice > 0 ? "#dc0b0b" : "rgba(58,58,58,0.86)"}}>{convertToMoneyFormat(price.currentPrice)} ₽
                        </div>
                        {price.oldPrice > 0 && <div style={{
                            color: "grey",
                            textDecoration: "line-through",
                            opacity: "0.6",
                            fontWeight: "600"
                        }}>{convertToMoneyFormat(price.oldPrice)} ₽</div>}
                    </div>
                    {price.oldPrice > 0 && <div className={"discount"}>
                        <TbDiscount2 size={14}/>
                        -{getDiscount(price.oldPrice, price.currentPrice)}%
                    </div>}
                </div>
                <NavLink to={`/product/${product.id}`} className={"title"}>
                    {product.name}
                </NavLink>


                <div style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "10px",
                    alignItems: "center"
                }}>
                    <div
                        className={isItemAddedToCart(product.id, price.size, "cart") ? "add-to-cart active" : "add-to-cart"}
                        onClick={() => putItemToProducts("cart")}>
                        {isItemAddedToCart(product.id, price.size, "cart") ?
                            <div style={{display: "flex", alignContent: "center", gap: "10px"}}><FaCartArrowDown
                                size={iconSize}/> В корзине</div> :
                            <div style={{display: "flex", alignContent: "center", gap: "10px"}}><FaCartPlus
                                size={iconSize}/> Купить</div>}
                    </div>
                    <div className={"favorite-btn"} onClick={() => putItemToProducts("favorite")}>
                        {isItemAddedToCart(product.id, price.size, "favorite") ?
                            <PiHeartBold size={18} style={{color: "rgba(218,0,0,0.87)"}}/> : <PiHeartBold size={18}/>}
                    </div>
                </div>

            </div>
        </div>
    );

}

export default NewItem;