import React from 'react';
import "./style.css";
import {BsFillCursorFill, BsFillTelephoneFill, BsInstagram, BsTelegram, BsWhatsapp} from "react-icons/bs";
import {NavLink} from "react-router-dom";
import {SlSocialVkontakte} from "react-icons/sl";
import {useResize} from "../../helpers/windowSizes/use-resize";

const Footer = () => {

    const windowSize = useResize().width;

    return (
        <footer>
            <div className={"info-block"}>
                <div className={"logo-block"}>
                    {/*<div className={"footer-logo"}>Цветоводофф</div>

                    <div className={"text"}>- Всегда свежие цветы</div>
                    <div className={"text"}>- Горшечные растения на любой вкус</div>
                    <div className={"text"}>- Украшения и подарки</div>
                    <div className={"text"}>- Доступные цены</div>*/}

                    <img src={"../../img/icons/mobile-footer-logo.png"}/>

                </div>
                {windowSize >= 1100 && <div className={"items"}>
                    <div className={"column"}>
                        <NavLink className={"link"} to={"/personal-account"} onClick={() => window.scrollTo(0,0)}>Аккаунт</NavLink>
                        <NavLink className={"link"} to={"/contacts"} onClick={() => window.scrollTo(0,0)}>Контакты</NavLink>
                        <NavLink className={"link"} to={"/for-corporate-clients"} onClick={() => window.scrollTo(0,0)}>Компаниям</NavLink>
                        <NavLink className={"link"} to={"/loyalty-info"} onClick={() => window.scrollTo(0,0)}>Бонусная система</NavLink>
                        <NavLink className={"link"} to={"/flower-pass"} onClick={() => window.scrollTo(0,0)}>Цветочная подписка</NavLink>
                    </div>
                    <div className={"column"}>
                        <NavLink className={"link"} to={"/about-us"} onClick={() => window.scrollTo(0,0)}>О нас</NavLink>
                        <NavLink className={"link"} to={"/payment-delivery"} onClick={() => window.scrollTo(0,0)}>Доставка и оплата</NavLink>
                        <NavLink className={"link"} to={"/warranty-page"} onClick={() => window.scrollTo(0,0)}>Гарантия и возврат</NavLink>
                        <NavLink className={"link"} to={"/reviews"} onClick={() => window.scrollTo(0,0)}>Отзывы покупателей</NavLink>
                        <NavLink className={"link"} to={"/privacy-policy"} onClick={() => window.scrollTo(0,0)}>Политика конфиденциальности</NavLink>
                    </div>
                </div>}

                <div className={"phones-block"}>
                    <div className={"line-block"}>
                        <BsFillCursorFill className={"icon"}/>
                        <div className={"text"}>Самара, ул.Демократическая, 16к1</div>
                    </div>

                    <div className={"line-block"}>
                        <BsFillTelephoneFill  className={"icon"}/>
                        <div className={"text"}>+7 927 021-00-12 - общий</div>
                    </div>
                    <div className={"line-block"}>
                        <BsFillTelephoneFill className={"icon"}/>
                        <div className={"text"} >+7 917 979-62-52 - прием заказов</div>
                    </div>

                    <div className={"text"} style={{fontSize: "16px", margin: "20px 0 10px 0"}}>Наши соцсети: </div>
                    <div className={"links"}>
                            <a href={"https://t.me/cvetovodoff_zakazy"} target="_blank" rel={"noreferrer"}><BsTelegram className={"icon"}/></a>
                            <a href={"https://wa.me/79179796252"} target="_blank" rel={"noreferrer"}><BsWhatsapp className={"icon"} /></a>
                            <a href={"https://vk.com/cvetovodoff"} target="_blank" rel={"noreferrer"}><SlSocialVkontakte className={"icon"} /></a>
                            <a href={"https://www.instagram.com/cvetovodoff/"} target="_blank" rel={"noreferrer"}><BsInstagram className={"icon"} /></a>
                    </div>
                    <div className={"links"} style={{marginTop: "10px"}}>
                        <img src={"../../img/icons/visa.svg"}/>
                        <img src={"../../img/icons/mastercard.svg"}/>
                        <img src={"../../img/icons/mir.svg"}/>
                        <img src={"../../img/icons/sbp.png"}/>
                        <img src={"../../img/icons/sber.png"}/>
                    </div>
                </div>
            </div>

        </footer>
    );
};

export default Footer;