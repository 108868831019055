import React from 'react';
import {BsFillCursorFill, BsFillTelephoneFill, BsInstagram, BsTelegram, BsWhatsapp} from "react-icons/bs";
import {SlSocialVkontakte} from "react-icons/sl";
import "./style.css"

const MobileFooter = () => {


    const copyTextToClipboard = async (text) => {
            if (navigator.clipboard) {
                await navigator.clipboard.writeText(text).then(() => {
                    alert('Text copied to clipboard');
                }).catch(err => {
                    console.log(err);
                });
            }
    };

    return (
        <div className={"mobile-footer"}>
            <div className={"logo"}>
                <img src={"../../img/icons/mobile-footer-logo.png"} alt={"footer-image"}/>
            </div>
            <div className={"phones-block"}>
                <div className={"line-block"} onClick={() => copyTextToClipboard('Самара, ул.Демократическая, 16к1')}>
                    <BsFillCursorFill className={"icon"} size={14}/>
                    <div className={"text"}>Самара, ул.Демократическая, 16к1</div>
                </div>

                <div className={"line-block"} onClick={() => copyTextToClipboard('+7 927 021-00-12')}>
                    <BsFillTelephoneFill className={"icon"} size={14}/>
                    <div className={"text"}>+7 927 021-00-12 - общий</div>
                </div>
                <div className={"line-block"} onClick={() => copyTextToClipboard('+7 917 979-62-52')}>
                    <BsFillTelephoneFill className={"icon"} size={14}/>
                    <div className={"text"}>+7 917 979-62-52 - прием заказов</div>
                </div>

                <div className={"text"} style={{fontSize: "14px", margin: "20px 0 10px 0"}}>Наши соцсети:</div>
                <div className={"links"}>
                    <a href={"https://t.me/cvetovodoff_zakazy"} target="_blank" rel={"noreferrer"}><BsTelegram
                        className={"icon"}/></a>
                    <a href={"https://wa.me/79179796252"} target="_blank" rel={"noreferrer"}><BsWhatsapp
                        className={"icon"}/></a>
                    <a href={"https://vk.com/cvetovodoff"} target="_blank" rel={"noreferrer"}><SlSocialVkontakte
                        className={"icon"}/></a>
                    <a href={"https://www.instagram.com/cvetovodoff/"} target="_blank" rel={"noreferrer"}><BsInstagram
                        className={"icon"}/></a>
                </div>
                <div className={"links"} style={{marginTop: "10px"}}>
                    <img src={"../../img/icons/visa.svg"}/>
                    <img src={"../../img/icons/mastercard.svg"}/>
                    <img src={"../../img/icons/mir.svg"}/>
                    <img src={"../../img/icons/sbp.png"}/>
                    <img src={"../../img/icons/sber.png"}/>
                </div>
            </div>
        </div>
    );
};

export default MobileFooter;