import React, {useState} from 'react';
import {uploadBanner} from "../../../components/axios/SiteDataRequests";
import AxiosError from "../../../components/axios/AxiosError";
import SiteDataItem from "./SiteDataItem";

const AdminSettings = ({user, siteData, showMessage}) => {

    const [banner, setBanner] = useState();

    async function upload() {
        const formData = new FormData();
        formData.append("file", banner);
        await uploadBanner(formData, user.credentials).then((resp) => {
            console.log("Загружено успешно")
        }).catch((err) => AxiosError(err, showMessage))
    }

    return (
        <div className={"common-page"}>
            {siteData.map((d) => {
                return <SiteDataItem user={user} data={d} showMessage={showMessage}/>
            })}
        </div>
    );
};

export default AdminSettings;