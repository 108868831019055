

export default async function AxiosError(error, showMessage) {
    if (error.response) {
        // Запрос был сделан, и сервер ответил кодом состояния, который
        // выходит за пределы 2xx
        if (error.response.status === 401) {
            showMessage("Авторазицая не выполнена, проверьте правильность вводимых данных")
        }
        else {
            showMessage(error.response.data.message)
        }
    } else if (error.request) {
        // Запрос был сделан, но ответ не получен
        // `error.request`- это экземпляр XMLHttpRequest в браузере и экземпляр
        // http.ClientRequest в node.js
        showMessage("В данный момент сервер не отвечает, попробуйте еще раз чуть позже.")
    }
    else {
        // Произошло что-то при настройке запроса, вызвавшее ошибку
        console.log('Error', error);
    }
}