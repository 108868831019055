import React from 'react';
import "./about.css";
import {BsCheck2Square} from "react-icons/bs";

const PaymentDelivery = () => {
    return (
        <div className={"page-block"}>
            <img src={"../../img/banners/payment-delivery-page.png"}/>
            <div className={"data-block"}>
                <div className={"about-item"}
                     style={{width: "100%", maxWidth: "1200px", display: "flex", flexWrap: "wrap", gap: "15px"}}>
                    <div className={"about-item-title"} style={{width: "100%"}}>
                        <BsCheck2Square className={"about-item-icon"} size={25}/>
                        <div>Доставка для адресов, удаленность которых составляет менее 10км от мастерской
                            "Цветоводофф"
                        </div>
                    </div>
                    <div className={"text-block"} style={{maxWidth: "250px"}}>
                        <img src={"../../img/icons/8.png"}/>
                        <div>Доставка осуществляется в течение часа от указанного вами времени. Рабочие часы службы доставки с 8:00-21:00</div>
                    </div>
                    <div className={"text-block"} style={{maxWidth: "250px"}}>
                        <img src={"../../img/icons/9.png"}/>
                        <div >Доставка осуществляется курьерской службой только в пределах города Самара.</div>
                    </div>
                    <div className={"text-block"} style={{maxWidth: "250px"}}>
                        <img src={"../../img/icons/7.png"}/>
                        <div >Бесплатная доставка действует при заказе от 5000р.</div>
                    </div>
                    <div className={"text-block"} style={{maxWidth: "250px"}}>
                        <img src={"../../img/icons/6.png"}/>
                        <div >Стоимость доставки в пределах 10км - 350р.</div>
                    </div>
                </div>
                <div className={"about-item"} style={{width: "100%", maxWidth: "1200px"}}>
                    <div className={"about-item-title"}>
                        <BsCheck2Square className={"about-item-icon"} size={25}/>
                        <div>Доставка для адресов, удаленность которых составляет более 10км от мастерской
                            "Цветоводофф"
                        </div>
                    </div>
                    <div className={"text-block"} >
                        <div style={{marginTop: "10px", marginLeft: "3%"}}>Стоимость согласовывается сразу после заказа и составляет не менее 500р</div>
                    </div>
                </div>
                <div className={"about-item"} style={{width: "100%", maxWidth: "1200px"}}>
                    <div className={"about-item-title"}>
                        <BsCheck2Square className={"about-item-icon"} size={25}/>
                        <div>Доставка в праздничные дни и ночная доставка с 21:00-9:00
                        </div>
                    </div>
                    <div className={"text-block"} >
                        <div style={{ marginTop: "10px", marginLeft: "3%"}}>Стоимость согласовывается сразу после заказа и доплата составляет не менее 150р</div>
                    </div>
                </div>

                <div className={"about-item"}
                     style={{width: "100%", maxWidth: "1200px", display: "flex", flexWrap: "wrap", gap: "15px"}}>
                    <div className={"about-item-title"} style={{width: "100%"}}>
                        <BsCheck2Square className={"about-item-icon"} size={25}/>
                        <div>Оплата заказа с доставкой
                        </div>
                    </div>
                    <div className={"text-block"} style={{maxWidth: "300px", display: "flex"}}>
                        <img src={"../../img/icons/5.png"}/>
                        <div>Мы отправляем букеты только при 100% предоплате. Поэтому, для вашего удобства мы сделали возможность оплаты на сайте российскими картами. Если, при заказе, вы выбираете оплату “при получении”, то менеджер до отправки букета попросит вас внести 100% предоплату по ссылке или QR-коду на наш р\с.</div>
                    </div>
                    <div className={"text-block"} style={{maxWidth: "300px", display: "flex"}}>
                        <img src={"../../img/icons/5.png"}/>
                        <div>Если вы оформляете заказ как Юридическое Лицо и хотите оплатить его безналичным способом — свяжитесь с менеджером нашего салона любым удобным вам способом: Telegram, Whatsapp, мобильная связь.</div>
                    </div>
                </div>
                <div className={"about-item"} style={{width: "100%", maxWidth: "1200px"}}>
                    <div className={"about-item-title"}>
                        <BsCheck2Square className={"about-item-icon"} size={25}/>
                        <div>Оплата заказа самовывозом
                        </div>
                    </div>
                    <div className={"text-block"} style={{maxWidth: "300px", display: "flex"}}>
                        <img src={"../../img/icons/5.png"}/>
                        <div>Для вашего удобства мы сделали возможность оплаты на сайте российскими картами. Если при заказе вы выбираете оплату “при получении”, менеджер после оформления заказа попросит вас внести 20-30% предоплату по ссылке или QR-коду на наш р\с.</div>
                    </div>
                </div>
                <div className={"about-item"} style={{width: "100%", maxWidth: "1200px"}}>
                    <div className={"about-item-title"}>
                        <BsCheck2Square className={"about-item-icon"} size={25}/>
                        <div>Мы принимаем к оплате
                        </div>
                    </div>
                    <div className={"text-block"} style={{
                        textAlign: "center",
                        display: "flex",
                        flexWrap: "wrap",
                        fontWeight: "600",
                        maxWidth: "1200px",
                        justifyContent: "space-around"
                    }}>
                        <div>
                            <img src={"../../img/icons/4.png"}/>
                            <div>НАЛИЧНЫЕ</div>
                        </div>
                        <div>
                            <img src={"../../img/icons/3.png"}/>
                            <div>VISA RF MASTERCARD RF MIR</div>
                        </div>
                        <div>
                            <img src={"../../img/icons/2.png"}/>
                            <div>YANDEX MONEY QIWI WEBMONEY PAYPAL</div>
                        </div>
                        <div>
                            <img src={"../../img/icons/1.png"}/>
                            <div>ДЛЯ ЮР ЛИЦ ОПЛАТА НА Р/С</div>
                        </div>

                    </div>
                </div>
                <div className={"about-item"} style={{width: "100%", maxWidth: "1200px"}}>
                    <div className={"about-item-title"}>
                        <BsCheck2Square className={"about-item-icon"} size={25}/>
                        <div>Наши реквизиты</div>
                    </div>
                    <div className={"text-block"}>
                        <img style={{marginTop: "25px", marginLeft: "10px"}} src={"../../img/icons/card-info.png"}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentDelivery;