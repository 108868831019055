const categoriesList = [
    {value: "BOUQUETS", label: "Букеты"},
    {value: "WEDDING_BOUQUETS", label: "Букеты для свадьбы"},
    {value: "FIELD_BOUQUETS", label: "Полевые букеты"},
    {value: "FLOWER_BOXES", label: "Цветочные боксы"},
    {value: "VASE_FLOWERS", label: "Цветы для вазы"},

    {value: "DRY_FLOWERS", label: "Сухоцветы"},
    {value: "ROSES", label: "Розы"},
    {value: "PEONIES", label: "Пионы"},


    { value: "BIRTHDAY", label: "День рождения"},
    { value: "MOTHER_DAY", label: "День матери"},
    { value: "VALENTIN_DAY", label: "День влюбленных"},
    {value: "TEACHER_DAY", label: "День учителя"},
    {value: "SCHOOL_OUTLET", label: "Выпускной"},
    {value: "SEPTEMBER_1ST", label: "1 сентября"},
    {value: "WOMEN_DAY", label: "8 марта"},
    { value: "NEW_YEAR", label: "Новый год"},
    {value: "EASTER", label: "Пасха"},
    {value: "GIFTS_AND_POSTCARDS", label: "Подарки и открытки"},
    {value: "BALLS_AND_TOYS", label: "Шары и игрушки"},

    {value: "INDOOR_PLANTS", label: "Комнатные растения"},
    {value: "OUTDOOR_PLANTS", label: "Уличные растения"},
    {value: "CARE_PRODUCTS", label: "Средства для ухода"},
    {value: "KASHPO", label: "Кашпо"},
    {value: "VASES", label: "Вазы"},
    {value: "FOR_HOME", label: "Для дома"},
    {value: "CUSTOM_1", label: "Новая 1"},
    {value: "CUSTOM_2", label: "Новая 2"},
    {value: "CUSTOM_3", label: "Новая 3"}
]

const flowersAndGiftsCategories = [
    {value: "BOUQUETS", label: "Букеты"},
    {value: "WEDDING_BOUQUETS", label: "Букеты для свадьбы"},
    {value: "FIELD_BOUQUETS", label: "Полевые букеты"},
    {value: "FLOWER_BOXES", label: "Цветочные боксы"},
    {value: "VASE_FLOWERS", label: "Цветы для вазы"},
    { value: "DRY_FLOWERS", label: "Сухоцветы"},
    {value: "ROSES", label: "Розы"},
    {value: "PEONIES", label: "Пионы"},
    {value: "BIRTHDAY", label: "День рождения"},
    {value: "MOTHER_DAY", label: "День матери"},
    {value: "VALENTIN_DAY", label: "День влюбленных"},
    {value: "TEACHER_DAY", label: "День учителя"},
    {value: "SCHOOL_OUTLET", label: "Выпускной"},
    {value: "SEPTEMBER_1ST", label: "1 сентября"},
    {value: "WOMEN_DAY", label: "8 марта"},
    {value: "NEW_YEAR", label: "Новый год"},
    {value: "EASTER", label: "Пасха"},
    {value: "GIFTS_AND_POSTCARDS", label: "Подарки и открытки"},
    {value: "BALLS_AND_TOYS", label: "Шары и игрушки"},
    {value: "CUSTOM_1", label: "Новая 1"},
    {value: "CUSTOM_2", label: "Новая 2"},
    {value: "CUSTOM_3", label: "Новая 3"}
]

const pottedPlantsCategories = [
    { value: "INDOOR_PLANTS", label: "Комнатные растения"},
    { value: "OUTDOOR_PLANTS", label: "Уличные растения"},
    { value: "CARE_PRODUCTS", label: "Средства для ухода"},
    {value: "CUSTOM_1", label: "Новая 1"},
    {value: "CUSTOM_2", label: "Новая 2"},
    {value: "CUSTOM_3", label: "Новая 3"}
]

const decorCategories = [
    { value: "KASHPO", label: "Кашпо"},
    { value: "VASES", label: "Вазы"},
    {value: "FOR_HOME", label: "Для дома"},
    {value: "CUSTOM_1", label: "Новая 1"},
    {value: "CUSTOM_2", label: "Новая 2"},
    {value: "CUSTOM_3", label: "Новая 3"}
]

const flowerPassCategories = []

const mainCategories = [
    {value: "FLOWER_AND_GIFTS", label: "Цветы и подарки"},
    {value: "DECOR", label: "Декор"},
    {value: "POTTED_PLANTS", label: "Горшечные растения"},
    {value: "FLOWER_PASS", label: "Цветочная подписка"}
]

const pageList = [
    { value: "flowers", label: "Цветы и подарки" },
    { value: "plants", label: "Горшечные растения" },
    { value: "decor", label: "Декор" },
    { value: "flower-pass", label: "Цветочная подписка"},
]

const allCategories = [
    {value: "FLOWER_AND_GIFTS", label: "Цветы и подарки"},
    {value: "DECOR", label: "Декор"},
    {value: "POTTED_PLANTS", label: "Горшечные растения"},
    {value: "FLOWER_PASS", label: "Цветочная подписка"},
    {value: "BOUQUETS", label: "Букеты"},
    {value: "WEDDING_BOUQUETS", label: "Букеты для свадьбы"},
    {value: "FIELD_BOUQUETS", label: "Полевые букеты"},
    {value: "FLOWER_BOXES", label: "Цветочные боксы"},
    {value: "VASE_FLOWERS", label: "Цветы для вазы"},

    {value: "DRY_FLOWERS", label: "Сухоцветы"},
    {value: "ROSES", label: "Розы"},
    {value: "PEONIES", label: "Пионы"},


    {value: "BIRTHDAY", label: "День рождения"},
    {value: "MOTHER_DAY", label: "День матери"},
    {value: "VALENTIN_DAY", label: "День влюбленных"},
    {value: "TEACHER_DAY", label: "День учителя"},
    {value: "SCHOOL_OUTLET", label: "Выпускной"},
    {value: "SEPTEMBER_1ST", label: "1 сентября"},
    {value: "WOMEN_DAY", label: "8 марта"},
    {value: "NEW_YEAR", label: "Новый год"},
    {value: "EASTER", label: "Пасха"},
    {value: "GIFTS_AND_POSTCARDS", label: "Подарки и открытки"},
    {value: "BALLS_AND_TOYS", label: "Шары и игрушки"},

    {value: "INDOOR_PLANTS", label: "Комнатные растения"},
    {value: "OUTDOOR_PLANTS", label: "Уличные растения"},
    {value: "CARE_PRODUCTS", label: "Средства для ухода"},
    {value: "KASHPO", label: "Кашпо"},
    {value: "VASES", label: "Вазы"},
    {value: "FOR_HOME", label: "Для дома"},


    {value: "CUSTOM_1", label: "Новая 1"},
    {value: "CUSTOM_2", label: "Новая 2"},
    {value: "CUSTOM_3", label: "Новая 3"}
]

export {
    categoriesList,
    allCategories,
    pageList,
    mainCategories,
    flowersAndGiftsCategories,
    decorCategories,
    pottedPlantsCategories,
    flowerPassCategories
}

