import React from 'react';
import {MdAccountCircle, MdNoAccounts} from "react-icons/md";
import {PiHeartBold} from "react-icons/pi";
import {FaCartShopping} from "react-icons/fa6";


const MenuItem = ({name, cartLength}) => {
    function getIcon() {

        if (name === "Корзина") return <FaCartShopping size={22} className={"menu-item-icon"} style={{color: cartLength > 0 ? "var(--color-3)" : "#434343"}}/>;

        if (name === "Войти" || name === "Кабинет") return <MdAccountCircle size={22} className={"menu-item-icon"}/>

        if (name === "Выйти") return <MdNoAccounts size={22} className={"menu-item-icon"}/>
        if (name === "Избранное") return <PiHeartBold size={22}
                                                      style={{color: cartLength > 0 ? "rgba(232,2,2,0.93)" : "#818181"}}
                                                      className={"menu-item-icon"}/>
    }


    return (
        <div style={{
            display:"grid",
            gridColumn: "1",
            rowGap: "1px",
        }}>
            {getIcon()}
            <div className={"icon-label"}>{name}</div>
        </div>
    );
};

export default MenuItem;