import React from 'react';
import "./about.css";
import {NavLink} from "react-router-dom";

const LoyaltyInfo = () => {
    return (
        <div className={"page-block"}>
            <img src={"../../img/banners/bonus-page.png"}/>
            <div style={{
                width: "100%",
                textAlign: "center",
                fontSize: "20px",
                margin: "20px 0 10px",
                fontWeight: "600"
            }}>Как работает бонусная программа Цветоводофф?
            </div>
            <div className={"data-block"}>
                <div className={"icons-block"}>
                    <div className={"icons-block-item"}>
                            <img src={"../../img/icons/b1.png"}/>
                        <div className={"title"}>Регистрируйтесь
                            </div>
                        <NavLink to={"/register"} className={"icons-block-item-btn"}>Регистрация</NavLink>
                        </div>
                    <div className={"icons-block-item"}>
                            <img src={"../../img/icons/b2.png"}/>
                        <div className={"title"}>Заказывайте</div>
                        <div className={"text-block"}>Делайте заказы, находясь на сайте с
                                активным личным кабинетом
                            </div>
                        </div>
                    <div className={"icons-block-item"}>
                            <img src={"../../img/icons/b3.png"}/>
                        <div className={"title"}>Накапливайте бонусы
                            </div>
                        <div className={"text-block"}>Получайте бонусы за любые покупки на
                                сайте Цветоводофф
                            </div>
                        </div>
                    <div className={"icons-block-item"}>
                            <img src={"../../img/icons/b4.png"}/>
                        <div className={"title"}>Расплачивайтесь
                                бонусами
                            </div>
                        <div className={"text-block"}>Оплачивайте до 20% стоимости заказа
                                Бонусными рублями
                            </div>
                    </div>
                </div>
                <div className={"about-item"} style={{marginTop: "20px"}}>
                    <div className={"text"} style={{fontSize: "14px", fontWeight: "500", lineHeight: "22px"}}>
                        <p>1. Вы получаете накопительный бонус 3%-5%-7% от всех покупок, сделанных на нашем сайте. Вы
                            сможете оплатить бонусами до 20% стоимости товара бонусными рублями.</p>
                        <p>2. Новый клиент - 3% от покупки;</p>
                        <p>   Постоянный клиент - 5% от покупки, порог входа 10000р.;</p>
                        <p>   Постоянный клиент - 7%, порог входа 20000р.</p>
                        <p>3. 1 бонус = 1 рубль.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoyaltyInfo;