import React, {useEffect, useState} from 'react';
import apiData from "../../helpers/ApiData";
import {NavLink} from "react-router-dom";
import {useResize} from "../../helpers/windowSizes/use-resize";
import {FaMinus, FaPlus, FaTrashAlt} from "react-icons/fa";
import {convertToMoneyFormat} from "../../helpers/Utils";


const CartItem = ({product, products, setProducts, totalSum, setTotalSum}) => {

    const [count, setCount] = useState(product.quantity);
    const [sum, setSum] = useState(product.totalSum);
    const windowSize = useResize();

    useEffect(() => {
        if (product) {
            product.totalSum = product.price.currentPrice * count;
            setSum(product.totalSum);
            product.quantity = count;
        }
    }, [count])

    useEffect(() => {
        setCount(product.quantity);
        setSum(product.totalSum);
    }, [products, product])


    function removeProduct() {
        setProducts(products.filter((el) => el.item.id !== product.item.id));
    }
    function increaseCount() {
        setCount(count + 1);
        setTotalSum(totalSum + product.price.currentPrice);
    }

    function decreaseCount() {
        if (count > 1) {
            setCount(count-1);
            setTotalSum(totalSum - product.price.currentPrice);
        }
    }

    return (
        <>
            {windowSize.width > 768 &&  <div className={"cart-item"}>
                <img src={apiData.getAttachment(product.item.attachments[0].downloadLink)} alt={"img"}/>
                <div className={"delete-btn"}><FaTrashAlt size={22} onClick={() => removeProduct()}/></div>
                <div className="cart-item-info">
                    <NavLink className={"cart-item-title"} to={`/product/${product.item.id}`}>{product.item.name}</NavLink>

                    <div>
                        <div className={"cart-item-ready"} style={{marginTop: "10px"}}>{product.item.isAvailable ? "В наличии" : "Нет в наличии"}</div>
                        <div className={"cart-item-ready"}>Время подготовки товара {product.item.readyTime} ч.</div>
                        <div className={"cart-item-ready"}>размер букета: <span style={{fontSize: "14px", marginLeft: "5px", fontWeight: "700"}}>{product.price.size}</span></div>
                    </div>

                    <div className={"quantity"}>
                        <button className={"quantity-btn"} onClick={() => decreaseCount()}><FaMinus/></button>
                        {count}
                        <button className={"quantity-btn"} onClick={() => increaseCount()}><FaPlus/></button>
                    </div>
                    <div>
                        <div className={"price"}>{convertToMoneyFormat(sum)} ₽</div>
                        {product.price.oldPrice > 0 && <div style={{fontSize: "18px", color: "grey", textDecoration: "line-through"}}>{product.price.oldPrice * count} ₽</div>}
                    </div>
                </div>

            </div>}
            {windowSize.width < 768 &&  <div className={"cart-item"}>
                <img src={apiData.getAttachment(product.item.attachments[0].downloadLink)} alt={"img"}/>
                <div className="cart-item-info">
                    <NavLink className={"cart-item-title"} to={`/product/${product.item.id}`}>{product.item.name}</NavLink>
                    <div className={"cart-item-ready"}>{product.item.isAvailable ? "В наличии" : "Нет в наличии"}</div>
                    <div className={"cart-item-ready"}>Время подготовки товара {product.item.readyTime} ч.</div>
                    <div className={"cart-item-ready"}>размер букета: <span style={{
                        fontSize: "12px",
                        marginLeft: "5px",
                        fontWeight: "600",
                        color: "var(--color-2)"
                    }}>{product.price.size}</span></div>
                    <div className={"cart-item-ready"}>
                        Количество
                        <button className={"quantity-btn"} onClick={() => decreaseCount()}>-</button>
                        {count}
                        <button className={"quantity-btn"} onClick={() => increaseCount()}>+</button>
                    </div>
                    <div className={"price"}>{sum} ₽</div>
                    <div><FaTrashAlt className={"delete-btn"} onClick={() => removeProduct()}/></div>

                </div>
            </div>}
        </>

    );
};

export default CartItem;