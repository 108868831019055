import React from 'react';
import "./about.css";

import {BsCheck2Square} from "react-icons/bs";

const WarrantyInfo = () => {
    return (
        <div className={"page-block"}>
            <img src={"../../img/banners/warranty-page.png"}/>
            <div className={"data-block"}>
                <div className={"about-item"}>
                    <div className={"about-item-title"}>
                        <BsCheck2Square size={25} className={"about-item-icon"}/>
                        <div>Соблюдение временных сроков</div>
                    </div>
                    <div className={"text-block"} >
                        <div >При оформлении заказа учитывайте, что в праздничные дни и часы-пик курьеры могут привезти заказ в течение часа от указанного в заказе времени. Мы понимаем, как важна своевременность в такие важные дни и будем стараться доставить заказ четко ко времени или в указанный временной диапазон.</div>
                    </div>
                </div>
                <div className={"about-item"}>
                    <div className={"about-item-title"}>
                        <BsCheck2Square size={25} className={"about-item-icon"}/>
                        <div>Качество цветов и оформление букета</div>
                    </div>
                    <div className={"text-block"} >
                        <div >Вам необходимы свежие красивые цветы в подарочном оформлении. Мы понимаем это и предоставляем вам гарантию 24 часа на замену букета, в случае преждевременного увядания цветов, при условии соблюдения инструкций по уходу.</div>
                    </div>
                </div>
                <div className={"about-item"}>
                    <div className={"about-item-title"}>
                        <BsCheck2Square size={25} className={"about-item-icon"}/>
                        <div>Соблюдение условий заказа</div>
                    </div>
                    <div className={"text-block"} >
                        <div >Мы понимаем, как важны детали в составлении букетов, ведь вы дарите эмоции. Наши флористы оставляют за собой право вносить небольшие дизайнерские нюансы, составляя ваш букет, которые существенно не повлияют на стиль, форму, цветовое решение. и ценность букета.</div>
                    </div>
                </div>
                <div className={"about-item"}>
                    <div className={"about-item-title"}>
                        <BsCheck2Square size={25} className={"about-item-icon"}/>
                        <div>Конфиденциальность данных</div>
                    </div>
                    <div className={"text-block"} >
                        <div >В процессе оформления доставки вы оставляете контактные данные своих близких людей и заботитесь о их сохранности. Указанные адреса и телефоны не передаются третьим лицам, только сотрудникам мастерской и курьерской службе.
                        </div>
                    </div>
                </div>
                <div className={"about-item"}>
                    <div className={"about-item-title"}>
                        <BsCheck2Square size={25} className={"about-item-icon"}/>
                        <div>Безопасность проведения платежей</div>
                    </div>
                    <div className={"text-block"} >
                        <div>Все транзакции проводятся в зашифрованном виде через личные безопасные серверы платежных систем. Это значит, что данные по кредитной карте находится в скрытом виде и не доступны посторонним лицам.</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WarrantyInfo;