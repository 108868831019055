import { useState, useEffect } from 'react';
import {
    SCREEN_XS, SCREEN_S, SCREEN_M, SCREEN_L, SCREEN_XL, SCREEN_XXL,
} from './const-breakpoints';

export const useResize = () => {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = (event) => {
            setWidth(event.target.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return {
        width,
        isScreenXS: width >= SCREEN_XS,
        isScreenS: width >= SCREEN_S,
        isScreenM: width >= SCREEN_M,
        isScreenL: width >= SCREEN_L,
        isScreenXl: width >= SCREEN_XL,
        isScreenXXl: width >= SCREEN_XXL,
    };
};

