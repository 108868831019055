import React, {useState} from 'react';
import {updateSiteData} from "../../../components/axios/SiteDataRequests";
import AxiosError from "../../../components/axios/AxiosError";

const SiteDataItem = ({user, data, showMessage}) => {

    const [val, setVal] = useState(data.value);

    async function saveChanges() {
        let newData = {
            id: data.id,
            key: data.key,
            value: val
        }

        await updateSiteData(newData, data.id, user.credentials).then((resp) => showMessage("Изменения успешно сохранены"))
            .catch((err) => AxiosError(err, showMessage));
    }


    return (
        <div className={"common-page"}>
            <div>{data.key}</div>
            <input type={"text"} value={val} onChange={(event) => setVal(event.currentTarget.value)}/>
            <button onClick={() => saveChanges()}>Сохранить</button>
        </div>
    );
};

export default SiteDataItem;