import React from 'react';

const AdminUser = ({user, index, showMessage, openModal, closeModal}) => {



    return (
        <div className={"admin-user-item"} style={{backgroundColor: index % 2 === 0 ? "#f4f4f4" : "#ebebeb"}}
             onClick={() => openModal(user.id, "user")}>
                <div className={"val"}>{user.name}</div>
                <div className={"val"}>{user.email}</div>
                <div className={"val"}>{user.phone}</div>
                <div className={"val"}>{user.role}</div>
        </div>
    );
};

export default AdminUser;