import React from 'react';
import "./banners.css"
import {useResize} from "../../helpers/windowSizes/use-resize";

const CreateBouquet = ({openModal}) => {

    const windowSize = useResize();

    return (
        <div className={"create-bouquet"}>
            {windowSize.isScreenL && <img src={"./img/banners/create.jpg"}/>}
            <div className={"text-block"}>
                НЕ НАШЛИ ПОДХОДЯЩИЙ БУКЕТ?
                <div className={"line"}/>
                <p>Наши флористы придут на помощь и соберут уникальный букет, на любой вкус, бюджет и для любого события по Вашему индивидуальному заказу.</p>
                <div className={"create-btn"} onClick={() => openModal(undefined, "createBouquet")}>Собрать уникальный букет</div>
            </div>
        </div>
    );
};

export default CreateBouquet;
