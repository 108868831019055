import React from 'react';
import PriceItem from "./PriceItem";
import "./productPage.css";

const PricesContainer = ({prices, setPrices, type}) => {



    return (
        <div className={"prices-container"}>
            <div className={"price-item"} style={{fontSize: "12px", wordBreak: "break-all", fontWeight: "500"}}>
                <div>Размер</div>
                <div>Старая цена</div>
                <div>Новая цена</div>
                <div>Размер скидки</div>
            </div>
            {prices.map((item, index) => {
                return <PriceItem price={item} key={index} index={index} setPrices={setPrices} prices={prices}
                                  type={type}/>
            })}
        </div>
    );
};

export default PricesContainer;