import axios from "axios";
import apiData from "../../helpers/ApiData";

export async function updateUser(updated, credentials) {
    return axios({
        method: "PATCH",
        url: apiData.updateUser(updated.id),
        headers: {"Content-Type": "application/json", "Authorization": credentials},
        // в data передаем объект с данными формы
        data: updated,
    });
}

export async function getAdminUsers(credentials) {
    return axios({
        method: "GET",
        url: apiData.getAdminUsers,
        headers: {"Authorization": credentials},
    })
}

export async function getAdminUser(id, credentials) {
    return axios({
        method: "GET",
        url: apiData.getAdminUser(id),
        headers: {"Authorization": credentials},
    })
}

export async function updateAdminUser(userId, updated, credentials) {
    return axios({
        method: "PATCH",
        url: apiData.updateAdminUser(userId),
        headers: {"Authorization": credentials},
        data: updated,
    })
}