import React, {useEffect, useState} from 'react';
import {getAdminAllReviews} from "../../../components/axios/ReviewRequests";
import AxiosError from "../../../components/axios/AxiosError";
import ReviewItem from "../../reviews/ReviewItem";

const AdminReviews = ({user, showMessage, openModal, closeModal}) => {

    const [reviews, setReviews] = useState([]);

    useEffect(() => {
        fetchData().catch((err) => AxiosError(err, showMessage))
    }, [])

    const fetchData = async () => {
        await getAdminAllReviews(user.credentials).then((resp) => setReviews(resp.data));
    }

    return (
        <div className={"main-block"} style={{gap: "20px"}}>
            {reviews.map((rev, index) => {
                return <ReviewItem review={rev} key={index} isEdit={true} openModal={openModal}
                                   closeModal={closeModal}/>
            })}
        </div>
    );
};

export default AdminReviews;