
import React from "react";

export default function GetNameOfCount(count) {
    if (count === 1) {
        return "товар";
    }
    else if (count > 1 && count < 5) {
        return "товарa";
    }
    else {
        return "товаров";
    }
}