import React, {useEffect, useState} from 'react';
import {getDiscount} from "../../helpers/Utils";

const PriceItem = ({price, prices, type, index}) => {

    const [oldPrice, setOldPrice] = useState(0);
    const [currentPrice, setCurrentPrice] = useState(0)
    const [discount, setDiscount] = useState(0);

    useEffect(() => {
        setOldPrice(price.oldPrice);
        setCurrentPrice(price.currentPrice);
    }, [prices]);

    useEffect(() => {
        price.oldPrice = oldPrice;
        price.currentPrice = currentPrice;

        setDiscount(getDiscount(oldPrice, currentPrice));

    }, [oldPrice, currentPrice]);


    return (
        <div className={"price-item"}
             style={{backgroundColor: Number.parseInt(index) % 2 === 0 ? "#f3f3f3" : "#e5e5e5"}}>
            <div className={"column-title"}>{price.size}</div>
            <input className={"input-price"} type={"number"} value={oldPrice}
                   onChange={(event) => setOldPrice(event.currentTarget.value)}/>
            <input className={"input-price"} type={"number"} value={currentPrice}
                   onChange={(event) => setCurrentPrice(event.currentTarget.value)}/>
            <div className={"input-price"}
                 style={{width: "40px", boxSizing: "border-box", paddingTop: "2px", color: "red"}}>{discount}%
            </div>
        </div>
    );
};

export default PriceItem;