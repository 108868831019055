import React from 'react';
import {BsCheck2Square} from 'react-icons/bs';
import "./about.css";
import {useResize} from "../helpers/windowSizes/use-resize";

const About = (props) => {

    const windowSize = useResize();

    return (
            <div className={"page-block"}>
                <img src={"../../img/banners/about-page.png"}/>
                <div className={"data-block"}>
                    <div className={"about-item"}>
                        <div className={"about-item-title"}>
                            <BsCheck2Square className={"about-item-icon"} size={25}/>
                            <div>ВЫБОР ЦВЕТОВ</div>
                        </div>
                        <div className={"text-block"}>
                            Мы получаем цветы 2-3 раза в неделю и работаем с плантациями России (в том числе имеем собственные теплицы), Армении, Голландии, Эквадора, что позволяет нам предоставить вам широкий ассортимент свеже-срезанных цветов и горшечных культур.                        </div>
                    </div>
                    <div className={"about-item"}>
                        <div className={"about-item-title"}>
                            <BsCheck2Square className={"about-item-icon"} size={25}/>
                            <div>КОПОРАТИВНЫЕ ЗАКАЗЫ</div>
                        </div>
                        <div className={"text-block"}>
                            Мы сотрудничаем с юр.лицами и заключаем договор с компаниями на регулярные поставки цветов для украшения клиентских зон и поздравления коллег и партнеров.                        </div>
                    </div>
                    <div className={"about-item"}>
                        <div className={"about-item-title"}>
                            <BsCheck2Square className={"about-item-icon"} size={25}/>
                            <div>ПОПОЛНЕНИЕ КАТАЛОГА САЙТА</div>
                        </div>
                        <div className={"text-block"}>
                            На нашем сайте вы всегда сможете посмотреть актуальные позиции сборных букетов, цветочных боксов, сумочек, цветочных ящичков и Premium ассортимент, выбрать и сразу оплатить желаемое. Мы будем рады, если вы захотите проявить фантазию и заказать что-то особенное, на ваш вкус и к особенному поводу - наш шеф-флорист всегда к вашим услугам.
                        </div>
                    </div>
                    <div className={"about-item"}>
                        <div className={"about-item-title"}>
                            <BsCheck2Square className={"about-item-icon"} size={25}/>
                            <div>НАШ СЕРВИС</div>
                        </div>
                        <div className={"text-block"}>
                            Мы ориентированы на то, чтобы вы были спокойны за свой заказ. Наш менеджер оперативно примет заказ и свяжется с вами. Перед доставкой мы пришлём фотоотчёт на согласование и сообщение о доставке заказа. Также, по вашей просьбе, мы можем выполнить анонимную доставку.                        </div>
                    </div>
                    <div className={"about-item"}>
                        <div className={"about-item-title"}>
                            <BsCheck2Square className={"about-item-icon"} size={25}/>
                            <div>ПРОФЕССИОНАЛЬНЫЕ ФЛОРИСТЫ</div>
                        </div>
                        <div className={"text-block"}>
                            Наша команда постоянно развивается, мы следим за трендами в цветочном мире, расширяем кругозор, наблюдая за лучшими флористами и цветочными дизайнерами, обучаясь на различных курсах. У нашего шеф-флориста есть сертификаты от самарской школы Никиты Деменьтева (свадебный курс) от Kazan Flower's School (2х годичные курсы флорист-дизайнер), курсы по колористикие от Lacy Bird, флоретрит и профессия-флорист от Натальи Жижко, от Анастасии Шехериной (упаковка коммерческого букета), от Максима Вотякова (Свадебный букет и оформление). В каждой смене у нас работает менеджер, который сердечно поможет вам сделать заказ и передаст его в работу флористу. Вы также сможете пообщаться голосом с флористом для обсуждения деталей заказа, при необходимости. Мы всегда рады новым вызовам и возможностям, мы развиваемся во многих направлениях!
                        </div>
                    </div>
                    <div className={"about-item"}>
                        <div className={"about-item-title"}>
                            <BsCheck2Square className={"about-item-icon"} size={25}/>
                            <div>МАСТЕРСКАЯ "ЦВЕТОВОДОФФ"</div>
                        </div>
                        <div className={"text-block"}>
                            "Цветоводофф" - это не только современная цветочная мастерская, но и копмлекс теплиц в
                            Самаре, где мы выращиваем сезонный цветок на продажу. В зависимости от сезона, в нашем
                            ассортименте вы можете найти тюльпаны, ирисы, гиацинты, нарциссы, лилии, георгины,
                            антирринумы и другие свеже-срезанные и горшечные цветы. Одним из ключевых направлений нашей
                            работы является осуществление доставки цветов и подарков по г.Самара. Наша мастерская
                            основана 01.02.2019г.
                        </div>
                    </div>
                </div>
            </div>

    );
};

export default About;