import React, {useEffect, useState} from "react";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Home from "./pages/Home";
import {Route, Routes, useLocation} from "react-router-dom";
import Catalog from "./pages/Catalog";
import ForCorporate from "./pages/ForCorporate";
import FlowerPass from "./pages/FlowerPass";
import About from "./pages/About";
import Cart from "./pages/cart/Cart";
import Contacts from "./pages/Contacts";
import PersonalAccount from "./pages/account/PersonalAccount";
import Product from "./pages/Product";
import {animated, useTransition} from "@react-spring/web";
import Register from "./pages/modal/Register";
import ModalWindow from "./pages/modal/ModalWindow";
import Checkout from "./pages/cart/Checkout";
import MessageModalWindow from "./pages/modal/MessageModalWindow";
import LoginUser from "./components/axios/LoginUser";
import {useResize} from "./helpers/windowSizes/use-resize";
import {YMaps} from '@pbe/react-yandex-maps';
import ResetPassword from "./pages/reset-password/ResetPassword";
import AdminOrders from "./pages/account/orders/AdminOrders";
import AddProductPage from "./pages/product/AddProductPage";
import EditProductPage from "./pages/product/EditProductPage";
import Page404 from "./pages/Page404";
import EditTabsPage from "./pages/tabs/EditTabsPage";
import {getAllTabs} from "./components/axios/TabRequests";
import AxiosError from "./components/axios/AxiosError";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import EducationInfo from "./pages/EducationInfo";
import PaymentDelivery from "./pages/PaymentDelivery";
import LoyaltyInfo from "./pages/LoyaltyInfo";
import WarrantyInfo from "./pages/WarrantyInfo";
import Reviews from "./pages/reviews/Reviews";
import OrderPage from "./pages/account/orders/OrderPage";
import {getProducts, searchProducts} from "./components/axios/ProductRequests";
import SearchPanel from "./components/search/SearchPanel";
import AdminSettings from "./pages/account/admin/AdminSettings";
import {getAllSiteData} from "./components/axios/SiteDataRequests";
import AdminProductsPage from "./pages/account/admin/AdminProductsPage";
import MobileHeader from "./components/header/MobileHeader";
import MobileNavMenu from "./components/mobile-menu/MobileNavMenu";
import MobileFooter from "./components/footer/MobileFooter";
import AdminReviews from "./pages/account/admin/AdminReviews";
import AdminUsersPage from "./pages/account/admin/AdminUsersPage";
import MobileFavoritesPage from "./components/mobileFavorites/MobileFavoritesPage";
import { Buffer } from 'buffer';


const App = () => {

    const location = useLocation();

    const [activeUser, setUser] = useState();

    const [modalActive, setModalActive] = useState(false);
    const [modalType, setModalType] = useState();


    const [messageModalActive, setMessageModalActive] = useState(false);
    const [message, setMessage] = useState();

    const [modalIdEntity, setModalIdEntity] = useState();
    const [products, setProducts] = useState([]);

    const [filterItems, setFilterItems] = useState();
    const [activeFilter, setActiveFilter] = useState();

    const [searchName, setSearchName] = useState("");


    const [activeSort, setActiveSort] = useState();

    const [cartProducts, setCartProducts] = useState([]);
    const [favoriteProducts, setFavoriteProducts] = useState([]);

    const [tabs, setTabs] = useState([]);
    const [data, setData] = useState([]);

    const [isServerAvailable, setIsServerAvailable] = useState(false);

    const windowSize = useResize();


    useEffect(() => {
        loadData().then(() => setIsServerAvailable(true)).catch((err) => {
            setIsServerAvailable(true);
        });

        try {
            const userCredentials = window.localStorage.getItem('userData');
            if (userCredentials !== null) {
                async function fetchData() {
                    await LoginUser(JSON.parse(userCredentials), setUser)
                        .then((response) => setUser({
                            credentials: JSON.parse(userCredentials),
                            user: response.data
                        }))
                        .catch((err) => AxiosError(err, showMessage));
                }

                fetchData();
            }

            const cartData = window.localStorage.getItem('cartData');
            if (cartProducts.length === 0 && cartData !== null) {
                let jsonArray = JSON.parse(cartData);
                setCartProducts(jsonArray);
            }
        } catch (e) {

        }
    }, [])

    useEffect(() => {
        if (activeUser) {
            window.localStorage.setItem('userData', JSON.stringify(activeUser.credentials));
        }
        if (cartProducts) {
            window.localStorage.setItem('cartData', JSON.stringify(cartProducts));
        }
    }, [activeUser, cartProducts])


    useEffect(() => {
        window.scrollTo(0, 0);
        if (location.pathname.endsWith('/')) {
            setActiveFilter("");
            setActiveSort("");
            setFilterItems(tabs);
        }
        else {
            const filters = tabs.filter((el) => location.pathname.endsWith(el.type)).sort((a, b) => {
                return a.index - b.index;
            });
            if (filters && filters.length > 0) {
                setFilterItems(filters);
            }
        }

    }, [location.pathname])

    useEffect(() => {
        if (filterItems) {
            if (activeFilter && filterItems[0].type === activeFilter.type) {

            } else {
                setActiveFilter(filterItems[0]);
            }
        }
    }, [filterItems])

    useEffect(() => {
        if (searchName.length > 0) {
            searchAndLoadItems().then((resp) => setProducts(resp.data)).catch((err) => AxiosError(err, showMessage));
        } else if (searchName.length === 0) {
            loadProducts().then((resp) => {setProducts(resp.data);}).catch((err) => AxiosError(err, showMessage));
        }
    }, [searchName]);


    useEffect(() => {
        setFilterItems(tabs);
    }, [tabs]);

    useEffect(() => {
        loadProducts().then((resp) => setProducts(resp.data)).catch((err) => AxiosError(err, showMessage));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeFilter, activeSort])


    function clearCartBuffer() {
        window.localStorage.removeItem('cartData');
    }

    const transitions = useTransition(location, {
        from: {
            opacity: 0,
        },
        enter: {
            width: "100%",
            opacity: 1,
            transition: "opacity 300ms ease-out"
        }
    })

    async function login(login, password) {
        const credentials = "Basic ".concat(Buffer.from(login.concat(":").concat(password)).toString('base64'));
        await LoginUser(credentials, showMessage)
            .then((response) => {
                setUser({
                    user: response.data,
                    credentials: credentials
                });
                showMessage("Вход успешно выполнен.")
                setModalActive(false);
            })
            .catch((error) => AxiosError(error, showMessage));
    }

    function logout() {
        setUser(undefined);
        window.localStorage.removeItem("userData");
        window.location.assign("/");

    }

    function showMessage(message) {
        setMessage(message);
        setMessageModalActive(true);
    }

    async function loadData() {

        await getAllTabs()
            .then((response) => {
                setTabs(response.data);
            })


        await getAllSiteData()
            .then((response) => {
                setData(response.data);
            })
    }

    async function loadProducts() {
        let filterList = "";

        if (activeFilter) {
            for (const cat of activeFilter.categories) {
                filterList = filterList.concat(cat).concat(",");
            }
        }

        return await getProducts(filterList, activeSort)
            .catch((err) => AxiosError(err, showMessage));
    }

    async function searchAndLoadItems() {
        return await searchProducts(searchName, activeSort)
            .catch((err) => AxiosError(err, showMessage));
    }

    function addItemToArrayProducts(item, price, type) {
        const it = (type === "cart" ? cartProducts : favoriteProducts).filter((el) => {
            let s = el.item.id === item.id;

            if (s) {
                return el.price.size !== price.size;
            } else {
                return true;
            }

        });

        it.push({
            quantity: 1,
            item: item,
            totalSum: price.currentPrice,
            price: price
        });

        if (type === "cart") {
            setCartProducts(it);
        } else {
            setFavoriteProducts(it);
        }

        showMessage("Товар был успешно добавлен в " + (type === "cart" ? "корзину" : "избранное"))
    }

    function deleteItemFromArrayProducts(item, type) {
        let newArray = type === 'cart' ? cartProducts : favoriteProducts;
        newArray = newArray.filter((it) => it !== item);

        if (type === 'cart') {
            setCartProducts(newArray);
        } else {
            setFavoriteProducts(newArray);
        }
    }
    function getCatalog() {
        return <Catalog
            filter={activeFilter}
            filterItems={filterItems}
            setFilter={setActiveFilter}
            setSort={setActiveSort}
            setUser={setUser}


            user={activeUser}
            products={products}
            openModal={openModal}
            loadProducts={loadProducts}
            addItemToCart={addItemToArrayProducts}
            isItemAddedToCart={isItemAddedToArrayProducts}

            setSearchname={setSearchName}
            searchName={searchName}
        />;
    }

    function privateRoute(route) {
        if (activeUser && activeUser.user.role === 'ADMIN') {
            switch (route) {
                case "add-product":
                    return <AddProductPage user={activeUser} showMessage={showMessage}/>;
                case "edit-product":
                    return <EditProductPage user={activeUser} showMessage={showMessage} reloadProducts={loadProducts}/>;
                case "edit-tabs":
                    return <EditTabsPage tabs={tabs} user={activeUser} showMessage={showMessage}/>
                case "orders":
                    return <AdminOrders user={activeUser} showMessage={showMessage}/>
                case "admin-users":
                    return <AdminUsersPage user={activeUser} showMessage={showMessage} openModal={openModal}
                                           closeModal={closeModal}/>
                case "admin-settings":
                    return <AdminSettings user={activeUser} showMessage={showMessage} siteData={data}/>
                case "admin-products":
                    return <AdminProductsPage user={activeUser} showMessage={showMessage}/>
                case "admin-reviews":
                    return <AdminReviews user={activeUser} showMessage={showMessage} openModal={openModal}
                                         closeModal={closeModal}/>
            }
        } else {
            return <Page404/>
        }
    }

    function checkCatalogPage() {
        let loc = location.pathname;
        return loc === "/" || loc === "/flowers" || loc === "/search" || loc === "/plants" || loc === "/decor" || loc === "/flower-pass";
    }

    function isItemAddedToArrayProducts(id, size, type) {
        return (type === "cart" ? cartProducts : favoriteProducts).filter((el) => el.item.id === id && el.price.size === size).length > 0;
    }

    function openModal(id, type) {
        setModalIdEntity(id);
        setModalType(type);
        setModalActive(true);
    }

    function closeModal() {
        setModalActive(false);
        setModalType(undefined);
        setModalIdEntity(undefined);
    }

    async function getAllFilters() {
        return tabs;
    }


    return (
        <>
            {!isServerAvailable ? <div className={"server-unavailable"}>
                    <div className={"error-container"}>
                        <div className={"error-message"}>
                            Нам ужасно жаль... Но сайт cvetovodoff.ru временно недоступен... Мы уже знаем о проблеме и
                            пытаемся ее решить... Попробуйте обновить страницу через несколько минут...

                        </div>
                    </div>
                </div> :
                <div className={"wrapper"}>
                    {windowSize.isScreenL ? <Header
                        logout={logout}
                        location={location}
                        user={activeUser}
                        filters={tabs}
                        setFilter={setActiveFilter}
                        openModal={openModal}
                        favoriteProducts={favoriteProducts}
                        deleteItemFromProducts={deleteItemFromArrayProducts}
                        cartProducts={cartProducts}/>
                        :
                        <MobileHeader/>}

                    <main>
                        {checkCatalogPage() && <div className={"search-sort-panel"}>
                            <SearchPanel searchName={searchName} setSearchName={setSearchName}/>
                        </div>}
                        {
                            transitions((style, item) => (
                                <animated.div style={style}>
                                    <Routes location={item}>
                                        <Route path="/" element={<Home
                                            products={products}
                                            addItemToCart={addItemToArrayProducts}
                                            isItemAddedToCart={isItemAddedToArrayProducts}
                                            openModal={openModal}

                                            filter={activeFilter}
                                            filterItems={filterItems}
                                            setFilter={setActiveFilter}

                                            setSort={setActiveSort}
                                        />}
                                        />
                                        <Route path="/product/:id" element={<Product
                                            user={activeUser}
                                            addItemToCart={addItemToArrayProducts}
                                            showMessage={showMessage}
                                            isItemAddedToCart={isItemAddedToArrayProducts}
                                        />}/>
                                        <Route path="/orders/:id" element={<OrderPage
                                            user={activeUser}
                                            showMessage={showMessage}
                                        />}/>
                                        <Route path={"/personal-account/orders/:id"} element={<OrderPage
                                            user={activeUser}
                                            showMessage={showMessage}/>}/>
                                        <Route path="/reset-password" element={<ResetPassword
                                            showMessage={showMessage}
                                        />}/>
                                        <Route path="/flowers"
                                               element={getCatalog()}/>
                                        <Route path="/plants"
                                               element={getCatalog()}/>
                                        <Route path="/decor"
                                               element={getCatalog()}/>
                                        <Route path="/for-corporate-clients"
                                               element={<ForCorporate/>}/>
                                        <Route path="/flower-pass"
                                               element={<FlowerPass filter={activeFilter}
                                                                    user={activeUser}
                                                                    products={products}
                                                                    openModal={openModal}
                                                                    loadProducts={loadProducts}
                                                                    addItemToCart={addItemToArrayProducts}
                                                                    isItemAddedToCart={isItemAddedToArrayProducts}/>}/>
                                        <Route path="/about-us"
                                               element={<About/>}/>
                                        <Route path="/personal-account"
                                               element={<PersonalAccount
                                                   user={activeUser}
                                                   login={login}
                                                   logout={logout}
                                                   showMessage={showMessage}
                                                   openModal={openModal}
                                                   setUser={setUser}/>}/>

                                        <Route path="/contacts" element={<YMaps> <Contacts/> </YMaps>}/>
                                        <Route path="/order" element={<Cart
                                            openModal={openModal}
                                            user={activeUser}
                                            products={cartProducts}
                                            setProducts={setCartProducts}/>}/>
                                        <Route path="/register" element={<Register user={activeUser} setUser={setUser}
                                                                                   showMessage={showMessage}/>}/>
                                        <Route path="/order/checkout"
                                               element={<Checkout
                                                   user={activeUser}
                                                   cartProducts={cartProducts}
                                                   setProducts={setCartProducts}
                                                   siteData={data}
                                                   clearBuffer={clearCartBuffer}
                                                   showMessage={showMessage}
                                               />}/>
                                        <Route path="/orders" element={privateRoute("orders")}/>
                                        <Route path="/add-product" element={privateRoute("add-product")}/>
                                        <Route path="/edit-product/:id" element={privateRoute("edit-product")}/>
                                        <Route path="/edit-tabs" element={privateRoute("edit-tabs")}/>
                                        <Route path="/admin-users" element={privateRoute("admin-users")}/>
                                        <Route path="/admin-settings" element={privateRoute("admin-settings")}/>
                                        <Route path="/admin-products" element={privateRoute("admin-products")}/>
                                        <Route path="/admin-reviews" element={privateRoute("admin-reviews")}/>
                                        <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                                        <Route path="/education-info" element={<EducationInfo/>}/>
                                        <Route path="/payment-delivery" element={<PaymentDelivery/>}/>
                                        <Route path="/warranty-info" element={<WarrantyInfo/>}/>
                                        <Route path="/reviews" element={<Reviews/>}/>
                                        <Route path="/loyalty-info" element={<LoyaltyInfo/>}/>
                                        <Route path={"/favorites"} element={<MobileFavoritesPage/>}/>
                                    </Routes>
                                </animated.div>
                            ))
                        }
                        <ModalWindow
                            active={modalActive}
                            id={modalIdEntity}
                            setActive={setModalActive}
                            showMessage={showMessage}
                            closeModal={closeModal}
                            login={login}
                            type={modalType}
                            user={activeUser}
                            setUser={setUser}/>

                        <MessageModalWindow
                            active={messageModalActive}
                            setActive={setMessageModalActive}
                            message={message}
                            setMessage={setMessage}
                        />
                    </main>

                    {windowSize.isScreenL ? <Footer/> : <MobileFooter/>}
                    {!windowSize.isScreenL && <MobileNavMenu logout={logout}
                                                             location={location}
                                                             user={activeUser}
                                                             filters={tabs}
                                                             setFilter={setActiveFilter}
                                                             openModal={openModal}
                                                             cartProducts={cartProducts}/>}
                </div>}
        </>
    );
};

export default App


