import axios from "axios";
import apiData from "../../helpers/ApiData";

export async function saveSiteData(siteData, credentials) {
    return axios({
        method: "POST",
        url: apiData.saveSiteDatas,
        headers: {"Content-Type": "application/json", "Authorization": credentials},
        // в data передаем объект с данными формы
        data: siteData,
    });
}

export async function updateSiteData(siteData, id, credentials) {
    return axios({
        method: "PUT",
        url: apiData.updateSiteData(id),
        headers: {"Content-Type": "application/json", "Authorization": credentials},
        // в data передаем объект с данными формы
        data: siteData,
    });
}

export async function deleteSiteData(id, credentials) {
    return axios({
        method: "DELETE",
        url: apiData.deleteSiteData(id),
        headers: {"Authorization": credentials},
    });
}

export async function getAllSiteData() {
    return axios({
        method: "GET",
        url: apiData.getSiteData
    });
}

export async function uploadBanner(img, credentials) {
    return axios({
        method: "POST",
        url: apiData.uploadBanner(),
        headers: {"Content-Type": "multipart/form-data","Authorization": credentials},
        data: img
    });
}