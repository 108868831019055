import axios from "axios";
import apiData from "../../helpers/ApiData";

export async function getAdminOrders(status, credentials) {

    return axios({
        method: "GET",
        url: apiData.getAdminOrders(status),
        headers: {"Authorization": credentials},
    });
}

export async function changeOrderStatus(orderId, status, credentials) {

    return axios({
        method: "POST",
        url: apiData.changeOrderStatus(orderId),
        headers: {"Content-Type": "application/json", "Authorization": credentials},
        data: status
    });
}

export async function getAndCheckPaymentOrder(orderId, credentials) {
    return axios({
        method: "GET",
        url: apiData.getAdminOrderById(orderId),
        headers: {"Authorization": credentials},
    })
}

export async function getAndCheckPaymentUserOrder(userId, orderId, credentials) {
    return axios({
        method: "GET",
        url: apiData.getUserOrderById(userId, orderId),
        headers: {"Authorization": credentials},
    })
}

export async function getUserOrders(userId, credentials) {
    return axios({
        method: "GET",
        url: apiData.getUserOrders(userId),
        headers: {"Authorization": credentials}
    })
}